import {
  Dispatch,
  FC,
  KeyboardEvent,
  SetStateAction,
  memo,
  useCallback,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import cls from './changeEmailModal.module.scss';
import { verifyNFTCardAction } from '@/entities/AllCards';
import { changesEmailProfileAction } from '@/entities/MyProfile';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import { getRouteVerifyNFTCard } from '@/shared/const/router';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { Button, IconButton } from '@/shared/ui/Buttons';
import { Input } from '@/shared/ui/Inputs';
import { Modal } from '@/shared/ui/Modal';
import { HStack } from '@/shared/ui/Stack';

interface IEmailModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  currentEmail?: string;
  modalType?: string;
}

export const ChangeEmailModal: FC<IEmailModalProps> = memo(props => {
  const { open, setOpen, currentEmail, modalType = 'changeMail' } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState(currentEmail || '');
  const [nftCardId, setNftCardId] = useState('');
  const [errorValidation, setErrorValidation] = useState('');

  const currentType = modalType === 'changeMail';

  const handleModalClose = useCallback(() => {
    setOpen(false);
    setEmail('');
  }, [setOpen]);

  const sendConfirmEmail = (curEmail: string) =>
    dispatch(
      changesEmailProfileAction({ email: curEmail, typeAction: 'change' }),
    );

  const verifyNFTCard = (currentNftCardId: string) => {
    const getIdFromURL = currentNftCardId.substring(0);
    dispatch(
      verifyNFTCardAction({
        emptyCard: true,
        id: getIdFromURL,
        type: 'set',
      }),
    );
    setOpen(false);
    navigate(getRouteVerifyNFTCard());
  };

  const onKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter')
      if (nftCardId !== '') {
        verifyNFTCard(nftCardId as string);
        e.preventDefault();
      }
  };

  return (
    <Modal
      isOpen={open}
      className={cls['email-modal']}
      onClose={handleModalClose}
    >
      <HStack justify='between'>
        <h2>{currentType ? 'Change email' : 'VERIFY ASSET'}</h2>
        <IconButton size='32' onClick={() => handleModalClose()}>
          <CloseWhiteSVG />
        </IconButton>
      </HStack>
      <div className={cls.content}>
        <p>
          Use this tool to get more information about a digital token and check
          whether it’s a valid asset on the Collector platform
        </p>
        <p>{currentType ? 'New Email' : 'Token Address'}</p>
        {modalType === 'verifyCard' ? (
          <Input
            required
            value={nftCardId}
            placeholder='Enter NFT Address or Link e.g: https://collectorcrypt.com/xxxxxxxxx'
            onChange={setNftCardId}
            onKeyDown={onKeyPress}
          />
        ) : (
          <Input
            required
            isValidate
            type='email'
            value={email}
            error={errorValidation}
            setError={setErrorValidation}
            placeholder='Enter new email'
            onChange={setEmail}
          />
        )}
        {modalType === 'verifyCard' ? (
          <HStack gap={1} className={cls.buttons}>
            <Button
              fullWidth
              typeButton='primary'
              text='Verify'
              style={{ minWidth: '17rem' }}
              disabled={nftCardId === ''}
              onClick={() =>
                verifyNFTCard(nftCardId.split('/').pop() as string)
              }
            />
          </HStack>
        ) : (
          <HStack gap={1} className={cls.buttons}>
            <Button
              typeButton='secondary'
              text='Cancel'
              style={{ minWidth: '17rem' }}
              onClick={() => handleModalClose()}
            />
            <Button
              fullWidth
              typeButton='primary'
              text='Change'
              style={{ minWidth: '17rem' }}
              disabled={Boolean(errorValidation) || email === ''}
              onClick={() => sendConfirmEmail(email)}
            />
          </HStack>
        )}
      </div>
    </Modal>
  );
});
