import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Burn: FC<ISvgProps> = ({
  width = '24',
  height = '24',
  fill = 'inherit',
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill={fill}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.4781 6.24382C17.2594 4.29382 15.675 2.61569 14.2969 1.23757C14.2119 1.15333 14.108 1.09065 13.9938 1.05477C13.8796 1.01889 13.7585 1.01085 13.6406 1.03132C13.5219 1.05002 13.4095 1.09735 13.3131 1.16922C13.2167 1.24109 13.1393 1.33533 13.0875 1.44382L10.1906 7.51882L7.17188 5.50319C7.0063 5.39359 6.80437 5.35321 6.60938 5.39069C6.51264 5.40979 6.42062 5.44777 6.33857 5.50246C6.25653 5.55716 6.18608 5.62749 6.13125 5.70944C4.30313 8.46569 3.375 10.9594 3.375 13.1251C3.375 15.4126 4.2837 17.6064 5.9012 19.2239C7.51871 20.8414 9.71251 21.7501 12 21.7501C14.2875 21.7501 16.4813 20.8414 18.0988 19.2239C19.7163 17.6064 20.625 15.4126 20.625 13.1251C20.625 10.7907 19.9219 8.54069 18.4781 6.24382ZM17.5594 13.9876C17.382 15.17 16.8301 16.2643 15.9846 17.1097C15.1392 17.9551 14.0449 18.5071 12.8625 18.6844H12.75C12.5618 18.683 12.3811 18.611 12.2436 18.4825C12.1061 18.354 12.0219 18.1786 12.0077 17.9909C11.9935 17.8033 12.0504 17.6172 12.1671 17.4695C12.2838 17.3218 12.4517 17.2234 12.6375 17.1938C13.4994 17.0537 14.2954 16.6461 14.9129 16.0286C15.5304 15.4111 15.938 14.6151 16.0781 13.7532C16.0885 13.6522 16.1193 13.5543 16.1686 13.4655C16.2179 13.3768 16.2847 13.299 16.365 13.2368C16.4453 13.1746 16.5374 13.1294 16.6357 13.1039C16.734 13.0783 16.8364 13.0731 16.9368 13.0883C17.0372 13.1036 17.1335 13.139 17.2198 13.1926C17.306 13.2461 17.3805 13.3167 17.4388 13.3999C17.497 13.4831 17.5377 13.5772 17.5584 13.6767C17.5791 13.7761 17.5795 13.8787 17.5594 13.9782V13.9876Z'
      fill={fill}
    />
  </svg>
);

const BurnSVG = memo(Burn);
export default BurnSVG;
