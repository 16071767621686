import { FC, memo } from 'react';
import { ISvgProps } from './types';

const BuyPublic: FC<ISvgProps> = ({ width = '16', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.9937 4.0875L13.2312 8.26875C13.1683 8.61415 12.9863 8.92655 12.7168 9.15153C12.4472 9.37652 12.1073 9.49983 11.7563 9.5H4.50625L4.78125 11H11.5C11.7967 11 12.0867 11.088 12.3334 11.2528C12.58 11.4176 12.7723 11.6519 12.8858 11.926C12.9994 12.2001 13.0291 12.5017 12.9712 12.7926C12.9133 13.0836 12.7704 13.3509 12.5607 13.5607C12.3509 13.7704 12.0836 13.9133 11.7926 13.9712C11.5017 14.0291 11.2001 13.9994 10.926 13.8858C10.6519 13.7723 10.4176 13.58 10.2528 13.3334C10.088 13.0867 10 12.7967 10 12.5C10.0001 12.3295 10.0297 12.1604 10.0875 12H6.4125C6.47034 12.1604 6.49995 12.3295 6.5 12.5C6.50045 12.742 6.44234 12.9805 6.33062 13.1952C6.2189 13.4099 6.05689 13.5944 5.85843 13.7329C5.65996 13.8714 5.43093 13.9598 5.19088 13.9906C4.95084 14.0214 4.7069 13.9937 4.4799 13.9097C4.2529 13.8258 4.04957 13.6882 3.88728 13.5087C3.72498 13.3292 3.60854 13.113 3.54788 12.8787C3.48722 12.6445 3.48416 12.399 3.53894 12.1632C3.59373 11.9275 3.70474 11.7085 3.8625 11.525L2.13125 2H1C0.867392 2 0.740215 1.94732 0.646447 1.85355C0.552678 1.75979 0.5 1.63261 0.5 1.5C0.5 1.36739 0.552678 1.24021 0.646447 1.14645C0.740215 1.05268 0.867392 1 1 1H2.13125C2.36465 1.00051 2.59052 1.08265 2.76973 1.23218C2.94894 1.38171 3.0702 1.58922 3.1125 1.81875L3.41875 3.5H13.5C13.573 3.50015 13.645 3.51649 13.711 3.54783C13.7769 3.57917 13.8351 3.62474 13.8813 3.68125C13.9296 3.73599 13.9649 3.80102 13.9843 3.8714C14.0038 3.94179 14.0071 4.01569 13.9937 4.0875Z'
      fill='inherit'
    />
  </svg>
);
const BuyPublicSVG = memo(BuyPublic);
export default BuyPublicSVG;
