import { useLocation } from 'react-router-dom';
import cls from './UpdateListingBoxSuccess.module.scss';
import { SmallCardInfo } from '../../../SmallCardInfo/SmallCardInfo';
import { SuccessTitle } from '../../../SuccessTitle/SuccessTitle';
import { BoxQtySVG, OutsideLinkSVG, UsdcSVG } from '@/shared/assets/svg';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import { getRouteNFTCard } from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { convertNumberInK } from '@/shared/lib/helpers/convertNumberInK';
import { limitWordCharacters } from '@/shared/lib/limitWordCharacters/limitWordCharacters';
import { IconButton, Button } from '@/shared/ui/Buttons';
import { HStack, VStack } from '@/shared/ui/Stack';

type TransactionObj = {
  transactionId: string;
  transactionUrl: string;
  price: string;
};

export interface UpdateListingBoxSuccessProps {
  className?: string;
  boxData: SealedBox;
  handleModalClose: () => void;
  transaction: TransactionObj;
}

export const UpdateListingBoxSuccess = (
  props: UpdateListingBoxSuccessProps,
) => {
  const { className, handleModalClose, boxData, transaction } = props;
  const { transactionUrl, price } = transaction;
  const { name, frontImage } = boxData;
  const { pathname } = useLocation();

  const openCard = () => {
    if (pathname.includes('/assets/solana/')) handleModalClose();
    else window.location.href = getRouteNFTCard('tokenId');
  };

  return (
    <div
      className={classNames(cls.SealedBoxUpdateListingSuccess, {}, [className])}
    >
      <div className={cls.closeBtn}>
        <IconButton size='32' onClick={handleModalClose}>
          <CloseWhiteSVG />
        </IconButton>
      </div>
      <SuccessTitle
        text='Your listing price is updated successfully!'
        font='H4-CL'
      />
      <SmallCardInfo
        name={name}
        tokenId='tokenId'
        image={frontImage}
        isTitleShort={false}
        handleModalClose={handleModalClose}
      />
      <VStack justify='center' align='center' gap={1}>
        <HStack gap={1.5}>
          <HStack justify='center' gap={0.625}>
            <div className={cls.askingPriceText}>Asking price</div>
            <HStack
              gap={0.25}
              align='center'
              className={cls.askingPriceCurrency}
            >
              <UsdcSVG fill='#3875C9' secondFill='#FFFFFF' />
              <span className={cls.askingPriceCurrencyValue}>
                ${convertNumberInK(price)}
              </span>
            </HStack>
          </HStack>
          <HStack justify='center' gap={0.625}>
            <div className={cls.transactionTitle}>Quantity</div>
            <HStack
              gap={0.25}
              align='center'
              className={cls.askingPriceCurrency}
            >
              <BoxQtySVG />
              <span className={cls.askingPriceCurrencyValue}>200</span>
            </HStack>
          </HStack>
        </HStack>
        <HStack justify='center' gap={0.375}>
          <div className={cls.transactionTitle}>Transaction</div>
          <div className={cls.transactionDescription}>
            <a
              href={transactionUrl}
              target='_blank'
              rel='noreferrer'
              className='list-card-modal-success__wrapper__transaction__description__link'
            >
              {limitWordCharacters('transactionId', 4, 'centerDots')}
              <OutsideLinkSVG />
            </a>
          </div>
        </HStack>
        <div className={cls.button}>
          <Button
            fullWidth
            typeButton='secondary'
            text='go to box'
            onClick={openCard}
          />
        </div>
      </VStack>
    </div>
  );
};
