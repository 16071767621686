import { FC, memo } from 'react';
import { ISvgProps } from './types';

const Lightning: FC<ISvgProps> = ({
  width = '10',
  height = '10',
  fill = '#E35201',
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.43015 4.6172C8.41855 4.56683 8.39464 4.52012 8.36055 4.48127C8.32647 4.44241 8.28327 4.41261 8.23484 4.39455L5.98484 3.5508L6.55515 0.687515C6.56827 0.62119 6.5598 0.552408 6.53097 0.49125C6.50215 0.430092 6.4545 0.379774 6.395 0.347671C6.33491 0.315708 6.26594 0.304506 6.19882 0.31581C6.13171 0.327113 6.07021 0.360289 6.0239 0.410171L1.6489 5.09767C1.61306 5.13486 1.58712 5.18046 1.57347 5.23028C1.55982 5.2801 1.55889 5.33256 1.57078 5.38283C1.58301 5.43292 1.60715 5.47932 1.64115 5.51809C1.67516 5.55685 1.71802 5.58683 1.76609 5.60548L4.01609 6.44923L3.44578 9.31251C3.43266 9.37884 3.44114 9.44762 3.46996 9.50878C3.49878 9.56994 3.54643 9.62025 3.60594 9.65236C3.65079 9.67499 3.70023 9.68701 3.75047 9.68751C3.79292 9.68769 3.83495 9.67912 3.87393 9.66231C3.91292 9.64551 3.94801 9.62084 3.97703 9.58986L8.35203 4.90236C8.38788 4.86516 8.41382 4.81957 8.42747 4.76975C8.44112 4.71992 8.44204 4.66747 8.43015 4.6172Z'
      fill={fill}
    />
  </svg>
);
const LightningSVG = memo(Lightning);
export default LightningSVG;
