import { FC, memo } from 'react';
import { ISvgProps } from '../../types';

const Telegram: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.022 19.0099C16.2501 19.1714 16.544 19.2118 16.8061 19.1126C17.0682 19.0127 17.2608 18.7889 17.3189 18.5176C17.9344 15.6249 19.4276 8.30299 19.9879 5.67159C20.0304 5.47326 19.9595 5.26714 19.8037 5.13469C19.6479 5.00223 19.4318 4.96398 19.2392 5.03552C16.2692 6.13483 7.12269 9.56663 3.38419 10.95C3.1469 11.0378 2.99249 11.2659 3.00028 11.5159C3.00878 11.7667 3.17736 11.9841 3.42031 12.0571C5.0969 12.5586 7.29764 13.2563 7.29764 13.2563C7.29764 13.2563 8.32612 16.3622 8.86232 17.9418C8.92961 18.1401 9.08473 18.2959 9.28943 18.3498C9.49343 18.4029 9.71159 18.3469 9.86388 18.2031C10.7252 17.39 12.0568 16.1327 12.0568 16.1327C12.0568 16.1327 14.5869 17.9878 16.022 19.0099ZM8.22341 12.8638L9.41268 16.7865L9.67688 14.3024C9.67688 14.3024 14.2717 10.1581 16.8911 7.79583C16.9676 7.72642 16.9782 7.61025 16.9145 7.5288C16.8514 7.44734 16.7353 7.42822 16.6481 7.48347C13.6123 9.42213 8.22341 12.8638 8.22341 12.8638Z'
      fill='#DFE0E1'
    />
  </svg>
);
const TelegramSVG = memo(Telegram);
export default TelegramSVG;
