import { FC, memo } from 'react';
import { ISvgProps } from '../../types';

const Whatsapp: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.3773 5.61616C17.5471 4.78441 16.5592 4.12489 15.4711 3.67582C14.3829 3.22675 13.2159 2.99705 12.0379 3.00003C7.09816 3.00003 3.07687 7.00124 3.07488 11.9195C3.07488 13.4914 3.48719 15.0255 4.27088 16.3788L3 21L7.75107 19.759C9.06527 20.471 10.5376 20.8443 12.0339 20.845H12.0379C16.9767 20.845 20.998 16.8438 21 11.9255C21.0034 10.7535 20.7733 9.59253 20.3232 8.50957C19.873 7.42662 19.2117 6.44321 18.3773 5.61616ZM14.6101 12.7612C14.8158 12.8357 15.916 13.3742 16.1406 13.4865C16.184 13.5082 16.2246 13.5278 16.2624 13.546C16.4184 13.6212 16.5249 13.6726 16.5699 13.7478C16.6258 13.8412 16.6258 14.2873 16.4391 14.808C16.2524 15.3286 15.3579 15.8035 14.9276 15.8671C14.5413 15.9238 14.0531 15.9486 13.517 15.7787C13.1925 15.6754 12.7752 15.5382 12.2401 15.3087C10.1387 14.4052 8.71878 12.3773 8.45286 11.9976C8.43453 11.9714 8.42168 11.953 8.41447 11.9434L8.41155 11.9395C8.28941 11.7768 7.5 10.7251 7.5 9.63729C7.5 8.61082 8.00616 8.07332 8.2397 7.82531C8.25573 7.80829 8.27048 7.79263 8.28369 7.77827C8.35874 7.6934 8.4506 7.62489 8.5535 7.57701C8.65641 7.52914 8.76814 7.50294 8.88169 7.50006C9.0245 7.49955 9.16729 7.5022 9.30998 7.50801C9.32572 7.50869 9.34206 7.50854 9.35895 7.50839C9.48989 7.5072 9.65308 7.50571 9.81314 7.88856C9.87574 8.03816 9.96776 8.26094 10.0645 8.49523C10.2563 8.95963 10.4668 9.46926 10.504 9.54389C10.5609 9.65517 10.5978 9.78533 10.523 9.93437C10.5116 9.95697 10.5011 9.97828 10.4911 9.9986C10.4352 10.1123 10.3942 10.1956 10.2993 10.306C10.2621 10.3494 10.2236 10.3962 10.1851 10.443C10.1081 10.5366 10.0311 10.6302 9.96389 10.6965C9.85108 10.8077 9.73427 10.928 9.86505 11.1515C9.99583 11.3751 10.4451 12.1044 11.111 12.6956C11.8271 13.3309 12.4494 13.5995 12.7645 13.7354C12.8258 13.7619 12.8755 13.7833 12.912 13.8014C13.1356 13.9127 13.2664 13.8948 13.3972 13.7458C13.5279 13.5968 13.9572 13.095 14.107 12.8724C14.2567 12.6499 14.4055 12.6866 14.6101 12.7612Z'
      fill='#DFE0E1'
    />
  </svg>
);
const WhatsappSVG = memo(Whatsapp);
export default WhatsappSVG;
