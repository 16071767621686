import './transferModal.scss';
import { Buffer } from 'buffer';
import { useWallet } from '@solana/wallet-adapter-react';
import { Transaction } from '@solana/web3.js';
import axios from 'axios';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SuccessInvited } from './SuccessInvited/SuccessInvited';
import { TransferSuccess } from './TransferSuccess/TransferSuccess';
import {
  IModalIsOpen,
  IModalIsOpenWithType,
} from '../../../../pages/NFTCard/model/types/types';
import {
  getTransferInstructionsRequest,
  sendInvitationRequest,
  approveTransactionRequest,
} from '@/shared/api/services/transfer';
import { OutsideLinkSVG, TriangleErrorSVG } from '@/shared/assets/svg';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import { getRouteAllCards } from '@/shared/const/router';
import { AuthProviderContext } from '@/shared/lib/context/AuthProviderContext';
import { convertNumberInK } from '@/shared/lib/helpers/convertNumberInK';
import { getCurrentSizeImageInTable } from '@/shared/lib/helpers/getCurrentSizeImageInTable/getCurrentSizeImageInTable';
import { signTransactionsMethod } from '@/shared/lib/signTransactions/signTransactionsMethod';
import { IconButton, Button } from '@/shared/ui/Buttons';
import { Input } from '@/shared/ui/Inputs';
import { Spinner } from '@/shared/ui/Loaders';
import { Modal } from '@/shared/ui/Modal';

interface ITransferModalProps {
  open: boolean;
  setOpen: (
    modalName: string,
    value: IModalIsOpen | IModalIsOpenWithType,
  ) => void;
  name: string;
  tokenID: string;
  insuredValue: string;
  aversImage: string;
  id: string;
  orientation: IOrientation;
  getNFTCard: (withLoading: boolean) => void;
}

export const TransferModal: FC<ITransferModalProps> = ({
  open,
  setOpen,
  name,
  tokenID,
  insuredValue,
  aversImage,
  orientation,
  id,
  getNFTCard,
}) => {
  const { height, width } = getCurrentSizeImageInTable(orientation, 'm');

  const styleNoActive = {
    background: '#1D1D1D',
    border: '1px solid #3d3d3d',
    borderRadius: '0.5rem',
    color: '#828585',
    fontSize: '14px',
    fontWeight: 600,
    minWidth: 'auto',
    padding: '0.5rem 0.8125rem',
    width: '5.5rem',
  };

  const styleActive = {
    background: '#DBDBDB',
    border: '1px solid rgb(130 133 133 / 0%)',
    borderRadius: '0.5rem',
    color: '#1D1D1D',
    fontSize: '14px',
    fontWeight: 600,
    minWidth: 'auto',
    padding: '0.5rem 0.8125rem',
    transition: 'all 0.5s',
    width: '5.5rem',
  };

  const [isByWallet, setIsByWallet] = useState(true);
  const [wallet, setWallet] = useState('');
  const [email, setEmail] = useState('');
  const [tranferStatus, setTranferStatus] = useState('none');
  const [inviteStatus, setInviteStatus] = useState('none');
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidWallet, setIsInvalidWallet] = useState(false);
  const [isInvitationSent, setIsInvitationSent] = useState(false);
  const [transactionURL, setTransactionURL] = useState('');
  const [responseWallet, setResponseWallet] = useState('');

  const navigate = useNavigate();
  const { signTransaction } = useWallet();
  const { signTrans: signTransactionWeb3 } = useContext(AuthProviderContext);

  const isTranferPending = tranferStatus === 'pending';
  const isInviteMailPending = tranferStatus === 'invite-pending';
  const isTransferSuccess = tranferStatus === 'resolved';

  const handleModalClose = () => {
    setTranferStatus('none');
    setIsInvalidEmail(false);
    setIsInvalidWallet(false);
    setIsInvitationSent(false);
    setOpen('transferCard', { isOpen: false });
  };
  const currentNetwork = environment.NETWORK;

  const validateEmail = (localEmail: string) => {
    const re = /\S+@\S+\.\S+\S+/;
    return re.test(localEmail);
  };

  const handleTransfer = async () => {
    try {
      setTranferStatus('pending');
      const ut = (await getTransferInstructionsRequest({
        nftAddress: id,
        ...(isByWallet ? { wallet } : { email }),
      })) as {
        data: { transaction: string; wallet: string };
      };
      setResponseWallet(ut.data.wallet);
      const transaction = Transaction.from(
        Buffer.from(ut.data.transaction as string, 'base64'),
      ) as Transaction;

      const transactionSigned = await signTransactionsMethod(
        transaction,
        signTransaction,
        signTransactionWeb3,
      );
      if (transactionSigned) {
        const transactionSerialized = transactionSigned
          .serialize()
          .toString('base64');
        const transactionUrl = await approveTransactionRequest({
          nftAddress: id,
          transaction: transactionSerialized,
          ...(isByWallet ? { wallet: ut.data.wallet } : { email }),
        });
        setTransactionURL(transactionUrl.data as string);
      }
      setTranferStatus('pending');
      setTranferStatus('resolved');
    } catch (err) {
      setTranferStatus('none');
      if (axios.isAxiosError(err)) {
        const customError = err.response as IResponse<IErrorMessage>;
        if (customError.status === 404)
          if (customError?.data?.message === 'Recipient not found')
            if (isByWallet) setIsInvalidWallet(true);
            else setIsInvalidEmail(true);
          else setIsInvalidWallet(true);

        if (
          customError.status === 400 &&
          customError?.data?.message === 'User email is not public'
        )
          setIsInvalidEmail(true);
      }
    }
  };

  const handleSendInvitation = async () => {
    try {
      setTranferStatus('invite-pending');
      await sendInvitationRequest({ email });
      setInviteStatus('success');
      setIsInvitationSent(true);
      setTranferStatus('none');
    } catch (err) {
      setTranferStatus('none');
      setInviteStatus('error');
      console.log('TransferModal', err);
    }
  };

  const handleCancelInvitation = () => {
    setIsInvalidEmail(false);
    setTranferStatus('none');
  };

  const selectButton = () => {
    if (isInviteMailPending || isTranferPending) return null;
    if (!isTransferSuccess)
      return !isInvalidEmail || isByWallet ? (
        <div className='transfer-modal__main-modal__confirm'>
          <Button
            fullWidth
            typeButton='primary'
            text='Transfer'
            disabled={isByWallet ? wallet.length !== 44 : !validateEmail(email)}
            onClick={handleTransfer}
          />
        </div>
      ) : (
        <div className='transfer-modal__main-modal__invitation'>
          <p className='transfer-modal__main-modal__invitation__text'>
            Send him an invitation?
          </p>
          <div className='transfer-modal__main-modal__invitation__buttons'>
            <Button
              typeButton='primary'
              size='small'
              text='Yes, send'
              onClick={handleSendInvitation}
            />
            <Button
              typeButton='secondary'
              size='small'
              text='cancel'
              onClick={handleCancelInvitation}
            />
          </div>
        </div>
      );

    return (
      <div className='transfer-modal__main-modal__cards'>
        <Button
          fullWidth
          text='go to my cards'
          typeButton='secondary'
          onClick={() => navigate(getRouteAllCards())}
        />
      </div>
    );
  };

  const renderComponent = () => {
    if (!isTranferPending && !isInviteMailPending)
      return (
        <div style={{ width: '100%' }}>
          {!isTransferSuccess ? (
            <>
              <div className='transfer-modal__main-modal__method'>
                <Button
                  typeButton='secondary'
                  style={isByWallet ? styleActive : styleNoActive}
                  text='To Wallet'
                  onClick={() => setIsByWallet(true)}
                />
                <Button
                  typeButton='secondary'
                  style={!isByWallet ? styleActive : styleNoActive}
                  text='To Email'
                  onClick={() => setIsByWallet(false)}
                />
              </div>
              <div
                className={`transfer-modal__main-modal__input${
                  (isInvalidEmail && !isByWallet) ||
                  (isInvalidWallet && isByWallet)
                    ? ' error'
                    : ''
                }`}
              >
                {isByWallet ? (
                  <Input
                    value={wallet}
                    max={999999999}
                    label='Wallet Address'
                    placeholder='e.g. 4NVejcZGg8AegvMYsKZFeMgC8qFD4gwFED8aL6zLPkXz'
                    endIcon={
                      !!wallet.length && (
                        <IconButton
                          size='24'
                          style={{ minHeight: 'auto', minWidth: 'auto' }}
                          onClick={() => setWallet('')}
                        >
                          <CloseWhiteSVG />
                        </IconButton>
                      )
                    }
                    onChange={setWallet}
                    onKeyDown={() => setIsInvalidWallet(false)}
                  />
                ) : (
                  <>
                    <Input
                      value={email}
                      max={999999999}
                      label='Email'
                      placeholder='e.g. username@gmail.com'
                      endIcon={
                        !!email.length && (
                          <IconButton
                            size='24'
                            onClick={() => {
                              setEmail('');
                              setIsInvalidEmail(false);
                            }}
                          >
                            <CloseWhiteSVG />
                          </IconButton>
                        )
                      }
                      onChange={setEmail}
                      onKeyDown={() => setIsInvalidEmail(false)}
                    />
                    {!!isInvalidEmail && (
                      <div className='transfer-modal__main-modal__input__error'>
                        <TriangleErrorSVG />
                        <p className='transfer-modal__main-modal__input__error__text'>
                          This email is not linked to any account on the
                          collector. You can&apos;t transfer him pNFT!
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <div className='transfer-modal__main-modal__transaction'>
              <p className='transfer-modal__main-modal__transaction__content'>
                Transaction
                <a href={transactionURL} target='_blank' rel='noreferrer'>
                  {`${transactionURL.slice(
                    transactionURL.indexOf('tx/') + 3,
                    transactionURL.indexOf('tx/') + 9,
                  )}...${transactionURL.slice(
                    transactionURL.indexOf('?') - 4,
                    transactionURL.indexOf('?'),
                  )}`}
                  <OutsideLinkSVG />
                </a>
              </p>
            </div>
          )}
        </div>
      );

    if (isInviteMailPending)
      return (
        <div className='transfer-modal__main-modal__loader'>
          <Spinner size={40} />
          <div>
            <p>We are sending an invitation...</p>
          </div>
        </div>
      );

    return (
      <div className='transfer-modal__main-modal__loader'>
        <Spinner size={40} />
        <div>
          <p>Your transfer is processing. </p>
          <p>It should be confirmed on the blockchain shortly </p>
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={open}
      className='transfer-modal__main-modal'
      onClose={handleModalClose}
    >
      <div className='transfer-modal__main-modal__close'>
        <IconButton size='32' onClick={handleModalClose}>
          <CloseWhiteSVG />
        </IconButton>
      </div>
      {isInvitationSent ? (
        <SuccessInvited
          inviteStatus={inviteStatus}
          openCard={() => navigate(getRouteAllCards())}
        />
      ) : (
        <>
          {isTransferSuccess ? (
            <TransferSuccess
              getNFTCard={getNFTCard}
              isByWallet={isByWallet}
              wallet={wallet}
              email={email}
              responseWallet={responseWallet}
            />
          ) : (
            <p className='transfer-modal__main-modal__title'>Transfer pNFT</p>
          )}
          <div className='transfer-modal__main-modal__card'>
            <div className='transfer-modal__main-modal__card__image'>
              <img
                src={aversImage}
                alt=''
                width={width}
                height={height}
                loading='lazy'
              />
            </div>
            <div className='transfer-modal__main-modal__card__info'>
              <div
                className='transfer-modal__main-modal__card__info__name'
                onClick={handleModalClose}
              >
                {name}
              </div>
              <div className='transfer-modal__main-modal__card__info__bottom'>
                <div className='transfer-modal__main-modal__card__info__bottom__token-id'>
                  <div className='transfer-modal__main-modal__card__info__bottom__token-id__title'>
                    Token ID
                  </div>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    className='transfer-modal__main-modal__card__info__bottom__token-id__value'
                    href={`https://solscan.io/token/${tokenID}?cluster=${
                      currentNetwork === 'devnet' ? 'devnet' : 'mainnet'
                    }`}
                  >
                    {`${tokenID.slice(0, 5)}...${tokenID.slice(
                      tokenID.length - 5,
                    )}`}
                  </a>
                </div>
                <div className='transfer-modal__main-modal__card__info__bottom__price'>
                  ${convertNumberInK(insuredValue)}
                </div>
              </div>
            </div>
          </div>
          {renderComponent()}
          {selectButton()}
        </>
      )}
    </Modal>
  );
};
