import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Transfer: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.0344 17.0344L17.0344 20.0344C16.8905 20.172 16.6992 20.2492 16.5001 20.25C16.4005 20.2496 16.302 20.2305 16.2094 20.1938C16.0729 20.1364 15.9565 20.0399 15.8747 19.9164C15.793 19.793 15.7496 19.6481 15.7501 19.5V17.25H4.50007C4.30116 17.25 4.11039 17.171 3.96974 17.0304C3.82909 16.8897 3.75007 16.6989 3.75007 16.5C3.75007 16.3011 3.82909 16.1103 3.96974 15.9697C4.11039 15.829 4.30116 15.75 4.50007 15.75H15.7501V13.5C15.7496 13.352 15.793 13.2071 15.8747 13.0836C15.9565 12.9601 16.0729 12.8637 16.2094 12.8063C16.348 12.7522 16.499 12.7385 16.645 12.7667C16.791 12.7949 16.926 12.8639 17.0344 12.9657L20.0344 15.9657C20.1753 16.1078 20.2543 16.2999 20.2543 16.5C20.2543 16.7002 20.1753 16.8922 20.0344 17.0344ZM6.96569 11.0344C7.10966 11.172 7.30091 11.2492 7.50007 11.25C7.59961 11.2496 7.69818 11.2305 7.79069 11.1938C7.9272 11.1364 8.04366 11.0399 8.12541 10.9164C8.20716 10.793 8.25053 10.6481 8.25007 10.5V8.25002H19.5001C19.699 8.25002 19.8897 8.17101 20.0304 8.03035C20.1711 7.8897 20.2501 7.69894 20.2501 7.50002C20.2501 7.30111 20.1711 7.11035 20.0304 6.9697C19.8897 6.82904 19.699 6.75002 19.5001 6.75002H8.25007V4.50002C8.25053 4.35196 8.20716 4.20706 8.12541 4.08361C8.04366 3.96015 7.9272 3.86365 7.79069 3.80627C7.65216 3.75224 7.50116 3.73853 7.35516 3.76674C7.20916 3.79494 7.07413 3.86391 6.96569 3.96565L3.96569 6.96565C3.82486 7.10785 3.74585 7.29989 3.74585 7.50002C3.74585 7.70016 3.82486 7.8922 3.96569 8.0344L6.96569 11.0344Z'
      fill='#FF7C01'
    />
  </svg>
);

const TransferSVG = memo(Transfer);
export default TransferSVG;
