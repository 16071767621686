import { FC, memo } from 'react';
import { IErrorName } from './types';
import { ErrorCircleSVG } from '@/shared/assets/svg/AlertsIcons';
import './errorBlock.scss';

export const ErrorBlock: FC<IErrorName> = memo(({ errorName, key }) => {
  const selectText = () => {
    switch (errorName) {
      case 'listing-error':
        return 'This item is no longer available to purchase!';
      case 'same-offer-price-error':
        return 'You have already made an offer for this card. Please update your existing offer.';
      case 'price-error':
        return 'The price has changed for this NFT.';
      case 'owner-error':
        return 'This item is no longer available to purchase!';
      case 'unknown-error':
        return 'Something went wrong...Please try again!';
      case 'Expired-offer-error':
        return 'Expired offer cannot be updated';
      case 'Invalid-ownership-error':
        return 'Invalide ownership';
      case 'not-found':
        return 'Nft not found';
      case 'Card-not-found':
        return 'Card not found on magicEden platform';
      default:
        return '';
    }
  };

  const renderContent = () => {
    if (errorName === 'balance-error')
      return (
        <div key={key} className='error-block__content'>
          <div className='error-block__content__icon'>
            <ErrorCircleSVG type='alternative' />
            <p>Not enough funds in your wallet.</p>
          </div>
          <p>Transfer funds to your wallet and try again</p>
        </div>
      );

    return (
      <div key={key} className='error-block__content'>
        <div className='error-block__content__icon'>
          <ErrorCircleSVG type='alternative' />
          <p>{selectText()}</p>
        </div>
      </div>
    );
  };

  return <div className='error-block'>{renderContent()}</div>;
});
