import { FC, memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cls from './fullInfo.module.scss';
import { selectVerifyCardContent } from '../../const/selectIconVerifyCard';
import { BurnedSVG } from '@/shared/assets/svg/VerifyCardStatusIcon';
import { getRouteNFTCard, getRoutePublicAccount } from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { sliceString } from '@/shared/lib/helpers/scliceStringFunction';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { limitWordCharacters } from '@/shared/lib/limitWordCharacters/limitWordCharacters';
import { Alert } from '@/shared/ui/Alert/Alert';
import { Button } from '@/shared/ui/Buttons';
import { Spinner } from '@/shared/ui/Loaders';
import { RotateCard } from '@/shared/ui/RotateCard/RotateCard';
import { HStack, VStack } from '@/shared/ui/Stack';
import { BlockCosts } from '@/shared/ui/Statuses/ui/BlocksColor';

interface IFullCardInfoProps {
  card: IVerifyNFtCard | ICard;
  typeModal?: 'verify' | 'shipment';
}

export const FullInfo: FC<IFullCardInfoProps> = memo(props => {
  const { card, typeModal = 'verify' } = props;
  const currentNetwork = environment.NETWORK;

  const currentCardValues = useMemo(() => {
    if ('backImage' in card)
      return {
        address: card.nftAddress,
        backImage: card.backImage,
        category: card.category,
        frontImage: card.images ? card.images.frontS : '',
        grade: card.grade,
        gradingCompany: card.gradingCompany,
        insuredValue: card.insuredValue,
        name: card.itemName,
        nftStatus: card.nftStatus,
        orientation: card.orientation,
        owner: card?.owner?.wallet,
        ownerName: card?.author?.name,
        status: card.status,
        tokenId: card.nftAddress,
      };

    return {
      address: card.verifiedCard.nftAddress,
      backImage: card.verifiedCard.backImage,
      category: card.verifiedCard.category,
      frontImage: card.verifiedCard.images
        ? card.verifiedCard.images.frontS
        : '',
      grade: card.verifiedCard.grade,
      gradingCompany: card.verifiedCard.gradingCompany,
      insuredValue: card.verifiedCard.insuredValue,
      name: card.verifiedCard.itemName,
      nftStatus: card.verifiedCard.nftStatus,
      orientation: card.verifiedCard.orientation,
      owner: card.verifiedCard.author ? card.verifiedCard.author.wallet : '',
      ownerName: card?.verifiedCard.author?.name,
      status: card.verifiedCard.status,
    };
  }, [card]);

  const { isLoadingCards } = useAppSelector(state => state.cards);

  const isBurn = useMemo(
    () => currentCardValues.owner === '1',
    [currentCardValues.owner],
  );
  const isMinted = useMemo(
    () => currentCardValues.status === 'Minted',
    [currentCardValues.status],
  );
  const isRejected = useMemo(
    () => currentCardValues.status === 'Rejected',
    [currentCardValues.status],
  );
  const isRequestedBack = useMemo(
    () => currentCardValues.status === 'RequestedBack',
    [currentCardValues.status],
  );
  const inShipments = useMemo(() => typeModal === 'shipment', [typeModal]);

  const selectNameOwner = useMemo(
    () =>
      currentCardValues.nftStatus === 'Burned'
        ? 'N/A'
        : (currentCardValues.ownerName &&
            limitWordCharacters(
              currentCardValues.ownerName as string,
              15,
              'centerDots',
            )) ||
          (currentCardValues?.owner && sliceString(currentCardValues.owner)),
    [
      currentCardValues.nftStatus,
      currentCardValues.owner,
      currentCardValues.ownerName,
    ],
  );

  const selectContent = () => {
    if (!isMinted)
      return isRejected || isRequestedBack ? (
        <Alert
          title='Rejection reason:'
          status='warning'
          size='small'
          text='Rejection reason: Minting process cannot continue because your card is damage! Please contact support to resolve the issue'
        />
      ) : (
        <Link to={getRouteNFTCard(currentCardValues.address)}>
          <Button
            typeButton='secondary'
            text='View card'
            style={{ marginTop: '1rem', width: '6.95rem' }}
            size='small'
          />
        </Link>
      );

    return null;
  };

  if (isLoadingCards)
    return (
      <HStack justify='center' className={cls.loader} align='center'>
        <Spinner />
      </HStack>
    );

  return (
    <VStack align='center' gap={1} className={cls['full-info']}>
      {isBurn ? (
        <BurnedSVG />
      ) : (
        selectVerifyCardContent.icon(currentCardValues.status)
      )}
      <VStack align='center' gap={1}>
        <h3>
          {isBurn
            ? 'pNFT is burned!'
            : selectVerifyCardContent.title(currentCardValues.status)}
        </h3>
        <h5>
          {isBurn
            ? 'This pNFT does NOT represent an underlying asset, which has been returned to the owner. Proceed with caution!'
            : selectVerifyCardContent.text(currentCardValues.status)}
        </h5>
      </VStack>
      <HStack gap={0.5} align='stretch' className={cls.details}>
        <RotateCard
          inShipment
          status={isBurn ? 'Burned' : currentCardValues.status}
          frontImage={currentCardValues.frontImage}
          backImage={currentCardValues.backImage}
          orientation={currentCardValues.orientation}
          alt='test'
        />
        <VStack
          max
          className={cls.right}
          style={inShipments ? { width: '27.125rem' } : { width: '24.063rem' }}
        >
          <div
            className={classNames(cls.main, {
              [cls.horizontal]: inShipments && isMinted,
            })}
          >
            <div className={cls.title}>
              {!!currentCardValues.name &&
                limitWordCharacters(currentCardValues.name, 75, 'endDots')}
            </div>
            {!!inShipments && !!isMinted && (
              <BlockCosts
                amount='1'
                status={currentCardValues.status}
                value2={currentCardValues.insuredValue}
              />
            )}
            {!!(isRejected || isRequestedBack || !isMinted) && (
              <HStack justify='between' className={cls['owner-price']}>
                <HStack gap={0.25}>
                  <p>Owned by</p>
                  {currentCardValues.owner ? (
                    <Link to={getRoutePublicAccount(currentCardValues.owner)}>
                      {selectNameOwner}
                    </Link>
                  ) : (
                    <p>{selectNameOwner}</p>
                  )}
                </HStack>
                {currentCardValues.nftStatus !== 'Burned' && (
                  <span>${currentCardValues.insuredValue}</span>
                )}
              </HStack>
            )}
          </div>
          <VStack max gap={0.25} className={cls.more}>
            {!!(isMinted || !isRequestedBack) &&
              !!currentCardValues.tokenId && (
                <HStack max justify='between' className={cls['token-id']}>
                  <p>Token ID</p>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={`https://solscan.io/address/${
                      currentCardValues.address
                    }?cluster=${
                      currentNetwork === 'devnet' ? 'devnet' : 'mainnet'
                    }`}
                  >
                    {`${currentCardValues?.tokenId.slice(0, 14)}...`}
                  </a>
                </HStack>
              )}
            <HStack max justify='between' className={cls['token-id']}>
              <p>Grading company</p>
              <p className={cls.white}>
                {currentCardValues.gradingCompany || 'Grading company'}
              </p>
            </HStack>
            {!!(isRejected || !isRequestedBack) && (
              <HStack max justify='between' className={cls['token-id']}>
                <p>Category</p>
                <p className={cls.white}>{currentCardValues.category}</p>
              </HStack>
            )}
            {!!(!isRejected || !isRequestedBack) && (
              <HStack max justify='between' className={cls['token-id']}>
                <p>Grade</p>
                <p className={cls.white}>{currentCardValues.grade}</p>
              </HStack>
            )}
            {selectContent()}
          </VStack>
        </VStack>
      </HStack>
    </VStack>
  );
});
