import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Share: FC<ISvgProps> = ({
  width = '24',
  height = '24',
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
    {...otherProps}
  >
    <path
      d='M15.3756 14.2497C15.0004 14.2491 14.629 14.3241 14.2834 14.4703C13.9379 14.6165 13.6254 14.8308 13.3646 15.1005L10.1232 13.0192C10.3762 12.3631 10.3762 11.6363 10.1232 10.9802L13.3646 8.89891C13.8206 9.36526 14.4245 9.65863 15.073 9.7288C15.7214 9.79897 16.3741 9.64158 16.9193 9.28357C17.4645 8.92557 17.8683 8.3892 18.0617 7.76627C18.255 7.14334 18.2258 6.47259 17.9791 5.86881C17.7324 5.26503 17.2835 4.76576 16.7093 4.45646C16.135 4.14717 15.4711 4.04707 14.8312 4.1733C14.1913 4.29953 13.6152 4.64424 13.2014 5.14843C12.7877 5.65263 12.562 6.28496 12.5631 6.93719C12.5642 7.28572 12.6285 7.63115 12.7529 7.95673L9.51149 10.038C9.12124 9.63705 8.62029 9.36163 8.07268 9.24693C7.52507 9.13223 6.95569 9.18346 6.43735 9.39408C5.91902 9.6047 5.47528 9.96512 5.16287 10.4293C4.85047 10.8934 4.68359 11.4402 4.68359 11.9997C4.68359 12.5592 4.85047 13.106 5.16287 13.5701C5.47528 14.0343 5.91902 14.3947 6.43735 14.6053C6.95569 14.8159 7.52507 14.8672 8.07268 14.7525C8.62029 14.6378 9.12124 14.3623 9.51149 13.9614L12.7529 16.0427C12.6285 16.3682 12.5642 16.7137 12.5631 17.0622C12.5631 17.6185 12.728 18.1622 13.037 18.6247C13.3461 19.0872 13.7853 19.4477 14.2993 19.6606C14.8132 19.8735 15.3787 19.9292 15.9242 19.8207C16.4698 19.7121 16.971 19.4443 17.3643 19.0509C17.7576 18.6576 18.0255 18.1565 18.134 17.6109C18.2425 17.0653 18.1868 16.4998 17.974 15.9859C17.7611 15.472 17.4006 15.0327 16.9381 14.7237C16.4756 14.4146 15.9318 14.2497 15.3756 14.2497Z'
      fill='inherit'
    />
  </svg>
);

const ShareSVG = memo(Share);
export default ShareSVG;
