import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const WarningOktagon: FC<ISvgProps> = ({ width = '48', height = '48' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M42.6188 15.0375L32.9625 5.38125C32.3998 4.82006 31.6385 4.5034 30.8438 4.5H17.1562C16.3615 4.5034 15.6002 4.82006 15.0375 5.38125L5.38125 15.0375C4.82006 15.6002 4.5034 16.3615 4.5 17.1562V30.8438C4.5034 31.6385 4.82006 32.3998 5.38125 32.9625L15.0375 42.6188C15.6002 43.1799 16.3615 43.4966 17.1562 43.5H30.8438C31.6385 43.4966 32.3998 43.1799 32.9625 42.6188L42.6188 32.9625C43.1799 32.3998 43.4966 31.6385 43.5 30.8438V17.1562C43.4966 16.3615 43.1799 15.6002 42.6188 15.0375ZM22.5 15C22.5 14.6022 22.658 14.2206 22.9393 13.9393C23.2206 13.658 23.6022 13.5 24 13.5C24.3978 13.5 24.7794 13.658 25.0607 13.9393C25.342 14.2206 25.5 14.6022 25.5 15V25.5C25.5 25.8978 25.342 26.2794 25.0607 26.5607C24.7794 26.842 24.3978 27 24 27C23.6022 27 23.2206 26.842 22.9393 26.5607C22.658 26.2794 22.5 25.8978 22.5 25.5V15ZM24 34.5C23.555 34.5 23.12 34.368 22.75 34.1208C22.38 33.8736 22.0916 33.5222 21.9213 33.111C21.751 32.6999 21.7064 32.2475 21.7932 31.811C21.88 31.3746 22.0943 30.9737 22.409 30.659C22.7237 30.3443 23.1246 30.13 23.561 30.0432C23.9975 29.9564 24.4499 30.001 24.861 30.1713C25.2722 30.3416 25.6236 30.63 25.8708 31C26.118 31.37 26.25 31.805 26.25 32.25C26.25 32.8467 26.0129 33.419 25.591 33.841C25.169 34.2629 24.5967 34.5 24 34.5Z'
      fill='#FF4445'
    />
  </svg>
);
const WarningOktagonSVG = memo(WarningOktagon);
export default WarningOktagonSVG;
