import { Suspense } from 'react';
import { IPrivacyPolicyModalProps } from './PrivacyPolicyModal';
import { lazyRetry } from '@/shared/lib/lazyRetry/lazyWithRetry';

export const PrivacyPolicyModalAsync = lazyRetry(
  () => import('./PrivacyPolicyModal'),
);

export const PrivacyPolicyModalLazy = (props: IPrivacyPolicyModalProps) => (
  <Suspense fallback=''>
    <PrivacyPolicyModalAsync {...props} />
  </Suspense>
);
