import { Dispatch, SetStateAction, memo, useCallback, useState } from 'react';
import cls from './SendForm.module.scss';
import { sendFormReducer } from '../../model/slice/sendMoneySlice';
import { ConfirmSent } from '../ConfirmSent/ConfirmSent';
import { Form } from '../Form/Form';
import { SendMoneyHeader } from '@/entities/Headers';
import USDCLarge from '@/shared/assets/svg/HeaderPopover/USDCLarge.svg';
import SolanaIconLarge from '@/shared/assets/svg/HeaderPopover/solanaLogoLarge.svg';
import { classNames } from '@/shared/lib/classNames/classNames';
import {
  DynamicModuleLoader,
  ReducersList,
} from '@/shared/lib/components/DynamicModuleLoader';
import { HStack, VStack } from '@/shared/ui/Stack';

export interface SendFormProps {
  className?: string;
  setOpenForm: Dispatch<SetStateAction<boolean>>;
  setOpenModal: () => void;
}

const reducers: ReducersList = {
  sendForm: sendFormReducer,
};

export const SendForm = memo((props: SendFormProps) => {
  const { className, setOpenForm, setOpenModal } = props;
  const [openConfirm, setOpenConfirm] = useState(false);
  const isUSDC = localStorage.getItem('sendType') === 'USDC';

  const Icon = useCallback(() => {
    if (isUSDC) return <USDCLarge />;
    return (
      <SolanaIconLarge style={{ background: '#000000', borderRadius: '50%' }} />
    );
  }, [isUSDC]);
  return (
    <DynamicModuleLoader removeAfterUnmount={false} reducers={reducers}>
      {openConfirm ? (
        <ConfirmSent
          setOpenModal={setOpenModal}
          setOpenConfirm={setOpenConfirm}
        />
      ) : (
        <VStack className={classNames(cls.SendForm, {}, [className])} gap={1}>
          <SendMoneyHeader
            setOpen={setOpenForm}
            setOpenModal={setOpenModal}
            title={isUSDC ? 'Send USDC' : 'Send SOL'}
          />
          <HStack max justify='center'>
            <div className={cls.logo}>
              <Icon />
            </div>
          </HStack>
          <Form setOpenModal={setOpenModal} setOpenConfirm={setOpenConfirm} />
        </VStack>
      )}
    </DynamicModuleLoader>
  );
});
