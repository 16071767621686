import { Suspense } from 'react';
import { IMagicLinkModalProps } from './MagicLinkModal';
import { lazyRetry } from '@/shared/lib/lazyRetry/lazyWithRetry';

export const MagicLinkModalAsync = lazyRetry(() => import('./MagicLinkModal'));

export const MagicLinkModalLazy = (props: IMagicLinkModalProps) => (
  <Suspense fallback=''>
    <MagicLinkModalAsync {...props} />
  </Suspense>
);
