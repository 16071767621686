import { StateSchema } from '@/app/providers/StoreProvider';

export const getMagicLinkIsLoading = (state: StateSchema) =>
  state.auth.authMagicLink.isLoadingMagicLink;

export const getTypeAuthMagicLink = (state: StateSchema) =>
  state.auth.authMagicLink.typeAuth;

export const getWalletMagicLink = (state: StateSchema) =>
  state.auth.authMagicLink.walletMagicLink;

export const getIsAuthMagicLink = (state: StateSchema) =>
  state.auth.authMagicLink.isAuthMagicLink;

export const getDataUserGoogleAuthMagicLink = (state: StateSchema) =>
  state.auth.authMagicLink.userDataGoogleAuth;

export const getDataUserMagicLink = (state: StateSchema) =>
  state.auth.authMagicLink.userDataMagicLink;
