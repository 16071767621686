import { FC, memo } from 'react';
import { ISvgProps } from '../../types';

const TwitterSVG: FC<ISvgProps> = ({ width = '28', height = '28' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 28 28'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 2.33334C7.55742 2.33334 2.33334 7.55742 2.33334 14C2.33334 20.4426 7.55742 25.6667 14 25.6667C20.4438 25.6667 25.6667 20.4425 25.6667 14C25.6667 7.55749 20.4438 2.33334 14 2.33334ZM19.3508 11.1044C19.9318 11.0372 20.4848 10.8832 21 10.6592C20.6135 11.2288 20.1268 11.7235 19.5636 12.1194L19.5734 12.4862C19.5734 16.2242 16.6852 20.5334 11.4016 20.5334C9.84336 20.5356 8.31667 20.0942 7.00001 19.2608C7.22541 19.286 7.45361 19.3 7.68461 19.3C8.97491 19.3034 10.2298 18.8779 11.2518 18.0904C10.6561 18.0821 10.0777 17.8885 9.59704 17.5365C9.11635 17.1845 8.75719 16.6916 8.56941 16.1262C9.00018 16.2069 9.44351 16.1906 9.86721 16.0786C9.22181 15.9542 8.63946 15.6101 8.21929 15.1047C7.79913 14.5992 7.56714 13.9638 7.56281 13.3066V13.2716C7.96301 13.4901 8.40903 13.6115 8.86481 13.6258C8.09481 13.1204 7.58661 12.2524 7.58661 11.2724C7.58592 10.7723 7.7204 10.2813 7.97581 9.85136C8.70301 10.728 9.60496 11.4435 10.6241 11.9521C11.6433 12.4607 12.7573 12.7513 13.895 12.8054C13.846 12.5982 13.8208 12.3826 13.8208 12.1614C13.8208 10.6004 15.106 9.33336 16.6922 9.33336C17.0839 9.33212 17.4717 9.41068 17.832 9.56424C18.1924 9.71781 18.5176 9.94316 18.788 10.2266C19.4432 10.1006 20.0564 9.86396 20.6136 9.54056C20.398 10.2 19.9444 10.7544 19.3508 11.1044Z'
      fill='#939393'
    />
  </svg>
);
const Twitter = memo(TwitterSVG);
export default Twitter;
