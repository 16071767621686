import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import cls from './Form.module.scss';
import { InputIcon } from './InputIcon';
import {
  getFormTo,
  getFormAmount,
  validation,
} from '../../model/selector/getFormData/getFormData';
import { sendFormActions } from '../../model/slice/sendMoneySlice';
import { getBalanceSOL, getBalanceUSDC } from '@/entities/Headers';
import { ErrorsInputSVG } from '@/shared/assets/svg';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { Button } from '@/shared/ui/Buttons';
import { Input } from '@/shared/ui/Inputs';
import { HStack, VStack } from '@/shared/ui/Stack';

export interface FormProps {
  className?: string;
  setOpenModal: () => void;
  setOpenConfirm: Dispatch<SetStateAction<boolean>>;
}
export const Form = memo((props: FormProps) => {
  const { className, setOpenModal, setOpenConfirm } = props;
  const dispatch = useAppDispatch();
  const valueTo = useAppSelector(getFormTo);
  const balanceSOL = useAppSelector(getBalanceSOL);
  const balanceUSDC = useAppSelector(getBalanceUSDC);
  const validations = useAppSelector(validation);
  const isUSDC = localStorage.getItem('sendType') === 'USDC';
  const amount = useAppSelector(getFormAmount);

  const [isFocus, setIsFocus] = useState(false);

  const isValidAmount = useMemo(() => {
    if (isUSDC) return Number(amount) < Number(balanceUSDC);
    return Number(amount) < Number(balanceSOL);
  }, [amount, balanceSOL, balanceUSDC, isUSDC]);

  const valid = useMemo(
    () => !validations && !isFocus && !!valueTo.length,
    [isFocus, validations, valueTo.length],
  );
  const availableCount = useMemo(() => {
    if (isUSDC) return `${balanceUSDC} USDC`;
    return `${balanceSOL} SOL`;
  }, [balanceSOL, balanceUSDC, isUSDC]);

  const onChangeTo = useCallback(
    (value: string) => {
      dispatch(sendFormActions.setSendTo(value));
    },
    [dispatch],
  );

  const onChangeAmount = useCallback(
    (value: string) => {
      dispatch(sendFormActions.setAmount(value));
    },
    [dispatch],
  );

  const onCancel = useCallback(() => {
    setOpenModal();
    setOpenConfirm(false);
    dispatch(sendFormActions.setInitState());
  }, [dispatch, setOpenConfirm, setOpenModal]);

  const errorMessage = useMemo(() => {
    if (Number(balanceSOL) < 0.001) return 'Not enough Solana to transfer';
    if (!isValidAmount) return 'Insufficient balance';
    return undefined;
  }, [balanceSOL, isValidAmount]);

  return (
    <VStack className={classNames(cls.Form, {}, [className])} gap={1}>
      <Input
        isValidate
        autoComplete='off'
        isError={valid}
        label='To'
        value={valueTo}
        className={cls.input}
        placeholder='Please input receive address'
        errorIcon={<ErrorsInputSVG width='14' height='14' />}
        error={valid ? 'Invalid Solana address' : undefined}
        onChange={onChangeTo}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      />
      <HStack max gap={0.5} className={cls.inputValue}>
        <Input
          isValidate
          autoComplete='off'
          value={amount}
          label='Amount'
          className={cls.input}
          placeholder='0.00'
          type='number'
          endIcon={<InputIcon />}
          errorClass={cls.errorMessage}
          endIconClass={cls.inputEndIcon}
          errorIcon={<ErrorsInputSVG width='14' height='14' />}
          isError={!isValidAmount && Number(balanceSOL) < 0.001}
          error={errorMessage}
          onChange={onChangeAmount}
        />
        <HStack gap={0.2} className={cls.availableMount}>
          <span className={cls.Available}>Available:</span>
          <span className={cls.count}> {availableCount}</span>
        </HStack>
      </HStack>
      <Button
        fullWidth
        className={cls.btn}
        typeButton='primary'
        text='Next'
        size='small'
        disabled={
          !validations ||
          !isValidAmount ||
          amount.length < 1 ||
          Number(balanceSOL) < 0.001
        }
        onClick={() => setOpenConfirm(true)}
      />
      <Button
        fullWidth
        typeButton='ghost'
        text='cancel'
        size='small'
        onClick={onCancel}
      />
    </VStack>
  );
});
