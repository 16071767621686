import { StateSchema } from '@/app/providers/StoreProvider';

export const getIsLoadingBurnedCardsPage = (state: StateSchema) =>
  state.burned.isLoadingBurnedCards;

export const getBurnIdDataBurnedCardsPage = (state: StateSchema) =>
  state.burned.burnIdData;
export const getBurnIdBurnedCardsPage = (state: StateSchema) =>
  state.burned.burnIdData.id;

export const getTotalBurnedCardsPage = (state: StateSchema) =>
  state.burned.allBurnedCards.total;
export const getTotalAllCoastBurnedCardsPage = (state: StateSchema) =>
  state.burned.allBurnedCards.totalCardsCoast;
export const getFindTotalBurnedCardsPage = (state: StateSchema) =>
  state.burned.allBurnedCards.findTotal;
export const getTotalPagesBurnedCardsPage = (state: StateSchema) =>
  state.burned.allBurnedCards.totalPages;
export const getAllBurnedCardsPage = (state: StateSchema) =>
  state.burned.allBurnedCards.filterNFtCard;
export const getQtyByCategoryBurnedCardsPage = (state: StateSchema) =>
  state.burned.allBurnedCards.cardsQtyByCategory;
