import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Shipment: FC<ISvgProps> = ({ width = '18', height = '18' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 0.027832C8.78469 0.027832 8.57315 0.0841497 8.38637 0.19116L4.96529 2.11552C4.95029 2.12317 4.93559 2.13144 4.92121 2.14031L1.5139 4.05692L1.51168 4.05818C1.3451 4.15278 1.20268 4.28397 1.0949 4.4414C1.07477 4.46702 1.05639 4.49455 1.04003 4.52391C1.02918 4.54337 1.01948 4.56316 1.01091 4.58321C0.922743 4.75712 0.876051 4.9495 0.875 5.14518V12.8517L0.875009 12.855C0.876197 13.0762 0.935679 13.2931 1.04746 13.484C1.15924 13.6748 1.31936 13.8328 1.51168 13.942L1.5139 13.9433L8.38639 17.809C8.57316 17.916 8.7847 17.9724 9 17.9724C9.2153 17.9724 9.42684 17.916 9.61361 17.809L16.4861 13.9433L16.4883 13.942C16.6806 13.8328 16.8408 13.6748 16.9525 13.484C17.0643 13.2931 17.1238 13.0762 17.125 12.855L16.5 12.8517H17.125L17.125 5.14853L17.125 5.14518C17.1239 4.94959 17.0773 4.75731 16.9892 4.58346C16.98 4.56181 16.9694 4.54045 16.9575 4.51949C16.9417 4.49176 16.9242 4.46569 16.9051 4.44135C16.7973 4.28394 16.6549 4.15277 16.4883 4.05818L9.61363 0.19116C9.42685 0.0841499 9.21531 0.027832 9 0.027832ZM2.125 12.8486V5.84422L8.44221 9.36551L8.38316 16.373L2.12871 12.8549C2.12762 12.8542 2.12671 12.8533 2.12606 12.8522C2.12542 12.8511 2.12506 12.8499 2.125 12.8486ZM9.63328 16.3638L15.8711 12.8551C15.8721 12.8544 15.8733 12.8533 15.8739 12.8522C15.8746 12.8511 15.8749 12.8499 15.875 12.8486V5.85325L13.4531 7.22911V10.9142C13.4531 11.2594 13.1733 11.5392 12.8281 11.5392C12.4829 11.5392 12.2031 11.2594 12.2031 10.9142V7.93921L9.69226 9.36558L9.63328 16.3638ZM9.06741 8.28292L11.5608 6.86647L5.25339 3.38764L2.7806 4.77858L9.06741 8.28292ZM15.2278 4.78331L12.8358 6.14216L6.53478 2.66686L9 1.28018L15.2278 4.78331Z'
      fill='inherit'
    />
  </svg>
);

const ShipmentSVG = memo(Shipment);
export default ShipmentSVG;
