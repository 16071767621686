import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Account: FC<ISvgProps> = ({ width = '18', height = '17' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 18 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.62508 6.5C4.62508 4.08375 6.58383 2.125 9.00008 2.125C11.4163 2.125 13.3751 4.08375 13.3751 6.5C13.3751 8.89595 11.4491 10.8421 9.06085 10.8746C9.04061 10.8745 9.02036 10.8744 9.0001 10.8744C8.97984 10.8744 8.95957 10.8745 8.93932 10.8746C6.55108 10.8421 4.62508 8.89596 4.62508 6.5ZM6.11457 11.3295C4.4736 10.347 3.37508 8.55176 3.37508 6.5C3.37508 3.3934 5.89347 0.875 9.00008 0.875C12.1067 0.875 14.6251 3.3934 14.6251 6.5C14.6251 8.55176 13.5266 10.3469 11.8856 11.3295C12.5103 11.5316 13.1147 11.7996 13.688 12.1306C15.1132 12.9536 16.2968 14.1373 17.1195 15.5626C17.2921 15.8616 17.1896 16.2438 16.8907 16.4164C16.5917 16.5889 16.2095 16.4865 16.0369 16.1875C15.3239 14.9522 14.2982 13.9264 13.0629 13.2131C11.8462 12.5106 10.4684 12.1357 9.06418 12.1246C9.04284 12.1249 9.02147 12.125 9.00008 12.125C8.97869 12.125 8.95733 12.1249 8.936 12.1246C7.53178 12.1357 6.154 12.5106 4.93728 13.2131C3.70205 13.9264 2.67634 14.9522 1.96327 16.1875C1.79071 16.4865 1.40848 16.5889 1.10953 16.4164C0.81058 16.2438 0.708123 15.8616 0.880685 15.5626C1.70345 14.1373 2.88696 12.9536 4.31223 12.1306C4.88555 11.7996 5.48985 11.5316 6.11457 11.3295Z'
      fill='inherit'
    />
  </svg>
);
const AccountSVG = memo(Account);
export default AccountSVG;
