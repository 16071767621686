import { FC, memo } from 'react';
import { ISvgProps } from './types';

const Comment: FC<ISvgProps> = ({ width = '18', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.43753 2.3125V13.7312L4.49125 12.0083C4.5777 11.9358 4.67654 11.8795 4.78301 11.8421L5.07207 11.7405C5.17193 11.7054 5.277 11.6875 5.38284 11.6875H15.5625V2.3125H2.43753ZM1.02018 0.895146C1.3132 0.602121 1.71063 0.4375 2.12503 0.4375H15.875C16.2894 0.4375 16.6869 0.60212 16.9799 0.895146C17.2729 1.18817 17.4375 1.5856 17.4375 2V12C17.4375 12.4144 17.2729 12.8118 16.9799 13.1049C16.6869 13.3979 16.2894 13.5625 15.875 13.5625H5.55601L3.12117 15.6051C2.89353 15.7935 2.61714 15.9134 2.32408 15.9511C2.03101 15.9887 1.73328 15.9425 1.46544 15.8178C1.19758 15.693 0.970585 15.4949 0.810776 15.2464C0.650969 14.9979 0.5649 14.7091 0.562559 14.4137L0.5625 14.4062L0.562529 2C0.562529 1.5856 0.72715 1.18817 1.02018 0.895146ZM5.5625 5.4375C5.5625 4.91973 5.98223 4.5 6.5 4.5H11.5C12.0178 4.5 12.4375 4.91973 12.4375 5.4375C12.4375 5.95527 12.0178 6.375 11.5 6.375H6.5C5.98223 6.375 5.5625 5.95527 5.5625 5.4375ZM6.5 7.625C5.98223 7.625 5.5625 8.04473 5.5625 8.5625C5.5625 9.08027 5.98223 9.5 6.5 9.5H11.5C12.0178 9.5 12.4375 9.08027 12.4375 8.5625C12.4375 8.04473 12.0178 7.625 11.5 7.625H6.5Z'
      fill='#DFE0E1'
    />
  </svg>
);
const CommentSVG = memo(Comment);
export default CommentSVG;
