import { FC } from 'react';
import './loadingScreen.scss';
import { ICancelLoadingScreenProps } from '../types';
import { Spinner } from '@/shared/ui/Loaders';

const CancelOfferOrListingLoading: FC<ICancelLoadingScreenProps> = ({
  typeModal,
}) => (
  <div className='loading-screen-cancelation'>
    <Spinner size={40} />
    <div className='loading-screen-cancelation__description'>
      {typeModal === 'offer'
        ? 'Cancellation of the offer  is  in progress...'
        : 'Cancellation of the listing is in progress...'}
    </div>
  </div>
);

export default CancelOfferOrListingLoading;
