import cls from './SuccessInvited.module.scss';
import { SuccessTitle } from '../../..';
import { classNames } from '@/shared/lib/classNames/classNames';
import { Button } from '@/shared/ui/Buttons';
import { PTag } from '@/shared/ui/Ptags/Ptags';

export interface UpdateListSealedBoxDefaultProps {
  className?: string;
  openCard: () => void;
  inviteStatus: string;
}

export const SuccessInvited = (props: UpdateListSealedBoxDefaultProps) => {
  const { className, openCard, inviteStatus } = props;

  const isInvitedSuccess = inviteStatus === 'success';
  return (
    <div className={classNames(cls.SuccessInvited, {}, [className])}>
      <SuccessTitle
        font='H4-CL'
        type={isInvitedSuccess ? 'success' : 'error'}
        text={
          isInvitedSuccess
            ? 'The invitation has been successfully sent!'
            : 'Something went wrong...'
        }
      />
      <PTag tag='name' className={cls.ordinaryText}>
        {isInvitedSuccess
          ? 'Well let you know as soon as your invitation is accepted'
          : 'Please try again later...'}
      </PTag>
      <div className={cls.button}>
        <Button
          fullWidth
          typeButton='secondary'
          text='go to my cards'
          onClick={openCard}
        />
      </div>
    </div>
  );
};
