import { useWallet } from '@solana/wallet-adapter-react';
import { useCallback } from 'react';
import { useAppDispatch } from '../redux';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const useLogOut = () => {
  const dispatch = useAppDispatch();
  const { disconnect, connected } = useWallet();
  const logOut = useCallback(
    (
      reload?: boolean,
      isMagic?: boolean,
      isSnackbar?: boolean,
      magicLogout?: () => Promise<void>,
    ) => {
      if (connected) disconnect();
      if (isMagic) {
        magicLogout?.();
        indexedDB.deleteDatabase('MagicAuthLocalStorageDB');
      }
      if (!isMagic && isSnackbar)
        showSnackbar('You have been logged out.', 'success');
      if (reload) window.location.reload();
      localStorage.clear();
      sessionStorage.clear();
      dispatch({ type: 'USER_LOGOUT' });
    },
    [connected, disconnect, dispatch],
  );

  return { logOutRedux: logOut };
};
