import { copyInBuffer } from './copyInBuffer';
import {
  TransferSVG,
  DollarBoldSVG,
  ErrorSVG,
  BurnSVG,
} from '@/shared/assets/svg';
import {
  CopyLinkCircleSVG,
  FacebookSVG,
  TelegramSVG,
  WhatsappSVG,
  TwitterSVG,
} from '@/shared/assets/svg/SocialMedia/Modal';

export const getItemTreeDots = (
  burnDirect: () => void,
  onClick: () => void,
  cardId?: string,
) => [
  { Icon: <TransferSVG />, content: 'Transfer pNFT', onClick: () => onClick() },
  {
    Icon: <DollarBoldSVG />,
    content: 'Suggest Value',
    href: `mailto:info@collectorcrypt.com?subject=Suggest%20a%20new%20insured%20value%20for%20${cardId}`,
    onClick: () => console.log(),
  },
  {
    content: (
      <div style={{ border: '1px solid var(--g-600)', width: '100%' }} />
    ),
    disabled: true,
    onClick: () => console.log(),
  },
  {
    Icon: <ErrorSVG />,
    content: 'Remove from Marketplace',
    onClick: () => console.log(),
  },
  {
    Icon: <BurnSVG fill='#FF4445' />,
    content: <span style={{ color: '#FF4445' }}>Withdraw Card</span>,
    onClick: () => burnDirect(),
  },
];

export const getItemShare = (text: string, iconsType: string, link: string) => {
  const icons = {
    copy: <CopyLinkCircleSVG />,
    facebook: <FacebookSVG />,
    telegram: <TelegramSVG />,
    twitter: <TwitterSVG />,
    whatsapp: <WhatsappSVG />,
  };

  return [
    {
      Icon: icons.copy,
      content: 'Copy link',
      onClick: () => copyInBuffer(link),
    },
    {
      Icon: icons.twitter,
      content: 'Twitter',
      onClick: () => {
        const textWithValidLink = text.replace(
          '@collector',
          '@Collector_Crypt',
        );
        window.open(
          `http://twitter.com/share?text=${encodeURIComponent(
            textWithValidLink,
          )}`,
          '_blank',
          'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0',
        );
      },
    },
    {
      Icon: icons.telegram,
      content: 'Telegram',
      onClick: () => {
        window.open(
          `https://telegram.me/share/url?url=${link}&text=${text}`,
          '',
          'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0',
        );
      },
    },
    {
      Icon: icons.whatsapp,
      content: 'Whatsapp',
      onClick: () => {
        window.open(
          `https://wa.me?text=${encodeURIComponent(text)}`,
          '',
          'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0',
        );
      },
    },
    {
      Icon: icons.facebook,
      content: 'Facebook',
      onClick: () => {
        window.open(
          `https://facebook.com/sharer.php?u=${encodeURIComponent(link)}`,
          '',
          'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0',
        );
      },
    },
  ];
};
