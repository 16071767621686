import { OAuthRedirectResult } from '@magic-ext/oauth';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MagicUserMetadata } from 'magic-sdk';
import { AuthMagicLink, MagicLinkSchema } from '../types/magicLink';

const initialState: MagicLinkSchema = {
  isAuthMagicLink: false,
  isLoadingMagicLink: false,
  tokenEmail: '',
  typeAuth: '',
  userDataGoogleAuth: null,
  userDataMagicLink: null,
  walletMagicLink: '',
};

export const magicLinkSlice = createSlice({
  initialState,
  name: 'magicLink',
  reducers: {
    setClearMagicLink: () => initialState,
    setIsAuthMagicLinkSlice: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthMagicLink = payload;
    },
    setIsLoadingMagicLinkSlice: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isLoadingMagicLink = payload;
    },
    setTokenMagicLinkSlice: (state, { payload }: PayloadAction<string>) => {
      state.tokenEmail = payload;
    },
    setTypeAuthMagicLinkSlice: (
      state,
      { payload }: PayloadAction<AuthMagicLink>,
    ) => {
      state.typeAuth = payload;
    },
    setUserDataGoogleAuthSlice: (
      state,
      { payload }: PayloadAction<OAuthRedirectResult>,
    ) => {
      state.userDataGoogleAuth = payload;
    },
    setUserDataMagicLinkSlice: (
      state,
      { payload }: PayloadAction<MagicUserMetadata>,
    ) => {
      state.userDataMagicLink = payload;
    },
    setWalletMagicLinkSlice: (state, { payload }: PayloadAction<string>) => {
      state.walletMagicLink = payload;
    },
  },
});

export const {
  setIsAuthMagicLinkSlice,
  setClearMagicLink,
  setTokenMagicLinkSlice,
  setWalletMagicLinkSlice,
  setTypeAuthMagicLinkSlice,
  setIsLoadingMagicLinkSlice,
  setUserDataGoogleAuthSlice,
  setUserDataMagicLinkSlice,
} = magicLinkSlice.actions;
export const { reducer: magicLinkReducer } = magicLinkSlice;
