import { FC, memo } from 'react';
import { ISvgProps } from './types';

const HeaderNotificationsBellWithoutDot: FC<ISvgProps> = ({
  width = '24',
  height = '24',
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 23 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21.8588 17.5939C21.2288 16.5177 20.4938 14.4702 20.4938 10.8127V10.0339C20.501 7.59972 19.5492 5.2606 17.8444 3.52304C16.1396 1.78547 13.819 0.789297 11.3851 0.750194C10.1846 0.742316 8.99463 0.974357 7.88506 1.43269C6.76001 1.8888 5.73672 2.56376 4.87455 3.41842C4.01238 4.27308 3.3285 5.29042 2.86257 6.41144C2.39455 7.51865 2.1564 8.70941 2.16257 9.91144V10.7864C2.16257 14.4439 1.41882 16.4914 0.797565 17.5677C0.600633 17.9048 0.497885 18.2886 0.500065 18.6789C0.497079 19.0627 0.596772 19.4404 0.788815 19.7727C0.97937 20.1081 1.25556 20.3869 1.58916 20.5806C1.92276 20.7743 2.30181 20.876 2.68757 20.8752H6.55507C6.65747 22.0694 7.20422 23.1817 8.08723 23.9923C8.97023 24.8028 10.1252 25.2525 11.3238 25.2525C12.5224 25.2525 13.6774 24.8028 14.5604 23.9923C15.4434 23.1817 15.9902 22.0694 16.0926 20.8752H19.9688C20.3531 20.8744 20.7303 20.772 21.0623 20.5784C21.3942 20.3848 21.669 20.1068 21.8588 19.7727C22.0509 19.4436 22.1507 19.0688 22.1476 18.6877C22.1493 18.304 22.0497 17.9267 21.8588 17.5939ZM12.8726 21.9689C12.5942 22.249 12.2453 22.4484 11.8628 22.5462C11.4803 22.644 11.0784 22.6364 10.6998 22.5242C10.3213 22.4121 9.98013 22.1996 9.71256 21.9093C9.445 21.619 9.26099 21.2616 9.18007 20.8752H13.4676C13.3821 21.2958 13.1752 21.6821 12.8726 21.9864V21.9689ZM3.41382 18.2502C4.41251 15.902 4.88154 13.3627 4.78757 10.8127V9.93769C4.78217 9.07266 4.95182 8.21546 5.28631 7.41769C5.6157 6.61862 6.10049 5.89292 6.71256 5.28269C7.94011 4.06152 9.60105 3.37575 11.3326 3.37519C13.0741 3.40738 14.7328 4.12422 15.9496 5.3705C17.1664 6.61678 17.8433 8.29219 17.8338 10.0339V10.8127C17.7465 13.3656 18.2277 15.906 19.2426 18.2502H3.41382Z'
      fill='#828585'
    />
  </svg>
);
const HeaderNotificationsBellWithoutDotSVG = memo(
  HeaderNotificationsBellWithoutDot,
);
export default HeaderNotificationsBellWithoutDotSVG;
