import { FC, memo, useCallback } from 'react';
import cls from './cancelModal.module.scss';
import { selectTypeContent } from '../lib/selectTypeContent/selectTypeContent';
import { ICancelModal } from '../model/types';
import { getMyProfileEmail } from '@/entities/MyProfile';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { IconButton, Button } from '@/shared/ui/Buttons';
import { Modal } from '@/shared/ui/Modal';
import { HStack, VStack } from '@/shared/ui/Stack';

export const CancelModal: FC<ICancelModal> = memo(props => {
  const { open, setOpen, typeContent, onClick, shipment } = props;

  const profileEmail = useAppSelector(getMyProfileEmail);

  const currentContent = selectTypeContent(
    typeContent,
    shipment ? shipment.id : '',
    profileEmail,
  );
  const { title, text, textFirstButton, textSecondButton } = currentContent;

  const handleModalClose = () => setOpen(false);

  const clickFirstButton = useCallback(
    () =>
      typeContent === 'cancelShipment' ? onClick && onClick() : setOpen(false),
    [onClick, setOpen, typeContent],
  );
  const clickSecondButton = useCallback(
    () =>
      typeContent === 'cancelShipment' ? setOpen(false) : onClick && onClick(),
    [setOpen, onClick, typeContent],
  );

  return (
    <Modal
      isOpen={open}
      className={cls['cancel-modal']}
      onClose={handleModalClose}
    >
      <HStack align='center' justify='between'>
        <h1>{title}</h1>
        <HStack className={cls.close} align='start'>
          <IconButton size='32' onClick={handleModalClose}>
            <CloseWhiteSVG />
          </IconButton>
        </HStack>
      </HStack>
      <VStack justify='between' gap={2} className={cls['main-content']}>
        <p>{text}</p>
        <HStack max gap={1} className={cls.buttons}>
          <Button
            fullWidth
            typeButton='secondary'
            text={textFirstButton}
            size='large'
            onClick={clickFirstButton}
          />
          <Button
            fullWidth
            typeButton='primary'
            text={textSecondButton}
            size='large'
            onClick={clickSecondButton}
          />
        </HStack>
      </VStack>
    </Modal>
  );
});
