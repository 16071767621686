import { FC, memo, useState } from 'react';
import './acceptOffer.scss';
import AcceptOfferDefault from './AcceptOfferDefault/AcceptOfferDefault';
import AcceptOfferSuccess from './AcceptOfferSuccess/AcceptOfferSuccess';
import { IAcceptOfferModalProps } from './types';
import { Modal } from '@/shared/ui/Modal';

export const AcceptOfferModal: FC<IAcceptOfferModalProps> = memo(
  ({
    open,
    setOpen,
    cardData,
    offer,
    getOffers,
    userWallet,
    getDataCard,
    setisLoading,
    getNFTCardActivities,
  }) => {
    const [transactionStatus, setTransactionStatus] = useState('none');
    const [transaction, setTransaction] = useState({
      transactionId: '',
      transactionUrl: '',
    });

    const handleModalClose = () => {
      setOpen('acceptOffer', { isOpen: false });
      setTransactionStatus('none');
    };

    const changeTransactionStatus = (status: string) => {
      setTransactionStatus(status);
    };

    return (
      <Modal
        isOpen={open}
        className='accept-offer-modal'
        onClose={handleModalClose}
      >
        {transactionStatus === 'resolved' ? (
          <AcceptOfferSuccess
            getDataCard={getDataCard}
            cardData={cardData}
            offer={offer}
            transaction={transaction}
            handleModalClose={handleModalClose}
          />
        ) : (
          <AcceptOfferDefault
            getDataCard={getDataCard}
            cardData={cardData}
            setisLoading={setisLoading}
            handleModalClose={handleModalClose}
            changeTransactionStatus={changeTransactionStatus}
            transactionStatus={transactionStatus}
            offer={offer}
            getOffers={getOffers}
            setTransaction={setTransaction}
            userWallet={userWallet}
            getNFTCardActivities={getNFTCardActivities}
          />
        )}
      </Modal>
    );
  },
);
