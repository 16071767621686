import { createSelector } from '@reduxjs/toolkit';
import { PublicKey } from '@solana/web3.js';
import { StateSchema } from '@/app/providers/StoreProvider/config/stateSchema';

export const getFormTo = (state: StateSchema) => state.sendForm?.to || '';
export const getIsOpen = (state: StateSchema) =>
  state.sendForm?.isOpened || false;
export const getFormAmount = (state: StateSchema) =>
  state.sendForm?.amount || '';

export function isPublicKey(address: string): boolean {
  try {
    // eslint-disable-next-line no-new
    new PublicKey(address);
  } catch (_) {
    return false;
  }
  return true;
}

export const ValidateWallet = (value: string): boolean => {
  const regWallet = /([1-9A-HJ-NP-Za-km-z]{32,44})/;
  return regWallet.test(value);
};

export const validation = createSelector(
  getFormTo,
  to => isPublicKey(to) && ValidateWallet(to),
);
