import { FC, memo } from 'react';
import { ISvgProps } from './types';

const MapPoint: FC<ISvgProps> = ({ width = '20', height = '20' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.2435 4.3685C7.23978 3.37221 8.59104 2.8125 10 2.8125C11.409 2.8125 12.7602 3.37221 13.7565 4.3685C14.7528 5.36478 15.3125 6.71604 15.3125 8.125C15.3125 10.6 13.9264 12.884 12.4176 14.6192C11.6746 15.4736 10.9292 16.1633 10.3694 16.6392C10.2341 16.7542 10.1101 16.8563 10 16.945C9.88992 16.8563 9.76588 16.7542 9.63061 16.6392C9.07078 16.1633 8.32541 15.4736 7.58244 14.6192C6.07361 12.884 4.6875 10.6 4.6875 8.125C4.6875 6.71604 5.24721 5.36478 6.2435 4.3685ZM9.46185 18.8927C9.46213 18.8929 9.46238 18.893 10 18.125L9.46238 18.893C9.78518 19.119 10.2148 19.119 10.5376 18.893L10 18.125C10.5376 18.893 10.5379 18.8929 10.5381 18.8927L10.5406 18.891L10.5458 18.8873L10.563 18.8751C10.5773 18.8648 10.5974 18.8503 10.623 18.8317C10.674 18.7944 10.7466 18.7406 10.8378 18.6709C11.0201 18.5316 11.2771 18.3285 11.5837 18.0678C12.1958 17.5476 13.0129 16.792 13.8324 15.8495C15.4486 13.991 17.1875 11.275 17.1875 8.125C17.1875 6.21876 16.4302 4.39059 15.0823 3.04267C13.7344 1.69475 11.9062 0.9375 10 0.9375C8.09376 0.9375 6.26559 1.69475 4.91767 3.04267C3.56975 4.39059 2.8125 6.21876 2.8125 8.125C2.8125 11.275 4.55139 13.991 6.16756 15.8495C6.98709 16.792 7.80422 17.5476 8.41627 18.0678C8.72295 18.3285 8.97986 18.5316 9.16216 18.6709C9.25335 18.7406 9.32601 18.7944 9.37705 18.8317C9.40257 18.8503 9.4227 18.8648 9.43704 18.8751L9.45419 18.8873L9.45942 18.891L9.46185 18.8927ZM8.4375 8.125C8.4375 7.26205 9.13705 6.5625 10 6.5625C10.8629 6.5625 11.5625 7.26205 11.5625 8.125C11.5625 8.98795 10.8629 9.6875 10 9.6875C9.13705 9.6875 8.4375 8.98795 8.4375 8.125ZM10 4.6875C8.10152 4.6875 6.5625 6.22652 6.5625 8.125C6.5625 10.0235 8.10152 11.5625 10 11.5625C11.8985 11.5625 13.4375 10.0235 13.4375 8.125C13.4375 6.22652 11.8985 4.6875 10 4.6875Z'
      fill='#DFE0E1'
    />
  </svg>
);
const MapPointSVG = memo(MapPoint);
export default MapPointSVG;
