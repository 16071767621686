import { Dispatch, SetStateAction, memo, useCallback, useState } from 'react';
import cls from './QRCode.module.scss';
import { getAuthData } from '@/entities/Auth';
import { SendMoneyHeader } from '@/entities/Headers';
import { Copy2SVG } from '@/shared/assets/svg';
import { InfoCircleSVG } from '@/shared/assets/svg/AlertsIcons';
import USDCIcon from '@/shared/assets/svg/HeaderPopover/USDCMedium.svg';
import SolanaIconM from '@/shared/assets/svg/HeaderPopover/solanaLogoMedium.svg';
import { classNames } from '@/shared/lib/classNames/classNames';
import { sliceString } from '@/shared/lib/helpers/scliceStringFunction';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { Tooltip } from '@/shared/ui/Popovers';
import { QRCode } from '@/shared/ui/QRCode/QRCode';
import { HStack, VStack } from '@/shared/ui/Stack';

export interface QRCodeProps {
  className?: string;
  setOpenQR: Dispatch<SetStateAction<boolean>>;
  setOpenModal: () => void;
}
export const SendQRCode = memo((props: QRCodeProps) => {
  const { className, setOpenModal, setOpenQR } = props;
  const { wallet } = useAppSelector(getAuthData);
  const [copyText, setCopyText] = useState('Copy');
  const sendType = localStorage.getItem('sendType');
  const handleCopyWalletAddress = useCallback((localWallet: string) => {
    navigator.clipboard.writeText(localWallet);
    setCopyText('Copied!');
  }, []);

  const Icon = useCallback(() => {
    if (sendType === 'USDC') return <USDCIcon />;
    return (
      <SolanaIconM style={{ background: '#000000', borderRadius: '50%' }} />
    );
  }, [sendType]);

  return (
    <VStack className={classNames(cls.SendQRCode, {}, [className])}>
      <SendMoneyHeader
        setOpen={setOpenQR}
        setOpenModal={setOpenModal}
        title={sendType === 'USDC' ? 'Deposit USDC' : 'Deposit SOL'}
      />
      <QRCode
        id='qrCodeWallet'
        className={cls.QRCode}
        value={`${wallet}` || 'someStringForTest'}
        backgroundColor='#dfe0e1'
        dotsColor='#121212'
        cornersColor='#121212'
        circleColor='#121212'
      />
      <VStack max gap={1.75}>
        <HStack max className={cls.walletInfo} justify='between'>
          <HStack gap={0.5}>
            <Icon />
            <VStack>
              <p className={cls.address}>{sliceString(wallet || '')}</p>
              <p className={cls.solana}>Solana</p>
            </VStack>
          </HStack>
          <Tooltip titleText={copyText} placement='bottom'>
            <div
              className={cls.copyText}
              onClick={() => handleCopyWalletAddress(wallet || '')}
            >
              <Copy2SVG />
            </div>
          </Tooltip>
        </HStack>
        <div className={cls.addressWrapper}>
          <p className={cls.walletName}>{wallet}</p>
        </div>
        <HStack className={cls.info} gap={0.5} align='start'>
          <div>
            <InfoCircleSVG />
          </div>
          <div className={cls.description}>
            This address can only accept assets on <span>SOL (Solana)</span>
            .Sending any other types of tokens to this address will result in
            permanent loss.
          </div>
        </HStack>
      </VStack>
    </VStack>
  );
});
