import { FC, useMemo, useState } from 'react';
import cls from './footer.module.scss';
import {
  DiscordSVG,
  YouTubeSVG,
  TwitterSVG,
  TelegramSVG,
} from '@/shared/assets/svg/SocialMedia/Footer';
import { IconButton } from '@/shared/ui/Buttons';
import { HStack } from '@/shared/ui/Stack';
import { PrivacyPolicyModal } from '@/widgets/PrivacyPolicy';

export const Footer: FC = () => {
  const social = useMemo(
    () => [
      { picture: <TwitterSVG />, ref: 'https://twitter.com/Collector_Crypt' },
      { picture: <DiscordSVG />, ref: 'https://discord.gg/dzYKMkQQKk' },
      {
        picture: <YouTubeSVG />,
        ref: 'https://www.youtube.com/channel/UCTd38OLkaYhXNIQ9LU9y3jQ',
      },
      { picture: <TelegramSVG />, ref: 'https://t.me/CollectorAnn' },
    ],
    [],
  );

  const [openModalPrivacy, setOpenPrivacy] = useState(false);
  const [openModalTermOfService, setOpenTermOfService] = useState(false);

  const handlePrivacy = () => setOpenPrivacy(true);
  const handleTerm = () => setOpenTermOfService(true);

  return (
    <HStack max className={cls.footer}>
      <HStack max justify='between' className={cls['footer-container']}>
        <HStack gap={1} className={cls['left-block']}>
          <h3>Join the community</h3>
          <HStack gap={0.5}>
            {social.map((item, index) => (
              <IconButton key={index} circle link={item.ref} size='40'>
                {item.picture}
              </IconButton>
            ))}
          </HStack>
        </HStack>
        <HStack gap={2} className={cls['right-block']}>
          <HStack>
            <p onClick={handlePrivacy}>Privacy Policy</p>
            <div className={cls.border} />
            <p onClick={handleTerm}>Terms of Service</p>
          </HStack>
          <h4>© COLLECTORCRYPT</h4>
          <PrivacyPolicyModal
            open={openModalPrivacy}
            setOpen={setOpenPrivacy}
            typeModal='PrivacyPolicy'
          />
          <PrivacyPolicyModal
            open={openModalTermOfService}
            setOpen={setOpenTermOfService}
            typeModal='TermOfService'
          />
        </HStack>
      </HStack>
    </HStack>
  );
};
