import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Profile: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21.1622 18.6875C20.1402 16.923 18.6741 15.4569 16.9097 14.435C16.412 14.1459 15.893 13.8951 15.3572 13.685C16.5562 12.7894 17.4428 11.5389 17.8912 10.1111C18.3395 8.68319 18.3269 7.15038 17.855 5.73011C17.3831 4.30983 16.476 3.0742 15.2623 2.19852C14.0486 1.32283 12.59 0.851562 11.0934 0.851562C9.59679 0.851562 8.13818 1.32283 6.92449 2.19852C5.71081 3.0742 4.80368 4.30983 4.33181 5.73011C3.85994 7.15038 3.84729 8.68319 4.29564 10.1111C4.744 11.5389 5.63059 12.7894 6.82965 13.685C6.29561 13.8937 5.77899 14.1445 5.28465 14.435C3.51485 15.4525 2.04526 16.9195 1.02465 18.6875C0.875467 18.9461 0.835116 19.2533 0.912474 19.5417C0.989833 19.83 1.17857 20.0758 1.43715 20.225C1.69574 20.3742 2.003 20.4145 2.29133 20.3372C2.57967 20.2598 2.82547 20.0711 2.97465 19.8125C3.79796 18.3856 4.98274 17.2008 6.40965 16.3775C7.8399 15.5698 9.45459 15.1453 11.0972 15.1453C12.7397 15.1453 14.3544 15.5698 15.7847 16.3775C17.2077 17.2032 18.3895 18.3876 19.2122 19.8125C19.311 19.9836 19.4531 20.1257 19.6242 20.2244C19.7954 20.3232 19.9895 20.3751 20.1872 20.375C20.3848 20.3767 20.5791 20.3248 20.7497 20.225C20.8778 20.1512 20.99 20.0528 21.0801 19.9356C21.1702 19.8184 21.2363 19.6845 21.2746 19.5417C21.3129 19.3989 21.3227 19.25 21.3034 19.1034C21.2841 18.9568 21.2361 18.8155 21.1622 18.6875ZM11.0972 3.12498C12.0613 3.12498 13.0039 3.4109 13.8056 3.94657C14.6072 4.48224 15.2321 5.24361 15.6011 6.1344C15.97 7.02519 16.0666 8.00539 15.8785 8.95105C15.6904 9.89671 15.2261 10.7653 14.5443 11.4471C13.8625 12.1289 12.9939 12.5932 12.0482 12.7813C11.1026 12.9694 10.1224 12.8729 9.23157 12.5039C8.34078 12.1349 7.57941 11.5101 7.04374 10.7084C6.50807 9.9067 6.22215 8.96417 6.22215 7.99998C6.22413 6.70766 6.73839 5.46884 7.6522 4.55503C8.56601 3.64122 9.80483 3.12697 11.0972 3.12498Z'
      fill='#dfe0e1'
    />
  </svg>
);
const ProfileSVG = memo(Profile);
export default ProfileSVG;
