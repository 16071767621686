import { FC, memo } from 'react';
import cls from './emptyInfo.module.scss';
import NoteSearchSVG from '@/shared/assets/svg/NoteSearchSVG';
import { NotFoundFaceSVG } from '@/shared/assets/svg/VerifyCardStatusIcon';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { Spinner } from '@/shared/ui/Loaders';
import { HStack, VStack } from '@/shared/ui/Stack';

export const EmptyInfo: FC = memo(() => {
  const { isLoadingCards } = useAppSelector(state => state.cards);
  const id = useAppSelector(
    state => state?.cards?.verifyNFtCard?.currentVerifyId,
  );

  if (isLoadingCards)
    return (
      <div className={cls.loader}>
        <Spinner />
      </div>
    );
  return (
    <HStack align='center' justify='center' className={cls['empty-info']}>
      {id !== '' ? (
        <VStack align='center' justify='center'>
          <NotFoundFaceSVG />
          <h3>Card not found!</h3>
          <VStack
            align='center'
            justify='center'
            className={cls['ordinary-text']}
          >
            <p>This token was not issued by Collector.</p>
            <p>We strongly discourage buying cards from 3rd-party platforms</p>
          </VStack>
        </VStack>
      ) : (
        <VStack
          align='center'
          justify='center'
          gap={1.25}
          className={cls['empty-state']}
        >
          <NoteSearchSVG />
          <p>Provide the link above to get information about the pNFT card</p>
        </VStack>
      )}
    </HStack>
  );
});
