import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Card: FC<ISvgProps> = ({ width = '14', height = '18' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 14 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.51818 0.379395C1.37553 0.379395 0.449219 1.3057 0.449219 2.44836V15.5518C0.449219 16.6945 1.37553 17.6208 2.51818 17.6208H11.4837C12.6264 17.6208 13.5527 16.6945 13.5527 15.5518V8.65526V2.44836C13.5527 1.3057 12.6264 0.379395 11.4837 0.379395H2.51818ZM12.1734 7.9656V2.44836C12.1734 2.06747 11.8646 1.7587 11.4837 1.7587H2.51818C2.1373 1.7587 1.82853 2.06747 1.82853 2.44836L1.82853 7.9656L12.1734 7.9656ZM1.82853 9.34491L12.1734 9.34491V15.5518C12.1734 15.9327 11.8646 16.2415 11.4837 16.2415H2.51818C2.1373 16.2415 1.82853 15.9327 1.82853 15.5518L1.82853 9.34491ZM3.20784 11.4139C3.20784 11.033 3.51661 10.7242 3.89749 10.7242H8.03543C8.41631 10.7242 8.72508 11.033 8.72508 11.4139C8.72508 11.7948 8.41631 12.1035 8.03543 12.1035L3.89749 12.1035C3.51661 12.1035 3.20784 11.7948 3.20784 11.4139ZM3.89749 13.4828C3.51661 13.4828 3.20784 13.7916 3.20784 14.1725C3.20784 14.5534 3.51661 14.8622 3.89749 14.8622L5.96646 14.8622C6.34735 14.8622 6.65611 14.5534 6.65611 14.1725C6.65611 13.7916 6.34735 13.4828 5.96646 13.4828H3.89749Z'
      fill='inherit'
    />
  </svg>
);

const CardSVG = memo(Card);
export default CardSVG;
