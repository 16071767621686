import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Payment: FC<ISvgProps> = ({ width = '17', height = '14' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 17 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.93306 1.55806C2.05027 1.44085 2.20924 1.375 2.375 1.375H13C13.3452 1.375 13.625 1.09518 13.625 0.75C13.625 0.404822 13.3452 0.125 13 0.125H2.375C1.87772 0.125 1.40081 0.322544 1.04917 0.674175C0.697544 1.02581 0.5 1.50272 0.5 2V12C0.5 12.4973 0.697544 12.9742 1.04917 13.3258C1.40081 13.6775 1.87772 13.875 2.375 13.875H14.875C15.2065 13.875 15.5245 13.7433 15.7589 13.5089C15.9933 13.2745 16.125 12.9565 16.125 12.625V3.875C16.125 3.54348 15.9933 3.22554 15.7589 2.99112C15.5245 2.7567 15.2065 2.625 14.875 2.625H2.375C2.20924 2.625 2.05027 2.55915 1.93306 2.44194C1.81585 2.32473 1.75 2.16576 1.75 2C1.75 1.83424 1.81585 1.67527 1.93306 1.55806ZM1.75 12V3.76777C1.94888 3.83809 2.16004 3.875 2.375 3.875H14.875V12.625H2.375C2.20924 12.625 2.05027 12.5592 1.93306 12.4419C1.81585 12.3247 1.75 12.1658 1.75 12ZM13 8.25C13 8.76777 12.5803 9.1875 12.0625 9.1875C11.5447 9.1875 11.125 8.76777 11.125 8.25C11.125 7.73223 11.5447 7.3125 12.0625 7.3125C12.5803 7.3125 13 7.73223 13 8.25Z'
      fill='inherit'
    />
  </svg>
);

const PaymentSVG = memo(Payment);
export default PaymentSVG;
