import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Announcements: FC<ISvgProps> = ({ width = '18', height = '15' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 18 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.12498 5.06935C1.28076 4.85731 1.49947 4.69983 1.74998 4.61935L15.5312 0.613102C15.7203 0.568419 15.9172 0.568419 16.1062 0.613102C16.2932 0.64275 16.4719 0.710851 16.6312 0.813102C16.7831 0.932662 16.9069 1.08415 16.9937 1.25685C17.0812 1.42916 17.1262 1.61988 17.125 1.8131V12.6444C17.1248 12.8374 17.0798 13.0278 16.9937 13.2006C16.9081 13.3723 16.784 13.522 16.6312 13.6381C16.4775 13.7565 16.2973 13.8358 16.1062 13.8694C16.0293 13.8758 15.9519 13.8758 15.875 13.8694C15.7587 13.8681 15.6431 13.8512 15.5312 13.8194L8.99998 11.9381V12.8506C9.00011 13.0155 8.96759 13.1789 8.9043 13.3312C8.84102 13.4835 8.74821 13.6218 8.63123 13.7381C8.39686 13.9706 8.08007 14.1009 7.74998 14.1006H5.24998C4.91989 14.1009 4.60309 13.9706 4.36873 13.7381C4.25175 13.6218 4.15894 13.4835 4.09565 13.3312C4.03236 13.1789 3.99985 13.0155 3.99998 12.8506V10.4819L1.78123 9.85685C1.51626 9.78111 1.28376 9.61994 1.11986 9.39841C0.955968 9.17687 0.869876 8.90738 0.874976 8.63185V5.81935C0.874976 5.54889 0.962698 5.28572 1.12498 5.06935V5.06935ZM15.875 12.6444V1.8131L8.99998 3.81935V10.6381L15.875 12.6444ZM5.24998 12.8506H7.74998V11.6006L5.24998 10.8694V12.8506ZM2.12498 8.6381L7.74998 10.2756V4.18185L2.12498 5.81935V8.6381Z'
      fill='inherit'
    />
  </svg>
);

const AnnouncementsSVG = memo(Announcements);
export default AnnouncementsSVG;
