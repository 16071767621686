import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const USDC: FC<ISvgProps> = ({ width = '16', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_3348_40852)'>
      <mask
        id='mask0_3348_40852'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='16'
        height='16'
      >
        <path d='M0 0H16V16H0V0Z' fill='white' />
      </mask>
      <g mask='url(#mask0_3348_40852)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8 0C12.4184 0 16 3.58164 16 8C16 12.418 12.4184 16 8 16C3.58164 16 0 12.418 0 8C0 3.58164 3.58164 0 8 0Z'
          fill='#2775CA'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.49996 13.5622C6.49996 13.7504 6.35259 13.8573 6.17059 13.8005C3.75177 13.0288 1.99805 10.7607 1.99805 8.08454C1.99805 5.40845 3.75177 3.14027 6.17059 2.36863C6.35259 2.31218 6.49996 2.41872 6.49996 2.60691V3.07145C6.49996 3.19672 6.40287 3.34418 6.2835 3.385C4.36977 4.08772 2.99886 5.92618 2.99886 8.08136C2.99886 10.2365 4.36977 12.0754 6.2835 12.7777C6.40287 12.8217 6.49996 12.9659 6.49996 13.0916V13.5622Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.49823 11.8335C8.49823 11.9717 8.38532 12.0846 8.24732 12.0846H7.74841C7.61041 12.0846 7.4975 11.9717 7.4975 11.8335V11.043C6.40568 10.8957 5.8725 10.2838 5.72814 9.45249C5.70296 9.31113 5.81587 9.18258 5.96023 9.18258H6.53123C6.65041 9.18258 6.75087 9.2674 6.77596 9.3864C6.88268 9.88213 7.16814 10.2618 8.04332 10.2618C8.68959 10.2618 9.14778 9.90094 9.14778 9.36158C9.14778 8.82176 8.87787 8.61794 7.93041 8.46094C6.53123 8.27276 5.86932 7.84913 5.86932 6.75113C5.86932 5.90413 6.50941 5.2454 7.50068 5.10413V4.32922C7.50068 4.19113 7.61368 4.07812 7.75159 4.07812H8.2505C8.3885 4.07812 8.50141 4.19113 8.50141 4.32922V5.12613C9.30768 5.27067 9.81905 5.7284 9.98541 6.48758C10.0167 6.63213 9.90378 6.76676 9.75641 6.76676H9.22923C9.11632 6.76676 9.02223 6.6914 8.98778 6.58167C8.84659 6.09876 8.50141 5.89176 7.90214 5.89176C7.24023 5.89176 6.89823 6.21167 6.89823 6.66022C6.89823 7.13394 7.09287 7.37222 8.10941 7.51994C9.48332 7.70813 10.1925 8.10022 10.1925 9.27067C10.1925 10.1584 9.53359 10.8769 8.50141 11.043V11.8335H8.49823Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.82936 13.8006C9.64755 13.8574 9.5 13.7505 9.5 13.5623V13.0981C9.5 12.96 9.58473 12.8315 9.71645 12.7842C11.6302 12.0815 13.0011 10.243 13.0011 8.08785C13.0011 5.93267 11.6302 4.09421 9.71645 3.39149C9.59727 3.34749 9.5 3.20331 9.5 3.07758V2.6134C9.5 2.42521 9.64755 2.31549 9.82936 2.37476C12.2482 3.14685 14.0019 5.41494 14.0019 8.09103C13.9989 10.7608 12.2482 13.0258 9.82936 13.8006Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_3348_40852'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
const USDCSVG = memo(USDC);
export default USDCSVG;
