import { FC } from 'react';
import './cancelOfferSuccess.scss';
import { useLocation } from 'react-router-dom';
import { SuccessTitle } from '../../SuccessTitle/SuccessTitle';
import { ICancelSuccessModalProps } from '../types';
import { OutsideLinkSVG } from '@/shared/assets/svg';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import { getRouteNFTCard } from '@/shared/const/router';
import { limitWordCharacters } from '@/shared/lib/limitWordCharacters/limitWordCharacters';
import { IconButton, Button } from '@/shared/ui/Buttons';
import { HStack } from '@/shared/ui/Stack';

const CancelOfferOrListingSuccess: FC<ICancelSuccessModalProps> = ({
  handleModalClose,
  cardData,
  typeModal,
  transaction,
}) => {
  const { pathname } = useLocation();
  const { tokenId } = cardData;
  const { transactionId, transactionUrl } = transaction;

  const openCard = () => {
    if (pathname.includes('/assets/solana/')) handleModalClose();
    else window.location.href = getRouteNFTCard(tokenId);
  };

  return (
    <div className='cancel-offer-success'>
      <div className='cancel-offer-success__close-btn'>
        <IconButton size='32' onClick={handleModalClose}>
          <CloseWhiteSVG />
        </IconButton>
      </div>
      <SuccessTitle
        font='H3'
        text={`Your ${
          typeModal === 'offer' ? 'offer' : 'listing'
        } has been canceled`}
      />
      <div className='cancel-offer-success__transaction'>
        <div className='cancel-offer-success__transaction__title'>
          Transaction
        </div>
        <div className='cancel-offer-success__transaction__description'>
          <a
            href={transactionUrl}
            target='_blank'
            rel='noreferrer'
            className='cancel-offer-success__transaction__description'
          >
            <HStack gap={0.25}>
              {limitWordCharacters(transactionId, 4, 'centerDots')}
              <OutsideLinkSVG />
            </HStack>
          </a>
        </div>
      </div>
      <div className='cancel-offer-success__btn'>
        <Button
          typeButton='secondary'
          text='go to card'
          style={{ width: '180px' }}
          onClick={openCard}
        />
      </div>
    </div>
  );
};

export default CancelOfferOrListingSuccess;
