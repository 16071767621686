import { FC, memo } from 'react';
import { ISvgProps } from './types';

const Quantity: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='12' cy='12' r='12' fill='inherit' />
    <path
      d='M9.39502 16.18C8.79102 16.18 8.27502 16.03 7.84702 15.73C7.41902 15.426 7.09102 15.016 6.86302 14.5C6.63502 13.984 6.52102 13.402 6.52102 12.754C6.52102 12.11 6.63302 11.53 6.85702 11.014C7.08502 10.498 7.41102 10.09 7.83502 9.79C8.25902 9.49 8.76702 9.34 9.35902 9.34C9.96702 9.34 10.479 9.488 10.895 9.784C11.311 10.08 11.625 10.486 11.837 11.002C12.053 11.514 12.161 12.098 12.161 12.754C12.161 13.398 12.055 13.98 11.843 14.5C11.631 15.016 11.319 15.426 10.907 15.73C10.495 16.03 9.99102 16.18 9.39502 16.18ZM9.48502 15.358C9.94502 15.358 10.325 15.246 10.625 15.022C10.925 14.798 11.149 14.49 11.297 14.098C11.445 13.702 11.519 13.254 11.519 12.754C11.519 12.246 11.445 11.798 11.297 11.41C11.149 11.018 10.925 10.712 10.625 10.492C10.329 10.272 9.95502 10.162 9.50302 10.162C9.03902 10.162 8.65502 10.276 8.35102 10.504C8.04702 10.732 7.82102 11.042 7.67302 11.434C7.52902 11.826 7.45702 12.266 7.45702 12.754C7.45702 13.246 7.53102 13.69 7.67902 14.086C7.83102 14.478 8.05702 14.788 8.35702 15.016C8.65702 15.244 9.03302 15.358 9.48502 15.358ZM11.423 18.88V14.398H11.519V9.52H12.311V18.88H11.423ZM17.5223 16C17.1583 16.076 16.7983 16.106 16.4423 16.09C16.0903 16.074 15.7743 15.998 15.4943 15.862C15.2183 15.726 15.0083 15.514 14.8643 15.226C14.7483 14.986 14.6843 14.744 14.6723 14.5C14.6643 14.252 14.6603 13.97 14.6603 13.654V7.72H15.5363V13.618C15.5363 13.89 15.5383 14.118 15.5423 14.302C15.5503 14.482 15.5923 14.642 15.6683 14.782C15.8123 15.05 16.0403 15.21 16.3523 15.262C16.6683 15.314 17.0583 15.302 17.5223 15.226V16ZM13.2743 10.276V9.52H17.5223V10.276H13.2743Z'
      fill='#121212'
    />
  </svg>
);
const QuantitySVG = memo(Quantity);
export default QuantitySVG;
