import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Dots: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.7148 6.21387C13.7148 7.04229 13.0433 7.71387 12.2148 7.71387C11.3864 7.71387 10.7148 7.04229 10.7148 6.21387C10.7148 5.38544 11.3864 4.71387 12.2148 4.71387C13.0433 4.71387 13.7148 5.38544 13.7148 6.21387ZM13.7148 12.2139C13.7148 13.0423 13.0433 13.7139 12.2148 13.7139C11.3864 13.7139 10.7148 13.0423 10.7148 12.2139C10.7148 11.3854 11.3864 10.7139 12.2148 10.7139C13.0433 10.7139 13.7148 11.3854 13.7148 12.2139ZM12.2148 19.7139C13.0433 19.7139 13.7148 19.0423 13.7148 18.2139C13.7148 17.3854 13.0433 16.7139 12.2148 16.7139C11.3864 16.7139 10.7148 17.3854 10.7148 18.2139C10.7148 19.0423 11.3864 19.7139 12.2148 19.7139Z'
      fill='#939393'
    />
  </svg>
);
const DotsSVG = memo(Dots);
export default DotsSVG;
