import './listCardDefault.scss';
import { Buffer } from 'buffer';
import { useWallet } from '@solana/wallet-adapter-react';
import { Transaction } from '@solana/web3.js';
import axios from 'axios';
import {
  CSSProperties,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBlock } from '../../ErrorBlock/ErrorBlock';
import { IErrorNames } from '../../ErrorBlock/types';
import { SmallCardInfo } from '../../SmallCardInfo/SmallCardInfo';
import { IListCardDefaultModalProps } from '../types';
import { CurrencyDropdown } from '@/features/CurrencyDropdown';
import { getListings } from '@/pages/Marketplace';
import {
  getListingFeesRequest,
  createUpdateListingTxRequest,
  createListingTxRequest,
  sendListTxsRequest,
  sendUpdateListingTxsRequest,
} from '@/shared/api/services/listings';
import { InfoCircleSVG } from '@/shared/assets/svg/AlertsIcons';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import { AuthProviderContext } from '@/shared/lib/context/AuthProviderContext';
import { convertNumberInK } from '@/shared/lib/helpers/convertNumberInK';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useDebounceValue } from '@/shared/lib/hooks/useDebounceValue';
import { signTransactionsMethod } from '@/shared/lib/signTransactions/signTransactionsMethod';
import { Alert } from '@/shared/ui/Alert/Alert';
import { IconButton, Button } from '@/shared/ui/Buttons';
import { Input } from '@/shared/ui/Inputs';
import { Spinner } from '@/shared/ui/Loaders';

const ListCardDefault: FC<IListCardDefaultModalProps> = ({
  getDataCard,
  getNFTCardActivities = () => {},
  cardData,
  handleModalClose,
  transactionStatus,
  changeTransactionStatus,
  subjectId,
  typeModal,
  setTransaction,
  currentUserWallet,
  modalFromPage,
  initialPrice,
  listingPrice,
  currency,
  setCurrency,
  setisLoading = () => {},
  setListingPrice,
  setError,
}) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { name, tokenId, frontImage, cardId, cardAddress, listing } = cardData;

  const [finalReceive, setFinalReceive] = useState<number>(0);
  const [viewFees, setViewFees] = useState(true);
  const [errors, setErrors] = useState<IErrorNames[]>([]);
  const debouncedListingPrice = useDebounceValue(listingPrice, 500);
  const { signTransaction } = useWallet();
  const { signTrans: signTransactionWeb3 } = useContext(AuthProviderContext);
  const isTransactionPending = transactionStatus === 'pending';
  const isListingPriceCorrect = !!listingPrice && Number(listingPrice) !== 0;
  const isOwnerError = errors.includes('owner-error');
  const isMEError = errors.includes('magic-eden-error');
  const isPriceWrong =
    (typeModal === 'update-listing' &&
      Number(listingPrice) === Number(initialPrice)) ||
    !listingPrice ||
    Number(listingPrice) === 0;

  const pollForUpdates = (card: string, interval = 5000) =>
    new Promise<void>(resolve => {
      const intervalId = setInterval(() => {
        if (modalFromPage === 'NFT-Page') {
          getDataCard({ cardAddress: card || '', withLoading: true });
          getNFTCardActivities();
        } else if (currentUserWallet)
          dispatch(
            getListings({
              params: { wallet: currentUserWallet },
            }),
          );
      }, interval);

      setTimeout(() => {
        clearInterval(intervalId);
        console.log('resolved---->');
        setisLoading(false);
        resolve();
      }, interval);
    });

  const createListingTransaction = async () => {
    changeTransactionStatus('pending');

    try {
      // const ownerCheckResult = await checkOwner(cardAddress);
      // if (
      //   ownerCheckResult === 'owner-error' ||
      //   ownerCheckResult === 'magic-eden-error'
      // ) {
      //   changeTransactionStatus(ownerCheckResult);
      //   setErrors(prev => [
      //     ...prev.filter(() => !prev.includes(ownerCheckResult)),
      //     ownerCheckResult,
      //   ]);
      //   getDataCard({ cardAddress: cardAddress || '', withLoading: false });
      //   throw new Error(ownerCheckResult);
      // }

      const createTx = async () => {
        if (typeModal === 'listing') {
          const createdTx = (await createListingTxRequest({
            cardId,
            currency,
            nftAddress: tokenId || '',
            price: Number(listingPrice),
          })) as { data: string };

          return createdTx.data;
        }

        if (typeModal === 'update-listing') {
          const createdTx = (await createUpdateListingTxRequest({
            coin: currency,
            newPrice: Number(listingPrice),
            seller: listing?.sellerWallet || '',
            tokenMint: tokenId || '',
          })) as { data: string };

          return createdTx.data;
        }
      };

      const ut = createTx() as Promise<string>;

      const transaction = Transaction.from(
        Buffer.from(await ut, 'base64'),
      ) as Transaction;

      const transactionSigned = await signTransactionsMethod(
        transaction,
        signTransaction,
        signTransactionWeb3,
      );
      if (transactionSigned) {
        const serializedTransactions = transactionSigned
          .serialize()
          .toString('base64');

        if (typeModal === 'listing') {
          const TXsended = (await sendListTxsRequest({
            tx: serializedTransactions,
          })) as {
            data: {
              txId: string;
              txUrl: string;
            };
          };
          setTransaction({
            currency,
            price: listingPrice,
            transactionId: TXsended.data.txId,
            transactionUrl: TXsended.data.txUrl,
          });
        }

        if (typeModal === 'update-listing') {
          const TXSended = (await sendUpdateListingTxsRequest({
            tx: serializedTransactions,
          })) as {
            data: {
              txId: string;
              txUrl: string;
            };
          };
          setTransaction({
            currency,
            price: listingPrice,
            transactionId: TXSended.data.txId,
            transactionUrl: TXSended.data.txUrl,
          });
        }

        if (transactionSigned.signatures.length > 0) {
          changeTransactionStatus('resolved');
          setisLoading(true);
          await pollForUpdates(cardAddress || '');
          setViewFees(false);
        }
      }
    } catch (err) {
      setisLoading(true);
      await pollForUpdates(cardAddress || '');
      setError?.('error');
      changeTransactionStatus('adapter-error');
      if (axios.isAxiosError(err)) {
        changeTransactionStatus('axios-error');
        const customError = err.response as IResponse<IErrorMessage>;
        if (customError.status >= 500)
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('unknown-error')),
            'unknown-error',
          ]);

        if (customError.data?.message.includes('not enough'))
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('balance-error')),
            'balance-error',
          ]);

        if (customError.data?.message.includes('Card not found'))
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('not-found')),
            'not-found',
          ]);
      }
    }
  };

  const handleOnErrorModalClose = () => {
    handleModalClose();
    if (typeModal === 'update-listing')
      if (pathname.includes('/assets/solana/'))
        getDataCard({ cardAddress: tokenId || '', withLoading: false });
      else if (currentUserWallet)
        dispatch(
          getListings({
            params: { wallet: currentUserWallet },
          }),
        );
  };

  const handleCurrencyChange = (value: ICurrency) => {
    setCurrency(value);
  };

  const getListingFees = useCallback(async () => {
    if (listingPrice.length) {
      const fees = (await getListingFeesRequest({
        currency,
        nftAddress: tokenId,
        price: Number(listingPrice),
      })) as { data: { fee: number; value: number } };
      const finalReceiveTemp = Number(listingPrice) - Number(fees.data.value);
      setFinalReceive(finalReceiveTemp);
      setViewFees(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedListingPrice]);

  useEffect(() => {
    getListingFees();
  }, [getListingFees]);

  useEffect(() => {
    setViewFees(false);
  }, [listingPrice]);

  const listCardInputStyles: CSSProperties = {
    alignItems: 'center',
    background: '#121212',
    borderRadius: '0.5rem',
    color: '#E8EAED',
    display: 'flex',
    gap: '0.25rem',
    padding: '0.195rem 0.275rem 0.275rem 0.195rem',
    position: 'absolute',
    right: ' 1rem',
    top: '0.7rem',
  };

  return (
    <div
      className='list-card-modal-default'
      style={{ minHeight: isOwnerError || isMEError ? 'unset' : '32.375rem' }}
    >
      <div className='list-card-modal-default__info'>
        {!isTransactionPending && (
          <div className='list-card-modal-default__info__close-btn'>
            <IconButton size='32' onClick={handleModalClose}>
              <CloseWhiteSVG />
            </IconButton>
          </div>
        )}
        <div className='list-card-modal-default__info__title'>
          {typeModal === 'listing'
            ? 'List pNFT for sale'
            : 'Update listing price  '}
        </div>
        <div className='list-card-modal-default__info__card-preview'>
          <SmallCardInfo
            name={name}
            tokenId={tokenId}
            image={frontImage}
            isTitleShort={false}
            handleModalClose={handleModalClose}
          />
        </div>
        {transactionStatus === 'pending' ? (
          <div className='list-card-modal-default__info__confirming'>
            <Spinner size={40} />
            <p className='list-card-modal-default__info__confirming__description'>
              Listing your card...
            </p>
          </div>
        ) : (
          !isOwnerError &&
          !isMEError && (
            <div className='list-card-modal-default__input-block'>
              <Input
                isValidate
                type='number'
                value={listingPrice}
                max={999999999}
                label='Price'
                placeholder='Enter price'
                isError={!!errors.length}
                onChange={setListingPrice}
              >
                <div>
                  <CurrencyDropdown
                    isSelect
                    currency={currency}
                    handleCurrencyChange={handleCurrencyChange}
                    styles={listCardInputStyles}
                  />
                </div>
              </Input>
            </div>
          )
        )}
        {!!(isOwnerError || isMEError) && (
          <div className='list-card-modal-default__owner-errors-block'>
            <Alert
              size='small'
              status='error'
              text={
                isOwnerError
                  ? 'You are no longer the owner and cannot perform this action !'
                  : 'This card is listed on another platform, cancel that listing to complete this action!'
              }
            />
          </div>
        )}
      </div>
      {!isOwnerError && !isMEError && (
        <div className='list-card-modal-default__alerts'>
          {!isTransactionPending &&
            !!finalReceive &&
            !!viewFees &&
            !!isListingPriceCorrect && (
              <div className='list-card-modal-default__alerts__advise'>
                <div className='list-card-modal-default__alerts__advise__icon'>
                  <InfoCircleSVG fill='#828585' />
                </div>
                <div className='list-card-modal-default__alerts__advise__text'>
                  You will receive
                  <span className='list-card-modal-default__alerts__advise__text__white'>
                    &ensp;{' '}
                    {currency === 'USD'
                      ? `$ ${convertNumberInK(
                          finalReceive,
                          'notRound',
                          currency,
                        )}`
                      : convertNumberInK(
                          finalReceive,
                          'notRound',
                          currency,
                        )}{' '}
                    &ensp;
                  </span>
                  after platform fees of 2% are subtracted from the sale price
                </div>
              </div>
            )}
          {!!errors.length && (
            <div className='list-card-modal-default__alerts__error-block'>
              {errors.map((error: IErrorNames, index: number) => (
                <ErrorBlock key={`${index}-${error}`} errorName={error} />
              ))}
            </div>
          )}
        </div>
      )}
      <div className='list-card-modal-default__button'>
        {isOwnerError || isMEError ? (
          <Button
            fullWidth
            typeButton='secondary'
            text='Close'
            onClick={handleOnErrorModalClose}
          />
        ) : (
          <Button
            fullWidth
            typeButton='primary'
            disabled={transactionStatus === 'pending' || isPriceWrong}
            text={
              typeModal === 'listing' ? 'Complete listing' : 'Update listing'
            }
            onClick={createListingTransaction}
          />
        )}
      </div>
    </div>
  );
};

export default ListCardDefault;
