import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const AllCardsBurnIconSVG: FC<ISvgProps> = ({
  width = '18',
  height = '21',
  fill,
  style,
  type,
}) =>
  type === 'alternative' ? (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2772_21329)'>
        <circle cx='14' cy='14' r='14' fill='#D43B3C' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.5585 6.67769C15.4411 6.56076 15.2743 6.50804 15.1111 6.53629C14.9479 6.56455 14.8085 6.67026 14.7373 6.81984L12.7701 10.9521L10.7084 9.58101C10.5956 9.50601 10.4577 9.47891 10.3249 9.50568C10.1921 9.53245 10.0754 9.6109 10.0005 9.72375C9.00229 11.2276 8.12891 13.0293 8.12891 14.7657C8.12891 16.3224 8.74733 17.8154 9.84813 18.9162C10.9489 20.017 12.4419 20.6355 13.9987 20.6355C15.5555 20.6355 17.0485 20.017 18.1493 18.9162C19.2501 17.8154 19.8685 16.3224 19.8685 14.7657C19.8685 11.2143 17.4131 8.52598 15.5585 6.67769ZM13.4387 11.9226L15.3461 7.91595C17.0757 9.70448 18.8477 11.9582 18.8477 14.7657C18.8477 16.0517 18.3368 17.285 17.4274 18.1944C16.5181 19.1038 15.2847 19.6146 13.9987 19.6146C12.7127 19.6146 11.4793 19.1038 10.57 18.1944C9.66061 17.285 9.14974 16.0517 9.14974 14.7657C9.14974 13.4768 9.74783 12.0541 10.5751 10.7183L12.6952 12.1282C12.8185 12.2101 12.9713 12.2346 13.1139 12.1951C13.2566 12.1557 13.3751 12.0562 13.4387 11.9226ZM17.7823 15.3558C17.8263 15.0773 17.6363 14.8159 17.3579 14.7719C17.0795 14.7278 16.8181 14.9178 16.774 15.1962C16.6809 15.7846 16.4039 16.3283 15.9827 16.7495C15.5615 17.1708 15.0177 17.4478 14.4294 17.5409C14.1509 17.5849 13.9609 17.8464 14.005 18.1248C14.049 18.4032 14.3105 18.5932 14.5889 18.5492C15.3902 18.4224 16.1308 18.0451 16.7045 17.4714C17.2782 16.8977 17.6555 16.1571 17.7823 15.3558Z'
          fill='#121212'
        />
      </g>
      <defs>
        <clipPath id='clip0_2772_21329'>
          <rect width='28' height='28' fill='white' />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill || 'inherit'}
      style={style}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width={width} height={width} fill='none' />
      <path
        d='M12.3188 4.16255C11.5063 2.86255 10.45 1.7438 9.53125 0.825046C9.47459 0.768885 9.4053 0.727099 9.32919 0.703179C9.25308 0.679259 9.17235 0.673898 9.09375 0.687546C9.01458 0.700015 8.93964 0.731568 8.87539 0.77948C8.81115 0.827392 8.75953 0.890222 8.725 0.962546L6.79375 5.01255L4.78125 3.6688C4.67086 3.59573 4.53625 3.5688 4.40625 3.5938C4.34176 3.60652 4.28041 3.63184 4.22572 3.66831C4.17102 3.70477 4.12405 3.75166 4.0875 3.8063C2.86875 5.6438 2.25 7.3063 2.25 8.75005C2.25 10.275 2.8558 11.7376 3.93414 12.8159C5.01247 13.8942 6.47501 14.5 8 14.5C9.52499 14.5 10.9875 13.8942 12.0659 12.8159C13.1442 11.7376 13.75 10.275 13.75 8.75005C13.75 7.1938 13.2812 5.6938 12.3188 4.16255ZM11.7063 9.32505C11.588 10.1133 11.2201 10.8429 10.6564 11.4065C10.0928 11.9701 9.36326 12.3381 8.575 12.4563H8.5C8.37455 12.4554 8.25404 12.4073 8.16237 12.3217C8.0707 12.236 8.01458 12.119 8.00514 11.9939C7.9957 11.8688 8.03363 11.7448 8.1114 11.6463C8.18918 11.5479 8.30111 11.4823 8.425 11.4625C8.99961 11.3691 9.53029 11.0974 9.94193 10.6857C10.3536 10.2741 10.6253 9.7434 10.7188 9.1688C10.7257 9.10145 10.7462 9.03621 10.7791 8.97703C10.8119 8.91785 10.8565 8.86597 10.91 8.82452C10.9636 8.78307 11.0249 8.75293 11.0905 8.73591C11.156 8.7189 11.2243 8.71537 11.2912 8.72553C11.3582 8.7357 11.4223 8.75935 11.4798 8.79506C11.5374 8.83077 11.587 8.87778 11.6258 8.93325C11.6646 8.98872 11.6918 9.0515 11.7056 9.11777C11.7194 9.18404 11.7196 9.25244 11.7063 9.3188V9.32505Z'
        fill={fill || 'inherit'}
      />
    </svg>
  );
const AllCardsBurnIcon = memo(AllCardsBurnIconSVG);
export default AllCardsBurnIcon;
