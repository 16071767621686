import { FC, memo } from 'react';
import { ISvgProps } from './types';

const Minted: FC<ISvgProps> = ({ width = '28', height = '28' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.375 14C4.375 8.68426 8.68426 4.375 14 4.375C19.3157 4.375 23.625 8.68426 23.625 14C23.625 19.3157 19.3157 23.625 14 23.625C8.68426 23.625 4.375 19.3157 4.375 14ZM14 2.625C7.71776 2.625 2.625 7.71776 2.625 14C2.625 20.2822 7.71776 25.375 14 25.375C20.2822 25.375 25.375 20.2822 25.375 14C25.375 7.71776 20.2822 2.625 14 2.625ZM19.4165 12.0081C19.7661 11.6745 19.7792 11.1207 19.4456 10.771C19.112 10.4214 18.5582 10.4083 18.2085 10.7419L12.3927 16.2902L9.79203 13.8049C9.44266 13.471 8.88878 13.4836 8.55491 13.833C8.22104 14.1823 8.2336 14.7362 8.58298 15.0701L11.7877 18.1326C12.1257 18.4556 12.6579 18.4558 12.9962 18.1331L19.4165 12.0081Z'
      fill='#FF7C01'
    />
  </svg>
);
const MintedSVG = memo(Minted);
export default MintedSVG;
