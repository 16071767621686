import { FC, memo } from 'react';
import { ISvgProps } from '../../types';

const Discord: FC<ISvgProps> = ({ width = '28', height = '28' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 28 28'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 2.625C20.2822 2.625 25.375 7.71776 25.375 14C25.375 20.2822 20.2822 25.375 14 25.375C7.71776 25.375 2.625 20.2822 2.625 14C2.625 7.71776 7.71776 2.625 14 2.625ZM15.7246 9.625C16.6111 9.77853 17.459 10.0482 18.2519 10.4192C19.6384 12.4897 20.3273 14.8249 20.0729 17.5184C19.0121 18.3116 17.9831 18.7928 16.9714 19.1077C16.7209 18.7643 16.4993 18.3982 16.3076 18.0144C16.6727 17.8756 17.0234 17.7044 17.3549 17.5046C17.2682 17.4397 17.1824 17.3718 17.0986 17.3019C15.107 18.2447 12.917 18.2447 10.9014 17.3019C10.8185 17.3718 10.7327 17.4397 10.6451 17.5046C10.9755 17.7034 11.3253 17.8746 11.6905 18.0134C11.4987 18.3982 11.2762 18.7633 11.0266 19.1067C10.0159 18.7918 8.98784 18.3106 7.92702 17.5184C7.71023 15.195 8.14381 12.8381 9.74322 10.4211C10.5362 10.0492 11.385 9.77853 12.2724 9.625C12.3823 9.82182 12.5123 10.0866 12.6 10.2971C13.525 10.1554 14.4586 10.1554 15.4009 10.2971C15.4886 10.0866 15.6158 9.82182 15.7246 9.625ZM10.8638 14.8367C10.8638 15.5246 11.36 16.0895 11.9651 16.0895C12.5807 16.0895 13.0673 15.5246 13.0663 14.8367C13.0769 14.1479 12.5807 13.583 11.9651 13.583C11.3494 13.583 10.8638 14.1489 10.8638 14.8367ZM14.9336 14.8367C14.9336 15.5246 15.4298 16.0895 16.0349 16.0895C16.6506 16.0895 17.1362 15.5246 17.1362 14.8367C17.1468 14.1479 16.6506 13.583 16.0349 13.583C15.4192 13.583 14.9336 14.1489 14.9336 14.8367Z'
      fill='#939393'
    />
  </svg>
);
const DiscordSVG = memo(Discord);
export default DiscordSVG;
