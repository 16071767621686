import { FC } from 'react';
import './acceptOfferSuccess.scss';
import { useLocation } from 'react-router-dom';
import { SmallCardInfo } from '../../SmallCardInfo/SmallCardInfo';
import { SuccessTitle } from '../../SuccessTitle/SuccessTitle';
import { IAcceptOfferSuccessModalProps } from '../types';
import { OutsideLinkSVG } from '@/shared/assets/svg';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import { getRouteNFTCard } from '@/shared/const/router';
import { limitWordCharacters } from '@/shared/lib/limitWordCharacters/limitWordCharacters';
import { IconButton, Button } from '@/shared/ui/Buttons';
import { HStack } from '@/shared/ui/Stack';

const AcceptOfferSuccess: FC<IAcceptOfferSuccessModalProps> = ({
  cardData,
  transaction,
  handleModalClose,
  offer,
}) => {
  const { pathname } = useLocation();

  const { name, tokenId, frontImage } = cardData;
  const { from } = offer;
  const { transactionId, transactionUrl } = transaction;

  const openCard = () => {
    if (pathname.includes('/assets/solana/')) handleModalClose();
    else window.location.href = getRouteNFTCard(tokenId);
  };

  const redirectToProfile = (wallet: string) => {
    window.open(`${window.location.origin}/account/${wallet}`, '_blank');
  };

  return (
    <div className='accept-offer-modal-success'>
      <div className='accept-offer-modal-success__close-btn'>
        <IconButton size='32' onClick={handleModalClose}>
          <CloseWhiteSVG />
        </IconButton>
      </div>
      <SuccessTitle text='Sale completed successfully!' font='H4-CL' />
      <div className='accept-offer-modal-success__sold-info'>
        <span className='accept-offer-modal-success__sold-info__text'>
          The item was transferred to
        </span>
        <span
          className='accept-offer-modal-success__sold-info__value'
          onClick={() => from && redirectToProfile(from)}
        >
          {!!from && limitWordCharacters(from, 4, 'centerDots')}
        </span>
      </div>
      <SmallCardInfo
        name={name}
        tokenId={tokenId}
        image={frontImage}
        isTitleShort={false}
        handleModalClose={handleModalClose}
      />
      <div className='accept-offer-modal-success__trnsctn-info'>
        <span className='accept-offer-modal-success__trnsctn-info__text'>
          Transaction
        </span>
        <span className='accept-offer-modal-success__trnsctn-info__value'>
          <a
            href={transactionUrl}
            target='_blank'
            rel='noreferrer'
            className='accept-offer-modal-success__trnsctn-info__link'
          >
            <HStack gap={0.25}>
              {limitWordCharacters(transactionId, 4, 'centerDots')}
              <OutsideLinkSVG />
            </HStack>
          </a>
        </span>
      </div>
      <div className='accept-offer-modal-success__button'>
        <Button
          fullWidth
          typeButton='secondary'
          text='go to card'
          onClick={openCard}
        />
      </div>
    </div>
  );
};

export default AcceptOfferSuccess;
