import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const HoverCardPanelBurn: FC<ISvgProps> = ({
  width = '18',
  height = '21',
  fill,
  style,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill={fill || 'inherit'}
    style={style}
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width={width} height={width} fill='none' />
    <path
      d='M15.4781 5.24406C14.2594 3.29406 12.675 1.61594 11.2969 0.237813C11.2119 0.153572 11.108 0.0908931 10.9938 0.0550126C10.8796 0.0191321 10.7585 0.0110916 10.6406 0.0315626C10.5219 0.0502661 10.4095 0.0975956 10.3131 0.169464C10.2167 0.241331 10.1393 0.335577 10.0875 0.444063L7.19062 6.51906L4.17188 4.50344C4.0063 4.39384 3.80437 4.35345 3.60938 4.39094C3.51264 4.41003 3.42062 4.44801 3.33857 4.50271C3.25653 4.5574 3.18608 4.62774 3.13125 4.70969C1.30313 7.46594 0.375 9.95969 0.375 12.1253C0.375 14.4128 1.2837 16.6066 2.9012 18.2241C4.51871 19.8416 6.71251 20.7503 9 20.7503C11.2875 20.7503 13.4813 19.8416 15.0988 18.2241C16.7163 16.6066 17.625 14.4128 17.625 12.1253C17.625 9.79094 16.9219 7.54094 15.4781 5.24406ZM14.5594 12.9878C14.382 14.1702 13.8301 15.2645 12.9846 16.11C12.1392 16.9554 11.0449 17.5073 9.8625 17.6847H9.75C9.56183 17.6833 9.38106 17.6112 9.24356 17.4827C9.10606 17.3543 9.02187 17.1788 9.00771 16.9912C8.99355 16.8035 9.05044 16.6174 9.1671 16.4697C9.28376 16.3221 9.45167 16.2237 9.6375 16.1941C10.4994 16.0539 11.2954 15.6463 11.9129 15.0288C12.5304 14.4114 12.938 13.6154 13.0781 12.7534C13.0885 12.6524 13.1193 12.5546 13.1686 12.4658C13.2179 12.377 13.2847 12.2992 13.365 12.237C13.4453 12.1749 13.5374 12.1296 13.6357 12.1041C13.734 12.0786 13.8364 12.0733 13.9368 12.0885C14.0372 12.1038 14.1335 12.1393 14.2198 12.1928C14.306 12.2464 14.3805 12.3169 14.4388 12.4001C14.497 12.4833 14.5377 12.5775 14.5584 12.6769C14.5791 12.7763 14.5795 12.8789 14.5594 12.9784V12.9878Z'
      fill={fill || 'inherit'}
    />
  </svg>
);

const HoverCardPanelBurnSVG = memo(HoverCardPanelBurn);

export default HoverCardPanelBurnSVG;
