import { FC, memo } from 'react';
import { ISvgProps } from './types';

const SmileyXEyesSVG: FC<ISvgProps> = ({ width = '48', height = '48' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.5 24C7.5 14.8873 14.8873 7.5 24 7.5C33.1127 7.5 40.5 14.8873 40.5 24C40.5 33.1127 33.1127 40.5 24 40.5C14.8873 40.5 7.5 33.1127 7.5 24ZM24 4.5C13.2304 4.5 4.5 13.2304 4.5 24C4.5 34.7696 13.2304 43.5 24 43.5C34.7696 43.5 43.5 34.7696 43.5 24C43.5 13.2304 34.7696 4.5 24 4.5ZM35.5607 19.0607C36.1464 18.4749 36.1464 17.5251 35.5607 16.9393C34.9749 16.3536 34.0251 16.3536 33.4393 16.9393L31.5 18.8787L29.5607 16.9393C28.9749 16.3536 28.0251 16.3536 27.4393 16.9393C26.8536 17.5251 26.8536 18.4749 27.4393 19.0607L29.3787 21L27.4393 22.9393C26.8536 23.5251 26.8536 24.4749 27.4393 25.0607C28.0251 25.6464 28.9749 25.6464 29.5607 25.0607L31.5 23.1213L33.4393 25.0607C34.0251 25.6464 34.9749 25.6464 35.5607 25.0607C36.1464 24.4749 36.1464 23.5251 35.5607 22.9393L33.6213 21L35.5607 19.0607ZM20.5607 19.0607C21.1464 18.4749 21.1464 17.5251 20.5607 16.9393C19.9749 16.3536 19.0251 16.3536 18.4393 16.9393L16.5 18.8787L14.5607 16.9393C13.9749 16.3536 13.0251 16.3536 12.4393 16.9393C11.8536 17.5251 11.8536 18.4749 12.4393 19.0607L14.3787 21L12.4393 22.9393C11.8536 23.5251 11.8536 24.4749 12.4393 25.0607C13.0251 25.6464 13.9749 25.6464 14.5607 25.0607L16.5 23.1213L18.4393 25.0607C19.0251 25.6464 19.9749 25.6464 20.5607 25.0607C21.1464 24.4749 21.1464 23.5251 20.5607 22.9393L18.6213 21L20.5607 19.0607ZM26.25 33.75C26.25 34.9926 25.2426 36 24 36C22.7574 36 21.75 34.9926 21.75 33.75C21.75 32.5074 22.7574 31.5 24 31.5C25.2426 31.5 26.25 32.5074 26.25 33.75Z'
      fill='#828585'
    />
  </svg>
);
const SmileyXEyes = memo(SmileyXEyesSVG);
export default SmileyXEyes;
