import { FC, memo } from 'react';
import { ISvgProps } from '../../types';

const Twitter: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.3558 8.35492C18.3623 8.50313 18.3623 8.6446 18.3623 8.79281C18.3688 13.2863 15.0674 18.4737 9.03006 18.4737C7.24939 18.4737 5.50122 17.9415 4 16.9444C4.25995 16.9781 4.5199 16.9916 4.77985 16.9916C6.25508 16.9916 7.69131 16.4796 8.85459 15.5297C7.45085 15.5027 6.21608 14.5528 5.78716 13.165C6.28107 13.2661 6.78798 13.2459 7.26889 13.1044C5.74167 12.7945 4.64338 11.4 4.63688 9.77639C4.63688 9.76292 4.63688 9.74945 4.63688 9.73597C5.0918 9.99871 5.6052 10.1469 6.1251 10.1604C4.68887 9.16334 4.24045 7.17596 5.11129 5.61975C6.78148 7.7486 9.23802 9.03534 11.8765 9.17681C11.6101 7.99786 11.974 6.75828 12.8253 5.92291C14.1446 4.63617 16.2242 4.70353 17.472 6.07112C18.2063 5.92291 18.9147 5.63996 19.5581 5.24248C19.3111 6.0307 18.7977 6.69765 18.1154 7.12207C18.7652 7.04123 19.4021 6.85933 20 6.58986C19.5581 7.27702 18.9992 7.86986 18.3558 8.35492Z'
      fill='#DFE0E1'
    />
  </svg>
);
const TwitterSVG = memo(Twitter);
export default TwitterSVG;
