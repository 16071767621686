import { FC, memo } from 'react';
import { ISvgProps } from './types';

const HashTag: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.7728 8.50017H16.5632L17.2927 5.8815C17.3967 5.50839 17.1669 5.12413 16.7786 5.02403C16.3903 4.92392 15.9909 5.14579 15.8877 5.5189L15.0579 8.50017H10.8927L11.6222 5.8815C11.7262 5.50839 11.4964 5.12413 11.1081 5.02403C10.7196 4.92469 10.3212 5.14579 10.2178 5.5189L9.38726 8.50017H6.68182C6.28032 8.50017 5.95459 8.81304 5.95459 9.20012C5.95459 9.58733 6.28032 9.90019 6.68182 9.90019H8.99668L7.82654 14.1001H5.22723C4.82586 14.1001 4.5 14.413 4.5 14.8001C4.5 15.1871 4.82586 15.5 5.22723 15.5H7.43675L6.70726 18.1187C6.60326 18.4918 6.8331 18.8761 7.22142 18.9762C7.28401 18.9923 7.34726 19 7.40905 19C7.72986 19 8.02442 18.7942 8.11098 18.4813L8.94222 15.5H13.1073L12.3778 18.1187C12.2739 18.4918 12.5036 18.8761 12.8921 18.9762C12.9545 18.9923 13.0178 19 13.0804 19C13.4012 19 13.6956 18.7942 13.7822 18.4813L14.6127 15.5H17.3182C17.7197 15.5 18.0455 15.1871 18.0455 14.8001C18.0455 14.413 17.7197 14.1001 17.3182 14.1001H15.0033L16.1735 9.90019H18.7728C19.1743 9.90019 19.5 9.58733 19.5 9.20012C19.5 8.81304 19.1743 8.50017 18.7728 8.50017ZM13.4971 14.1001H9.332L10.5021 9.90019H14.6673L13.4971 14.1001Z'
      fill='#828585'
    />
  </svg>
);
const HashTagSVG = memo(HashTag);
export default HashTagSVG;
