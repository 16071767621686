import { CSSProperties, FC, memo } from 'react';
import cls from './currencyDropdown.module.scss';
import { UsdcSVG, UsdtSVG } from '@/shared/assets/svg';
import SolanaIcon from '@/shared/assets/svg/HeaderPopover/SolonsLogo.svg';
import { Select } from '@/shared/ui/Popovers';
import { HStack } from '@/shared/ui/Stack';

export interface ICurrencyDropdownProps {
  className?: string;
  isSelect?: boolean;
  currency: ICurrency;
  handleCurrencyChange: (event: any) => void;
  styles?: CSSProperties;
}

const CurrencyDropdown: FC<ICurrencyDropdownProps> = memo(props => {
  const {
    className,
    currency,
    handleCurrencyChange,
    isSelect = false,
    styles,
  } = props;

  if (isSelect)
    return (
      <div style={styles || undefined}>
        <Select
          value={currency}
          defaultValue={'USDC'}
          items={[
            {
              content: (
                <HStack gap='0.25'>
                  <UsdcSVG
                    fill='#3875C9'
                    secondFill='#FFFFFF'
                    width='17'
                    height='17'
                  />
                  <span>USDC</span>
                </HStack>
              ),
              value: 'USDC',
            },
            // {
            //   content: (
            //     <HStack gap='0.25'>
            //       <UsdtSVG />
            //       USDT
            //     </HStack>
            //   ),
            //   value: 'USDT',
            // },
            {
              content: (
                <HStack gap='0.25'>
                  <SolanaIcon
                    style={{ background: '#000000', borderRadius: '50%' }}
                  />
                  <span>SOL</span>
                </HStack>
              ),
              value: 'SOL',
            },
          ]}
          triggerContent={
            <HStack gap='0.5' justify='between' className={cls.trigger}>
              {currency === 'USDC' ? (
                <UsdcSVG fill='#3875C9' secondFill='#FFFFFF' />
              ) : (
                <SolanaIcon
                  style={{ background: '#000000', borderRadius: '50%' }}
                />
              )}
              {currency}
            </HStack>
          }
          onChange={handleCurrencyChange}
        />
      </div>
    );

  let currencyDisplay;
  if (currency === 'USDC')
    currencyDisplay = (
      <>
        <UsdcSVG fill='#3875C9' secondFill='#FFFFFF' />
        USDC
      </>
    );
  else if (currency === 'SOL')
    currencyDisplay = (
      <>
        <SolanaIcon style={{ background: '#000000', borderRadius: '50%' }} />
        SOL
      </>
    );
  else
    currencyDisplay = (
      <>
        <UsdtSVG />
        USDT
      </>
    );

  return <div style={styles || undefined}>{currencyDisplay}</div>;
});

export default CurrencyDropdown;
