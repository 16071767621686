import { FC, memo, useCallback, useEffect, useState } from 'react';
import './cancelOffer.scss';
import CancelOfferOrListingDefault from './CancelOfferOrListingDefault/CancelOfferOrListingDefault';
import CancelOfferOrListingSuccess from './CancelOfferOrListingSuccess/CancelOfferOrListingSuccess';
import { ICancelOfferModalProps } from './types';
import { Modal } from '@/shared/ui/Modal';

export const CancelOfferOrListing: FC<ICancelOfferModalProps> = memo(
  ({
    getDataCard,
    open,
    setOpen,
    subjectId,
    getOffers,
    cardData,
    setisLoading,
    typeModal,
    currentUserWallet,
    getNFTCardActivities,
  }) => {
    const [transactionStatus, setTransactionStatus] = useState('none');
    const [error, setError] = useState<undefined | string>(undefined);
    const [transaction, setTransaction] = useState({
      transactionId: '',
      transactionUrl: '',
    });

    const handleModalClose = useCallback(() => {
      if (transactionStatus === 'pending') return;
      setOpen('cancelOffer', { isOpen: false, typeModal: '' });
      setTransactionStatus('none');
    }, [setOpen, transactionStatus]);

    const changeTransactionStatus = (status: string) => {
      setTransactionStatus(status);
    };

    useEffect(() => {
      if (error) handleModalClose();
    }, [error, handleModalClose]);
    return (
      <Modal
        isOpen={open}
        className='cancel-offer-modal'
        onClose={handleModalClose}
      >
        {transactionStatus === 'resolved' ? (
          <CancelOfferOrListingSuccess
            getDataCard={getDataCard}
            handleModalClose={handleModalClose}
            cardData={cardData}
            typeModal={typeModal}
            transaction={transaction}
          />
        ) : (
          <CancelOfferOrListingDefault
            getDataCard={getDataCard}
            handleModalClose={handleModalClose}
            subjectId={subjectId}
            cardData={cardData}
            setError={setError}
            setisLoading={setisLoading}
            transactionStatus={transactionStatus}
            setTransactionStatus={changeTransactionStatus}
            setTransaction={setTransaction}
            getOffers={getOffers}
            typeModal={typeModal}
            currentUserWallet={currentUserWallet}
            getNFTCardActivities={getNFTCardActivities}
          />
        )}
      </Modal>
    );
  },
);
