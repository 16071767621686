import { FC, memo } from 'react';
import { ISvgProps } from './types';

const Usdt: FC<ISvgProps> = ({ width = '20', height = '20' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_3326_15329)'>
      <path
        d='M19.6986 12.419C18.363 17.7762 12.937 21.0365 7.5792 19.7006C2.22361 18.365 -1.03667 12.9387 0.299485 7.58197C1.63447 2.22419 7.06044 -1.03634 12.4166 0.299234C17.7741 1.63481 21.0341 7.0617 19.6984 12.4191L19.6985 12.419H19.6986Z'
        fill='#50AF95'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.2654 11.1334V11.1334C11.1947 11.1387 10.8294 11.1605 10.0145 11.1605C9.36631 11.1605 8.90611 11.1411 8.74466 11.1334V11.1334C6.23979 11.0232 4.37012 10.5872 4.37012 10.0651C4.37012 9.54303 6.23979 9.10758 8.74466 8.99562V10.6991C8.90847 10.7109 9.3775 10.7386 10.0257 10.7386C10.8035 10.7386 11.193 10.7062 11.2631 10.6997V8.9968C13.7626 9.10817 15.6282 9.54421 15.6282 10.0651C15.6282 10.586 13.7632 11.022 11.2631 11.1328V11.1328L11.2654 11.1334ZM11.2654 8.82061V7.29624H14.7537V4.97168H5.25634V7.29624H8.74406V8.82002C5.90922 8.95024 3.77734 9.51179 3.77734 10.1847C3.77734 10.8576 5.90922 11.4186 8.74406 11.5494V16.4342H11.2648V11.5476C14.0932 11.4174 16.2215 10.8564 16.2215 10.1841C16.2215 9.51179 14.095 8.95083 11.2648 8.82002V8.82002L11.2654 8.82061Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_3326_15329'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
const UsdtSVG = memo(Usdt);
export default UsdtSVG;
