import { SignerWalletAdapterProps } from '@solana/wallet-adapter-base';
import { Transaction } from '@solana/web3.js';

export const signTransactionsMethod = async (
  transactions: Transaction,
  signTransaction: SignerWalletAdapterProps['signTransaction'] | undefined,
  signTransactionMagic?: (transaction: Transaction) => Promise<Transaction>,
): Promise<Transaction | undefined> => {
  if (localStorage.getItem('typeConnect') === 'walletAuth' && signTransaction) {
    const transactionSigned = await signTransaction(transactions);
    return transactionSigned;
  }
  if (
    localStorage.getItem('typeConnect') !== 'walletAuth' &&
    signTransactionMagic
  ) {
    const signatures = transactions.signatures.filter(
      it => it.signature != null,
    );
    const signTran = await signTransactionMagic(transactions);
    signTran.signatures = signTran.signatures.map(item => {
      const find = signatures.find(it => it.publicKey.equals(item.publicKey));
      if (item.signature == null && find && find.signature != null) return find;
      return item;
    });
    return signTran;
  }
  return undefined;
};
