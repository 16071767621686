import { FC, memo } from 'react';
import './successTitle.scss';
import { ISuccessTitleProps } from './types';
import {
  SuccessCircleSVG,
  ErrorCircleSVG,
} from '@/shared/assets/svg/AlertsIcons';

export const SuccessTitle: FC<ISuccessTitleProps> = memo(
  ({ text, font, type = 'success' }) => (
    <div className='success-title'>
      <div className='success-title__icon'>
        {type === 'success' ? (
          <SuccessCircleSVG width='40' height='40' type='alternative' />
        ) : (
          <ErrorCircleSVG width='40' height='40' type='alternative' />
        )}
      </div>
      <div
        className={`success-title__text ${
          font === 'H3' ? 'ordinary' : 'clash'
        }`}
      >
        {text}
      </div>
    </div>
  ),
);
