import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Download: FC<ISvgProps> = ({ width = '16', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.51875 7.23125C5.4508 7.16033 5.40465 7.07137 5.38582 6.97497C5.36698 6.87857 5.37624 6.77879 5.4125 6.6875C5.44978 6.59533 5.5137 6.51637 5.59608 6.4607C5.67847 6.40504 5.77557 6.3752 5.875 6.375H8V2.5C8 2.36739 8.05268 2.24021 8.14645 2.14645C8.24021 2.05268 8.36739 2 8.5 2C8.63261 2 8.75979 2.05268 8.85355 2.14645C8.94732 2.24021 9 2.36739 9 2.5V6.375H11.125C11.2244 6.3752 11.3215 6.40504 11.4039 6.4607C11.4863 6.51637 11.5502 6.59533 11.5875 6.6875C11.6238 6.77879 11.633 6.87857 11.6142 6.97497C11.5953 7.07137 11.5492 7.16033 11.4812 7.23125L8.85625 9.85625C8.76068 9.94866 8.63294 10.0003 8.5 10.0003C8.36706 10.0003 8.23932 9.94866 8.14375 9.85625L5.51875 7.23125ZM14 9C13.8674 9 13.7402 9.05268 13.6464 9.14645C13.5527 9.24021 13.5 9.36739 13.5 9.5V13H3.5V9.5C3.5 9.36739 3.44732 9.24021 3.35355 9.14645C3.25979 9.05268 3.13261 9 3 9C2.86739 9 2.74021 9.05268 2.64645 9.14645C2.55268 9.24021 2.5 9.36739 2.5 9.5V13C2.5 13.2652 2.60536 13.5196 2.79289 13.7071C2.98043 13.8946 3.23478 14 3.5 14H13.5C13.7652 14 14.0196 13.8946 14.2071 13.7071C14.3946 13.5196 14.5 13.2652 14.5 13V9.5C14.5 9.36739 14.4473 9.24021 14.3536 9.14645C14.2598 9.05268 14.1326 9 14 9Z'
      fill='white'
    />
  </svg>
);
const DownloadSVG = memo(Download);
export default DownloadSVG;
