import { FC, memo } from 'react';
import { ISvgProps } from './types';

const Dollar: FC<ISvgProps> = ({ width = '24', height = '24', fill }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='12' cy='12' r='12' fill={fill || 'inherit'} />
    <path
      d='M11.5868 18.182V16.814H12.4688V18.182H11.5868ZM11.5868 8.54V7.166H12.4688V8.54H11.5868ZM12.1328 17.18C11.5608 17.18 11.0448 17.078 10.5848 16.874C10.1288 16.67 9.7508 16.382 9.4508 16.01C9.1548 15.638 8.9608 15.198 8.8688 14.69L9.7268 14.54C9.8588 15.092 10.1428 15.53 10.5788 15.854C11.0188 16.174 11.5488 16.334 12.1688 16.334C12.7968 16.334 13.3048 16.182 13.6928 15.878C14.0808 15.57 14.2748 15.168 14.2748 14.672C14.2748 14.304 14.1668 14.01 13.9508 13.79C13.7348 13.57 13.3688 13.382 12.8528 13.226L10.9328 12.626C9.7728 12.262 9.1928 11.574 9.1928 10.562C9.1928 10.09 9.3088 9.676 9.5408 9.32C9.7768 8.96 10.1028 8.68 10.5188 8.48C10.9388 8.28 11.4288 8.182 11.9888 8.186C12.5368 8.186 13.0268 8.29 13.4588 8.498C13.8948 8.702 14.2528 8.992 14.5328 9.368C14.8128 9.744 14.9988 10.19 15.0908 10.706L14.2088 10.874C14.1528 10.506 14.0228 10.184 13.8188 9.908C13.6188 9.632 13.3608 9.418 13.0448 9.266C12.7288 9.11 12.3768 9.03 11.9888 9.026C11.6208 9.026 11.2928 9.09 11.0048 9.218C10.7168 9.346 10.4888 9.524 10.3208 9.752C10.1568 9.976 10.0748 10.234 10.0748 10.526C10.0748 10.846 10.1888 11.118 10.4168 11.342C10.6488 11.562 11.0148 11.748 11.5148 11.9L13.1648 12.398C13.8608 12.606 14.3628 12.886 14.6708 13.238C14.9788 13.59 15.1328 14.06 15.1328 14.648C15.1328 15.156 15.0088 15.6 14.7608 15.98C14.5128 16.36 14.1628 16.656 13.7108 16.868C13.2628 17.076 12.7368 17.18 12.1328 17.18Z'
      fill='#121212'
    />
  </svg>
);
const DollarSVG = memo(Dollar);
export default DollarSVG;
