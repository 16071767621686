import { FC, memo } from 'react';
import { ISvgProps } from './types';

const TriangleError: FC<ISvgProps> = ({ width = '16', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.25105 1.70143C7.47871 1.56941 7.73721 1.49988 8.00038 1.49988C8.26356 1.49988 8.52206 1.56941 8.74972 1.70143C8.977 1.83323 9.16549 2.02263 9.29618 2.25053L9.29685 2.25169L14.7959 11.75C14.9273 11.9777 14.9966 12.2359 14.9969 12.4987C14.9971 12.7616 14.9282 13.0199 14.7972 13.2477C14.6662 13.4756 14.4776 13.6651 14.2503 13.7971C14.023 13.9291 13.765 13.9991 13.5021 14L13.5004 14H2.50039L2.49862 14C2.23577 13.9991 1.97777 13.9291 1.75048 13.7971C1.52318 13.6651 1.33457 13.4756 1.20355 13.2477C1.07252 13.0199 1.00367 12.7616 1.00391 12.4987C1.00414 12.2359 1.07344 11.9777 1.20487 11.75L6.70392 2.25169L6.70459 2.25053C6.83528 2.02263 7.02377 1.83322 7.25105 1.70143ZM8.00038 2.49988C7.91339 2.49988 7.82795 2.52286 7.7527 2.5665C7.67744 2.61014 7.61505 2.67288 7.57185 2.74838L7.5706 2.75056L2.0709 12.25C2.02716 12.3259 2.00398 12.4121 2.00391 12.4996C2.00383 12.5872 2.02678 12.6733 2.07045 12.7493C2.11413 12.8252 2.177 12.8884 2.25276 12.9324C2.32833 12.9763 2.41408 12.9996 2.50146 13H13.4993C13.5867 12.9996 13.6724 12.9763 13.748 12.9324C13.8238 12.8884 13.8866 12.8252 13.9303 12.7493C13.974 12.6733 13.9969 12.5872 13.9969 12.4996C13.9968 12.4121 13.9737 12.3261 13.93 12.2503L8.42892 2.74839C8.38571 2.67289 8.32333 2.61014 8.24807 2.5665C8.17282 2.52286 8.08738 2.49988 8.00038 2.49988ZM8.00037 6.00003C8.27651 6.00003 8.50037 6.22389 8.50037 6.50003V9.00003C8.50037 9.27617 8.27651 9.50003 8.00037 9.50003C7.72422 9.50003 7.50037 9.27617 7.50037 9.00003V6.50003C7.50037 6.22389 7.72422 6.00003 8.00037 6.00003ZM8.75037 11.25C8.75037 11.6642 8.41458 12 8.00037 12C7.58615 12 7.25037 11.6642 7.25037 11.25C7.25037 10.8358 7.58615 10.5 8.00037 10.5C8.41458 10.5 8.75037 10.8358 8.75037 11.25Z'
      fill='#FF4445'
    />
  </svg>
);
const TriangleErrorSVG = memo(TriangleError);
export default TriangleErrorSVG;
