import { FC, memo } from 'react';
import { ISvgProps } from './types';

const AddPhoto: FC<ISvgProps> = ({ width = '52', height = '52' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 52 52'
    fill='transparent'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M26.6523 13.3111L26.6521 13.3107L24.8444 9.86759C24.8444 9.86745 24.8443 9.8673 24.8442 9.86716C24.3303 8.88794 23.5475 8.07818 22.5957 7.51809L21.5814 9.24179L22.5957 7.51809C21.6447 6.95846 20.557 6.66642 19.4536 6.66675C19.4534 6.66675 19.4532 6.66675 19.453 6.66675C19.4529 6.66675 19.4528 6.66675 19.4527 6.66675L9.82179 6.66675C8.24933 6.66675 6.72283 7.26074 5.58262 8.34665C4.4391 9.43572 3.77734 10.9342 3.77734 12.5186V39.4816C3.77734 41.0659 4.4391 42.5644 5.58262 43.6535C6.72283 44.7394 8.24933 45.3334 9.82179 45.3334H42.1773C43.7498 45.3334 45.2763 44.7394 46.4165 43.6535C47.56 42.5644 48.2218 41.0659 48.2218 39.4816V20.2223C48.2218 18.6379 47.56 17.1394 46.4165 16.0504C45.2763 14.9644 43.7498 14.3705 42.1773 14.3705H28.4991C28.4991 14.3705 28.4991 14.3705 28.499 14.3705C28.0997 14.3704 27.7144 14.2641 27.3872 14.0714C27.0605 13.8791 26.8103 13.6122 26.6523 13.3111Z'
      stroke='#3D3D3D'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M27.0004 21.6667C27.0004 21.1145 26.5527 20.6667 26.0004 20.6667C25.4481 20.6667 25.0004 21.1145 25.0004 21.6667V26.4445H20.2227C19.6704 26.4445 19.2227 26.8922 19.2227 27.4445C19.2227 27.9968 19.6704 28.4445 20.2227 28.4445H25.0004V33.2223C25.0004 33.7746 25.4481 34.2223 26.0004 34.2223C26.5527 34.2223 27.0004 33.7746 27.0004 33.2223V28.4445H31.7782C32.3305 28.4445 32.7782 27.9968 32.7782 27.4445C32.7782 26.8922 32.3305 26.4445 31.7782 26.4445H27.0004V21.6667Z'
      stroke='#3D3D3D'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
const AddPhotoSVG = memo(AddPhoto);
export default AddPhotoSVG;
