interface Environment {
  API_URL: string;
  APP_ENV: string;
  CLIENT_APP_URL: string;
  COINFLOW_ENV: string;
  CROSSMINT_CLIENT_ID: string;
  GOOGLE_ANALYTICS: string;
  MAGIC_LINK_ID: string;
  MERCHANT_ID: string;
  NETWORK: string;
  RPC_URL: string;
  SENTRY_DSN: string;
  SIGNIN_GOOGLE_ID: string;
}

export const environment: Environment = {
  API_URL:
    process.env.REACT_APP_API_URL || 'https://dev-api.collectorcrypt.com/',
  APP_ENV: process.env.REACT_APP_ENV || 'local',
  CLIENT_APP_URL:
    process.env.REACT_APP_CLIENT_APP_URL || 'https://dev.collectorcrypt.com/',
  COINFLOW_ENV: process.env.REACT_APP_COINFLOW_ENV || 'sandbox',
  CROSSMINT_CLIENT_ID: process.env.REACT_APP_CROSSMINT_CLIENT_ID || '',
  GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS || 'G-N6KT72LESQ',
  MAGIC_LINK_ID:
    process.env.REACT_APP_MAGIC_LINK_ID || 'pk_live_ABB74829B13C80A7',
  MERCHANT_ID: process.env.REACT_APP_MERCHANT_ID || 'collectorcrypt',
  NETWORK: process.env.REACT_APP_NETWORK || 'devnet',
  RPC_URL: process.env.REACT_APP_RPC_URL || 'https://api.devnet.solana.com',
  SENTRY_DSN:
    process.env.REACT_APP_SENTRY_DSN ||
    'https://5f40c59d4cef4a6283ef534ef12cadcf@o1363123.ingest.sentry.io/6680400',
  SIGNIN_GOOGLE_ID: process.env.REACT_APP_SIGNIN_GOOGLE_ID || '',
};

type GlobalWithEnvironment = typeof globalThis & { environment: Environment };
const globalWithEnvironment = globalThis as unknown as GlobalWithEnvironment;
globalWithEnvironment.environment = environment;
