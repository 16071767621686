import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const WarningTriangle: FC<ISvgProps> = ({
  width = '14',
  height = '13',
  type,
}) =>
  type === 'alternative' ? (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M41.3812 31.2501L24.9 2.75012C24.5054 2.06468 23.9371 1.49534 23.2525 1.09945C22.5678 0.703561 21.7909 0.495117 21 0.495117C20.2091 0.495117 19.4321 0.703561 18.7475 1.09945C18.0628 1.49534 17.4945 2.06468 17.1 2.75012L0.618719 31.2501C0.218347 31.9321 0.00557132 32.7079 0.00199886 33.4987C-0.00157359 34.2895 0.204185 35.0671 0.598379 35.7527C0.992574 36.4382 1.56117 37.0073 2.24642 37.402C2.93167 37.7967 3.70917 38.0031 4.49997 38.0001H37.5C38.2908 38.0031 39.0683 37.7967 39.7535 37.402C40.4388 37.0073 41.0074 36.4382 41.4016 35.7527C41.7958 35.0671 42.0015 34.2895 41.9979 33.4987C41.9944 32.7079 41.7816 31.9321 41.3812 31.2501ZM19.5 15.5001C19.5 15.1023 19.658 14.7208 19.9393 14.4395C20.2206 14.1582 20.6021 14.0001 21 14.0001C21.3978 14.0001 21.7793 14.1582 22.0606 14.4395C22.3419 14.7208 22.5 15.1023 22.5 15.5001V23.0001C22.5 23.3979 22.3419 23.7795 22.0606 24.0608C21.7793 24.3421 21.3978 24.5001 21 24.5001C20.6021 24.5001 20.2206 24.3421 19.9393 24.0608C19.658 23.7795 19.5 23.3979 19.5 23.0001V15.5001ZM21 32.0001C20.555 32.0001 20.1199 31.8682 19.7499 31.6209C19.3799 31.3737 19.0915 31.0223 18.9212 30.6112C18.7509 30.2 18.7064 29.7476 18.7932 29.3112C18.88 28.8747 19.0943 28.4738 19.409 28.1591C19.7236 27.8445 20.1246 27.6302 20.561 27.5434C20.9975 27.4565 21.4499 27.5011 21.861 27.6714C22.2721 27.8417 22.6235 28.1301 22.8708 28.5001C23.118 28.8701 23.25 29.3051 23.25 29.7501C23.25 30.3469 23.0129 30.9192 22.591 31.3411C22.169 31.7631 21.5967 32.0001 21 32.0001Z'
        fill='#FFA927'
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.25056 0.380017C6.47822 0.247996 6.73672 0.178467 6.9999 0.178467C7.26307 0.178467 7.52157 0.247996 7.74924 0.380017C7.97651 0.511814 8.165 0.70122 8.29569 0.929117L8.29636 0.930277L13.7954 10.4286C13.9268 10.6563 13.9961 10.9144 13.9964 11.1773C13.9966 11.4402 13.9278 11.6985 13.7967 11.9263C13.6657 12.1542 13.4771 12.3437 13.2498 12.4757C13.0225 12.6077 12.7645 12.6777 12.5017 12.6786L12.4999 12.6786H1.4999L1.49814 12.6786C1.23528 12.6777 0.977281 12.6077 0.749988 12.4757C0.522694 12.3437 0.334086 12.1542 0.203059 11.9263C0.0720322 11.6985 0.00318626 11.4402 0.00341855 11.1773C0.00365198 10.9144 0.0729553 10.6563 0.204386 10.4286L5.70343 0.930277L5.7041 0.929117C5.83479 0.70122 6.02328 0.511814 6.25056 0.380017ZM6.9999 1.17847C6.91291 1.17847 6.82746 1.20145 6.75221 1.24509C6.67695 1.28873 6.61457 1.35147 6.57136 1.42697L6.57012 1.42915L1.07041 10.9286C1.02667 11.0045 1.0035 11.0906 1.00342 11.1782C1.00334 11.2658 1.02629 11.3519 1.06997 11.4279C1.11364 11.5038 1.17651 11.567 1.25227 11.611C1.32784 11.6549 1.41359 11.6782 1.50098 11.6786H12.4988C12.5862 11.6782 12.672 11.6549 12.7475 11.611C12.8233 11.567 12.8862 11.5038 12.9298 11.4279C12.9735 11.3519 12.9965 11.2658 12.9964 11.1782C12.9963 11.0907 12.9733 11.0047 12.9296 10.9289L7.42843 1.42698C7.38522 1.35148 7.32284 1.28873 7.24759 1.24509C7.17233 1.20145 7.08689 1.17847 6.9999 1.17847ZM6.99988 4.67862C7.27602 4.67862 7.49988 4.90248 7.49988 5.17862V7.67862C7.49988 7.95476 7.27602 8.17862 6.99988 8.17862C6.72374 8.17862 6.49988 7.95476 6.49988 7.67862V5.17862C6.49988 4.90248 6.72374 4.67862 6.99988 4.67862ZM7.74988 9.92862C7.74988 10.3428 7.41409 10.6786 6.99988 10.6786C6.58566 10.6786 6.24988 10.3428 6.24988 9.92862C6.24988 9.51441 6.58566 9.17862 6.99988 9.17862C7.41409 9.17862 7.74988 9.51441 7.74988 9.92862Z'
        fill='#FFA927'
      />
    </svg>
  );
const WarningTriangleSVG = memo(WarningTriangle);
export default WarningTriangleSVG;
