import { FC, memo } from 'react';
import { ISvgProps } from './types';

const NotFound: FC<ISvgProps> = ({ width = '244', height = '97' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 244 97'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M72.7456 96.2815V75.8755H90.5648V96.2814H164.213V75.8755H215.084V96.2815H225.431V75.8755H243.394V66.5348H225.431V0H213.36L164.213 56.1003V1.07773H90.5648V66.5348H72.7456V0H60.6744L0.75 68.403V75.8755H62.3989V96.2815H72.7456ZM14.833 66.5348V65.6726L61.6804 12.3585H62.3989V66.5348H14.833ZM167.518 66.5348V65.6726L214.366 12.3585H215.084V66.5348H167.518Z'
      fill='#D2D4D6'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M101.283 32.4523H153.345V85.3442H101.283V32.4523ZM97.6924 88.9301V28.8665H156.935V88.9301H97.6924ZM120.321 43.9L116.74 47.4766L113.159 43.9L110.95 46.106L114.531 49.6826L110.95 53.2592L113.159 55.4651L116.74 51.8885L120.321 55.4651L122.53 53.2592L118.948 49.6826L122.53 46.106L120.321 43.9ZM141.146 43.9L137.565 47.4766L133.983 43.9L131.775 46.106L135.356 49.6826L131.775 53.2592L133.983 55.4651L137.565 51.8885L141.146 55.4651L143.355 53.2592L139.773 49.6826L143.355 46.106L141.146 43.9ZM117.57 62.1615H136.65L142.834 68.3378L140.625 70.5438L135.356 65.2812H129.235V67.361C129.235 70.2326 126.904 72.5605 124.028 72.5605C121.153 72.5605 118.822 70.2326 118.822 67.361V65.5339L114.295 70.9594L111.896 68.9622L117.57 62.1615ZM121.946 65.2812H126.111V67.361C126.111 68.5096 125.179 69.4408 124.028 69.4408C122.878 69.4408 121.946 68.5096 121.946 67.361V65.2812Z'
      fill='#1D1D1D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M153.345 11.8334H101.283V19.9017H153.345V11.8334ZM97.6924 8.24756V23.4876H156.935V8.24756H97.6924Z'
      fill='#EA0000'
    />
  </svg>
);
const NotFoundSVG = memo(NotFound);
export default NotFoundSVG;
