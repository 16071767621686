import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import {
  getAllBurnedCardsAction,
  getBurnIdAction,
  ISelectFilterBurnedCardsAction,
} from '../actions/burnedCardsPage';
import {
  setLoadingCardsSlice,
  setAllBurnedCardsSlice,
  setBurnIdDataSlice,
} from '../slice/burnedCardsPage';
import { setChangeTotalSlice } from '@/entities/AllCards';
import {
  getAllBurnedCardsRequestGET,
  getBurnIdRequest,
} from '@/shared/api/services/burned';
import { errorHandlerForSaga } from '@/shared/lib/ErrorHandlerForSaga/ErrorHandlerForSaga';

function* workerGetAllBurnedCards({
  payload,
}: PayloadAction<ISelectFilterBurnedCardsAction>) {
  try {
    yield put(setLoadingCardsSlice(true));
    const response: IResponse<IAllUserCards> =
      yield getAllBurnedCardsRequestGET(payload);
    yield put(
      setAllBurnedCardsSlice({
        ...response.data,
        clear: payload.clear ? payload.clear : undefined,
      }),
    );
    yield put(setLoadingCardsSlice(false));
  } catch (error) {
    errorHandlerForSaga(error);
    yield put(setLoadingCardsSlice(false));
  }
}

export function* watcherGetAllBurnedCards() {
  yield takeEvery(getAllBurnedCardsAction.toString(), workerGetAllBurnedCards);
}

function* workerGetBurnIdData() {
  try {
    const response: IResponse<IBurnedIdData> = yield getBurnIdRequest();
    yield put(setBurnIdDataSlice(response.data));
    yield put(setChangeTotalSlice(response.data));
  } catch (error) {
    errorHandlerForSaga(error);
    yield put(setLoadingCardsSlice(false));
  }
}

export function* watcherGetBurnIdData() {
  yield takeEvery(getBurnIdAction.toString(), workerGetBurnIdData);
}
