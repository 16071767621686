import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SendFormSchema } from '../types/sendMoneyForm';

const initialState: SendFormSchema = {
  amount: '',
  isOpened: false,
  to: '',
};

export const sendFormSlice = createSlice({
  initialState,
  name: 'sendForm',
  reducers: {
    setAmount: (state, { payload }: PayloadAction<string>) => {
      state.amount = payload;
    },
    setInitState: state => {
      state.amount = '';
      state.to = '';
    },
    setIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpened = payload;
    },
    setSendTo: (state, { payload }: PayloadAction<string>) => {
      state.to = payload;
    },
  },
});

export const { actions: sendFormActions } = sendFormSlice;
export const { reducer: sendFormReducer } = sendFormSlice;
