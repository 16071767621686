import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const OwnIcon: FC<ISvgProps> = ({ width = '14', height = '13' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 14 13'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.3056 12.2495C12.3726 10.6423 10.8793 9.43603 9.11183 8.86196C9.959 8.35818 10.6173 7.5902 10.9857 6.67598C11.3541 5.76176 11.4121 4.75189 11.1509 3.80149C10.8897 2.85109 10.3236 2.01275 9.53975 1.41525C8.75585 0.817748 7.79747 0.494141 6.81183 0.494141C5.82618 0.494141 4.8678 0.817748 4.08391 1.41525C3.30002 2.01275 2.73398 2.85109 2.47275 3.80149C2.21152 4.75189 2.26955 5.76176 2.63793 6.67598C3.00631 7.5902 3.66465 8.35818 4.51183 8.86196C2.74439 9.43603 1.25104 10.6423 0.318077 12.2495C0.272272 12.3248 0.248047 12.4113 0.248047 12.4995C0.248047 12.5876 0.272272 12.6741 0.318077 12.7495C0.360813 12.8261 0.423433 12.8897 0.499333 12.9337C0.575233 12.9777 0.661601 13.0004 0.749327 12.9995H12.8743C12.9621 13.0004 13.0484 12.9777 13.1243 12.9337C13.2002 12.8897 13.2628 12.8261 13.3056 12.7495C13.3514 12.6741 13.3756 12.5876 13.3756 12.4995C13.3756 12.4113 13.3514 12.3248 13.3056 12.2495Z'
      fill='#828585'
    />
  </svg>
);
export const OwnIconSVG = memo(OwnIcon);
