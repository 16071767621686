import { FC, memo } from 'react';
import { ISvgProps } from './types';

const BoxQty: FC<ISvgProps> = ({ width = '17', height = '17' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 17 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.8688 4.92375C14.8671 4.92375 14.8655 4.92309 14.8643 4.92192C14.8632 4.92075 14.8625 4.91916 14.8625 4.9175C14.7722 4.7637 14.6428 4.63649 14.4875 4.54875L8.9875 1.455C8.83849 1.3718 8.67067 1.32812 8.5 1.32812C8.32934 1.32812 8.16152 1.3718 8.0125 1.455L2.5125 4.54875C2.35721 4.63649 2.22784 4.7637 2.1375 4.9175C2.1375 4.91916 2.13685 4.92075 2.13567 4.92192C2.1345 4.92309 2.13291 4.92375 2.13125 4.92375V4.93625C2.04485 5.08187 1.99949 5.24818 2 5.4175V11.58C2.00032 11.7582 2.04793 11.933 2.13798 12.0868C2.22802 12.2405 2.35727 12.3676 2.5125 12.455L8.0125 15.5488C8.15097 15.6243 8.30492 15.667 8.4625 15.6738H8.55C8.70362 15.6669 8.85348 15.6241 8.9875 15.5488L14.4875 12.455C14.6427 12.3676 14.772 12.2405 14.862 12.0868C14.9521 11.933 14.9997 11.7582 15 11.58V5.4175C15.0009 5.24418 14.9556 5.07374 14.8688 4.92375ZM8.5 2.32375L13.4813 5.12375L11.5688 6.21125L10.55 6.7925L8.55625 7.92375L3.525 5.12375L5.5 4.01125L6.525 3.43L8.5 2.32375ZM9.00625 14.3925L9.05625 8.7925L11.0625 7.64875L12.0625 7.08L14 5.98V11.58L9.00625 14.3925Z'
      fill='#828585'
    />
  </svg>
);
const BoxQtySVG = memo(BoxQty);
export default BoxQtySVG;
