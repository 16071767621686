import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const HoverPanelShare: FC<ISvgProps> = ({ width = '18', height = '21' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 19 22'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.4981 14.0002C13.9979 13.9994 13.5027 14.0995 13.042 14.2944C12.5813 14.4893 12.1646 14.7751 11.8169 15.1346L7.49501 12.3596C7.83237 11.4848 7.83237 10.5157 7.49501 9.64087L11.8169 6.86587C12.4249 7.48767 13.2301 7.87882 14.0947 7.97238C14.9593 8.06594 15.8295 7.85609 16.5565 7.37875C17.2834 6.9014 17.8218 6.18625 18.0796 5.35568C18.3374 4.52511 18.2985 3.63077 17.9695 2.82573C17.6406 2.02069 17.0421 1.355 16.2764 0.942603C15.5108 0.530206 14.6256 0.396739 13.7724 0.565046C12.9192 0.733353 12.151 1.19297 11.5993 1.86523C11.0476 2.53749 10.7467 3.38059 10.7481 4.25024C10.7496 4.71494 10.8354 5.17552 11.0013 5.60962L6.67938 8.38462C6.15905 7.85005 5.49111 7.48282 4.76096 7.32989C4.03082 7.17696 3.27165 7.24527 2.58053 7.52609C1.88942 7.80691 1.29776 8.28748 0.881223 8.90635C0.464683 9.52521 0.242188 10.2543 0.242188 11.0002C0.242188 11.7462 0.464683 12.4753 0.881223 13.0941C1.29776 13.713 1.88942 14.1936 2.58053 14.4744C3.27165 14.7552 4.03082 14.8235 4.76096 14.6706C5.49111 14.5177 6.15905 14.1504 6.67938 13.6159L11.0013 16.3909C10.8354 16.825 10.7496 17.2855 10.7481 17.7502C10.7481 18.4919 10.9681 19.2169 11.3801 19.8336C11.7922 20.4503 12.3778 20.931 13.0631 21.2148C13.7483 21.4986 14.5023 21.5729 15.2297 21.4282C15.9571 21.2835 16.6253 20.9263 17.1498 20.4019C17.6742 19.8774 18.0314 19.2093 18.1761 18.4818C18.3208 17.7544 18.2465 17.0004 17.9627 16.3152C17.6789 15.63 17.1982 15.0443 16.5815 14.6322C15.9648 14.2202 15.2398 14.0002 14.4981 14.0002Z'
      fill='inherit'
    />
  </svg>
);
const HoverPanelShareSVG = memo(HoverPanelShare);
export default HoverPanelShareSVG;
