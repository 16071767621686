import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Fire: FC<ISvgProps> = ({ width = '18', height = '21' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.4781 6.24309C17.2594 4.29309 15.675 2.61496 14.2969 1.23684C14.2119 1.1526 14.108 1.08992 13.9938 1.05404C13.8796 1.01816 13.7585 1.01012 13.6406 1.03059C13.5219 1.04929 13.4095 1.09662 13.3131 1.16849C13.2167 1.24035 13.1393 1.3346 13.0875 1.44309L10.1906 7.51809L7.17188 5.50246C7.0063 5.39286 6.80437 5.35248 6.60938 5.38996C6.51264 5.40905 6.42062 5.44703 6.33857 5.50173C6.25653 5.55642 6.18608 5.62676 6.13125 5.70871C4.30313 8.46496 3.375 10.9587 3.375 13.1243C3.375 15.4118 4.2837 17.6056 5.9012 19.2231C7.51871 20.8406 9.71251 21.7493 12 21.7493C14.2875 21.7493 16.4813 20.8406 18.0988 19.2231C19.7163 17.6056 20.625 15.4118 20.625 13.1243C20.625 10.79 19.9219 8.53996 18.4781 6.24309ZM17.5594 13.9868C17.382 15.1692 16.8301 16.2636 15.9846 17.109C15.1392 17.9544 14.0449 18.5064 12.8625 18.6837H12.75C12.5618 18.6823 12.3811 18.6102 12.2436 18.4818C12.1061 18.3533 12.0219 18.1778 12.0077 17.9902C11.9935 17.8025 12.0504 17.6164 12.1671 17.4688C12.2838 17.3211 12.4517 17.2227 12.6375 17.1931C13.4994 17.053 14.2954 16.6453 14.9129 16.0279C15.5304 15.4104 15.938 14.6144 16.0781 13.7525C16.0885 13.6514 16.1193 13.5536 16.1686 13.4648C16.2179 13.376 16.2847 13.2982 16.365 13.236C16.4453 13.1739 16.5374 13.1287 16.6357 13.1031C16.734 13.0776 16.8364 13.0723 16.9368 13.0876C17.0372 13.1028 17.1335 13.1383 17.2198 13.1919C17.306 13.2454 17.3805 13.3159 17.4388 13.3991C17.497 13.4824 17.5377 13.5765 17.5584 13.6759C17.5791 13.7753 17.5795 13.8779 17.5594 13.9775V13.9868Z'
      fill='inherit'
    />
  </svg>
);
const FireSVG = memo(Fire);
export default FireSVG;
