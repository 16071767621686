import { memo, useEffect } from 'react';
import cls from './SuccessSent.module.scss';
import { getFormTo } from '../../model/selector/getFormData/getFormData';
import { getAuthData } from '@/entities/Auth';
import { fetchUserInfo } from '@/entities/Headers';
import { OutsideLinkSVG, SuccessMarkSVG } from '@/shared/assets/svg';
import { classNames } from '@/shared/lib/classNames/classNames';
import { sliceString } from '@/shared/lib/helpers/scliceStringFunction';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { Button } from '@/shared/ui/Buttons';
import { HStack, VStack } from '@/shared/ui/Stack';

export interface SuccessSentProps {
  className?: string;
  setOpenModal: () => void;
  successTrans: string;
  amount: string;
  isUSDC: boolean;
}
export const SuccessSent = memo((props: SuccessSentProps) => {
  const dispatch = useAppDispatch();
  const { className, setOpenModal, successTrans, amount, isUSDC } = props;
  const { wallet } = useAppSelector(getAuthData);
  const valueTo = useAppSelector(getFormTo);
  useEffect(() => {
    if (wallet) dispatch(fetchUserInfo({ user: wallet }));
  }, [dispatch, wallet]);
  return (
    <VStack
      className={classNames(cls.SuccessSent, {}, [className])}
      align='center'
      gap={2}
    >
      <VStack gap={1.25} align='center'>
        <SuccessMarkSVG />
        <HStack max className={cls.title} justify='center'>
          Tokens Sent!
        </HStack>
      </VStack>
      <div className={cls.info}>
        {amount} {isUSDC ? 'USDC' : 'SOL'}
        <span className={cls.greyColor} style={{ marginLeft: '4px' }}>
          was successfully sent to
        </span>
        {sliceString(valueTo || '')}
      </div>
      <VStack gap={0.5}>
        <span className={cls.greyColor}>Transaction</span>
        <a href={successTrans} target='_blank' rel='noreferrer'>
          <HStack>
            <span className={cls.link}>{sliceString(successTrans || '')}</span>
            <OutsideLinkSVG />
          </HStack>
        </a>
      </VStack>
      <Button
        fullWidth
        typeButton='secondary'
        text='close'
        size='small'
        onClick={setOpenModal}
      />
    </VStack>
  );
});
