import { FC, memo } from 'react';
import { ISvgProps } from './types';

const HeaderNotificationsBellWithDot: FC<ISvgProps> = ({
  width = '24',
  height = '24',
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.8352 11.4734C4.8352 6.44894 8.83669 2.25079 13.8914 2.18799C13.2574 3.00813 12.7817 3.95679 12.51 4.98824C9.619 5.68057 7.4602 8.33351 7.4602 11.4734V12.25C7.4602 15.7981 6.81617 18.1561 6.08679 19.6875H10.4804C10.4872 19.6874 10.4941 19.6873 10.5009 19.6873C10.5078 19.6873 10.5146 19.6874 10.5214 19.6875H17.4804C17.4872 19.6874 17.4941 19.6873 17.5009 19.6873C17.5078 19.6873 17.5146 19.6874 17.5214 19.6875H21.9149C21.3812 18.5671 20.8933 17.0042 20.6698 14.8564C21.5801 14.7943 22.4479 14.5775 23.2472 14.232C23.4614 16.7311 24.0599 18.2144 24.532 19.0299L24.5328 19.0312C24.7245 19.3632 24.8255 19.7397 24.8259 20.1231C24.8262 20.5064 24.7258 20.8831 24.5347 21.2154C24.3436 21.5477 24.0686 21.824 23.7371 22.0165C23.4056 22.2091 23.0294 22.3111 22.6461 22.3125L22.6415 22.3125H18.7935C18.6916 23.4284 18.2026 24.479 17.4039 25.2778C16.5014 26.1803 15.2773 26.6873 14.0009 26.6873C12.7246 26.6873 11.5005 26.1803 10.598 25.2778C9.79919 24.479 9.3102 23.4284 9.20834 22.3125H5.3602L5.35558 22.3125C4.97225 22.3111 4.596 22.2091 4.26453 22.0165C3.93305 21.824 3.658 21.5477 3.46692 21.2154C3.27584 20.8831 3.17544 20.5064 3.17578 20.1231C3.17612 19.7397 3.27719 19.3632 3.46886 19.0312L3.46964 19.0299C4.05518 18.0185 4.8352 15.9797 4.8352 12.25V11.4734ZM11.8576 22.3125C11.9428 22.7296 12.1486 23.1161 12.4541 23.4216C12.8644 23.8319 13.4208 24.0623 14.0009 24.0623C14.5811 24.0623 15.1375 23.8319 15.5477 23.4216C15.8532 23.1161 16.059 22.7296 16.1442 22.3125H11.8576Z'
      fill='#828585'
    />
    <circle cx='20.125' cy='7' r='4.375' fill='#5BEAFF' />
  </svg>
);
const HeaderNotificationsBellWithDotSVG = memo(HeaderNotificationsBellWithDot);
export default HeaderNotificationsBellWithDotSVG;
