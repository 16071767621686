import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const MarketPlace: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.9109 9.23556L19.9656 5.35111C19.7676 4.55111 19.0653 4 18.2461 4H5.74105C4.93079 4 4.21956 4.56 4.0305 5.35111L3.0852 9.23556C2.86913 10.1422 3.06719 11.0667 3.64338 11.7956C3.7154 11.8933 3.81443 11.9644 3.89546 12.0533V18.2222C3.89546 19.2 4.70572 20 5.69604 20H18.3001C19.2904 20 20.1007 19.2 20.1007 18.2222V12.0533C20.1817 11.9733 20.2807 11.8933 20.3528 11.8044C20.9289 11.0756 21.136 10.1422 20.9109 9.23556ZM18.2191 5.76889L19.1644 9.65333C19.2544 10.0267 19.1734 10.4 18.9393 10.6933C18.8133 10.8533 18.5432 11.1111 18.093 11.1111C17.5439 11.1111 17.0667 10.6756 17.0037 10.0978L16.4815 5.77778L18.2191 5.76889ZM12.8984 5.77778H14.6629L15.1491 9.79556C15.1941 10.1422 15.0861 10.4889 14.852 10.7467C14.6539 10.9778 14.3658 11.1111 13.9967 11.1111C13.3935 11.1111 12.8984 10.5867 12.8984 9.94667V5.77778ZM8.83805 9.79556L9.33321 5.77778H11.0978V9.94667C11.0978 10.5867 10.6026 11.1111 9.9364 11.1111C9.63031 11.1111 9.35122 10.9778 9.13515 10.7467C8.91007 10.4889 8.80204 10.1422 8.83805 9.79556ZM4.83176 9.65333L5.74105 5.77778H7.51462L6.99246 10.0978C6.92043 10.6756 6.45228 11.1111 5.90311 11.1111C5.46196 11.1111 5.18287 10.8533 5.06584 10.6933C4.82276 10.4089 4.74173 10.0267 4.83176 9.65333ZM5.69604 18.2222V12.8622C5.76806 12.8711 5.83108 12.8889 5.90311 12.8889C6.68636 12.8889 7.39759 12.5689 7.91975 12.0444C8.45993 12.5778 9.18016 12.8889 9.99942 12.8889C10.7827 12.8889 11.4849 12.5689 12.0071 12.0622C12.5382 12.5689 13.2585 12.8889 14.0687 12.8889C14.825 12.8889 15.5452 12.5778 16.0854 12.0444C16.6076 12.5689 17.3188 12.8889 18.102 12.8889C18.1741 12.8889 18.2371 12.8711 18.3091 12.8622V18.2222H5.69604Z'
      fill='inherit'
    />
  </svg>
);
const MarketPlaceSVG = memo(MarketPlace);
export default MarketPlaceSVG;
