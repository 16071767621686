import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const CloseWhite: FC<ISvgProps> = ({
  width = '24',
  height = '24',
  fill = 'inherit',
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill={fill}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20 5.6L18.4 4L12 10.4L5.6 4L4 5.6L10.4 12L4 18.4L5.6 20L12 13.6L18.4 20L20 18.4L13.6 12L20 5.6Z'
      fill={fill}
    />
  </svg>
);
const CloseWhiteSVG = memo(CloseWhite);
export default CloseWhiteSVG;
