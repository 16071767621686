import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/app/providers/StoreProvider';

export interface Res {
  data: string;
  dataId?: string;
  error?: null | string;
}

export interface TransBody {
  coin: string;
  to: string;
  amount: number;
}

export const createTrans = createAsyncThunk<
  string,
  TransBody,
  ThunkConfig<string>
>('headers/setTransaction', async (props, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;
  try {
    const response = await extra.api.post<Res>('/coin-transfer', props);
    if (!response.data) throw new Error();

    return response.data.data;
  } catch (e: unknown) {
    return rejectWithValue('Oops! Something went wrong...');
  }
});
