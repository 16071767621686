import { FC, memo, useEffect, useState } from 'react';
import './makeOffer.scss';
import MakeOfferDefault from './MakeOfferDefault/MakeOfferDefault';
import MakeOfferSuccess from './MakeOfferSuccess/MakeOfferSuccess';
import { IMakeOfferModalProps } from './types';
import { Modal } from '@/shared/ui/Modal';

export const MakeOfferModal: FC<IMakeOfferModalProps> = memo(
  ({
    open,
    setOpen,
    setCurrentOffer,
    cardData,
    typeModal,
    offer,
    getDataCard,
    setisLoading,
    getNFTCardActivities,
    currentUserWallet,
  }) => {
    const { price, offerId, from } = offer;
    const [transactionStatus, setTransactionStatus] = useState('none');
    const [transaction, setTransaction] = useState({
      transactionId: '',
      transactionUrl: '',
    });
    const [offerPrice, setOfferPrice] = useState('');

    const handleModalClose = () => {
      setOpen('makeOffer', { isOpen: false, typeModal: '' });
      setCurrentOffer({
        from: '',
        offerId: '',
        price: '',
        to: '',
      });
      setTransactionStatus('none');
      setOfferPrice('');
    };

    useEffect(() => {
      if (typeModal === 'update-offer' && !!price) setOfferPrice(price);
    }, [price, typeModal]);

    const changeTransactionStatus = (status: string) => {
      setTransactionStatus(status);
    };

    return (
      <Modal
        isOpen={open}
        className='make-offer-modal'
        onClose={handleModalClose}
      >
        {transactionStatus === 'resolved' ? (
          <MakeOfferSuccess
            getDataCard={getDataCard}
            cardData={cardData}
            handleModalClose={handleModalClose}
            offerPrice={offerPrice}
            transaction={transaction}
            typeModal={typeModal}
          />
        ) : (
          <MakeOfferDefault
            getDataCard={getDataCard}
            cardData={cardData}
            getNFTCardActivities={getNFTCardActivities}
            handleModalClose={handleModalClose}
            setisLoading={setisLoading}
            changeTransactionStatus={changeTransactionStatus}
            transactionStatus={transactionStatus}
            setOfferPrice={setOfferPrice}
            offerPrice={offerPrice}
            initialPrice={price}
            offerId={offerId}
            buyer={from}
            currentUserWallet={currentUserWallet || ''}
            setTransaction={setTransaction}
            typeModal={typeModal}
          />
        )}
      </Modal>
    );
  },
);
