export const initialCard: ICard = {
  ReasonForRejection: '',
  authenticated: false,
  author: {
    activities: 0,
    appleId: '',
    bio: '',
    createdAt: '',
    emailDiscoverable: false,
    facebookId: '',
    googleId: '',
    id: '',
    isActivate: false,
    isActivateEmail: false,
    listings: 0,
    name: '',
    offersMade: 0,
    offersReceived: 0,
    password: '',
    personalSite: '',
    photo: '',
    refreshToken: '',
    shippingAddress: [],
    twitterUsername: '',
    updatedAt: '',
    wallet: '',
  },
  authorId: '',
  autographed: false,
  backImage: '',
  blockchain: '',
  burnId: '',
  cartId: '',
  category: '',
  centering: '',
  contractAddress: '',
  corners: '',
  createdAt: '',
  edges: '',
  frontImage: '',
  grade: '',
  gradeNum: 0,
  gradingCompany: '',
  gradingID: null,
  id: '',
  images: {
    back: '',
    backM: '',
    backS: '',
    cardId: '',
    front: '',
    frontM: '',
    frontS: '',
    id: '',
  },
  insuredValue: '',
  itemName: '',
  listing: null,
  location: [''],
  nftAddress: '',
  nftStatus: '',
  offers: [],
  orientation: 'vertical',
  outboundShipmentId: '',
  owner: {
    activities: 0,
    appleId: '',
    bio: '',
    createdAt: '',
    emailDiscoverable: false,
    facebookId: '',
    googleId: '',
    id: '',
    isActivate: false,
    isActivateEmail: false,
    listings: 0,
    name: '',
    offersMade: 0,
    offersReceived: 0,
    password: '',
    personalSite: '',
    photo: '',
    refreshToken: '',
    shippingAddress: [],
    twitterUsername: '',
    updatedAt: '',
    wallet: '',
  },
  ownerId: '',
  qualifiers: [],
  sellerFeeBasisPoints: 0,
  serial: '',
  shippingId: '',
  status: '',
  submissionID: '',
  suggestPrice: '',
  surface: '',
  transactionsMultisig: [{ status: 'Ready', type: 'AwaitingUpdate' }],
  type: '',
  updatedAt: '',
  vault: '',
  vaultId: '',
  year: 0,
};

export const initialProfile = {
  activities: 0,
  appleId: '',
  bio: '',
  createdAt: '',
  emailDiscoverable: false,
  facebookId: '',
  googleId: '',
  id: '',
  isActivate: false,
  isActivateEmail: false,
  listings: 0,
  name: '',
  offersMade: 0,
  offersReceived: 0,
  password: '',
  personalSite: '',
  photo: '',
  refreshToken: '',
  shippingAddress: [],
  twitterUsername: '',
  updatedAt: '',
  wallet: '',
};

export const initialInboundShipment: IShipmentInbound = {
  createdAt: '',
  description: '',
  id: '',
  location: [''],
  numberOfCards: '',
  status: 'New',
  trackingId: null,
  trackingUrl: '',
  updatedAt: '',
  user: {
    email: '',
  },
  userId: '',
  valueOfBox: '',
};

export const initialOutboundShipment: IShipmentOutbound = {
  address: {
    apartment: '',
    city: '',
    country: '',
    fullName: '',
    id: '',
    isDefault: false,
    state: '',
    streetAddress: '',
    userId: '',
    zip: '',
  },
  comment: '',
  createdAt: '',
  customId: '',
  customer: '',
  deliveryCompany: '',
  feesCost: '',
  id: '',
  insurance: false,
  insuranceCost: '',
  numberOfCards: '',
  separatePayment: false,
  shippingCost: '',
  solscanURL: '',
  status: 'Shipped',
  totalCost: '',
  trackingId: '',
  trackingUrl: '',
  transactionId: '',
  transactionURL: '',
  typeCurrency: '',
  user: {
    email: '',
    wallet: '',
  },
  valueOfBox: '',
};

export const initialShipmentAddress: IShipmentAddress = {
  apartment: '',
  city: '',
  country: '',
  fullName: '',
  id: '',
  isDefault: false,
  state: '',
  streetAddress: '',
  userId: '',
};

export const initialSealedBox: SealedBox = {
  address: '',
  backImage: '',
  backImageM: '',
  backImageS: '',
  bestPrice: null,
  bestQty: null,
  boxes: 0,
  category: '',
  chunkId: '',
  createdAt: '',
  description: '',
  frontImage: '',
  frontImageM: '',
  frontImageS: '',
  id: '',
  insuredValue: '',
  listed: null,
  location: ['', ''],
  name: '',
  owned: 0,
  ownerId: '',
  owners: 0,
  qty: 0,
  status: '',
  type: '',
  updatedAt: '',
  vault: '',
  vaultId: '',
  year: 0,
};
