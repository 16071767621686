import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Link: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.25 17H7C5.58333 17 4.39583 16.5208 3.4375 15.5625C2.47917 14.6042 2 13.4167 2 12C2 10.5833 2.47917 9.39583 3.4375 8.4375C4.39583 7.47917 5.58333 7 7 7H11.25V8.5H7C6 8.5 5.16667 8.83333 4.5 9.5C3.83333 10.1667 3.5 11 3.5 12C3.5 13 3.83333 13.8333 4.5 14.5C5.16667 15.1667 6 15.5 7 15.5H11.25V17ZM8.125 12.75V11.25H15.875V12.75H8.125ZM12.75 17V15.5H17C18 15.5 18.8333 15.1667 19.5 14.5C20.1667 13.8333 20.5 13 20.5 12C20.5 11 20.1667 10.1667 19.5 9.5C18.8333 8.83333 18 8.5 17 8.5H12.75V7H17C18.4167 7 19.6042 7.47917 20.5625 8.4375C21.5208 9.39583 22 10.5833 22 12C22 13.4167 21.5208 14.6042 20.5625 15.5625C19.6042 16.5208 18.4167 17 17 17H12.75Z'
      fill='#828585'
    />
  </svg>
);

const LinkSVG = memo(Link);
export default LinkSVG;
