import { FC, memo } from 'react';
import { ISvgProps } from './types';

const Shipment: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 0.858215C11.6767 0.858215 11.3591 0.942635 11.0786 1.10306L6.97897 3.40908C6.95783 3.4201 6.93701 3.43181 6.91656 3.44419L2.83283 5.74128L2.82884 5.74354C2.58286 5.88323 2.37205 6.07617 2.21144 6.30752C2.17825 6.35076 2.14779 6.39696 2.12046 6.44599C2.10307 6.47719 2.08732 6.50885 2.07319 6.54089C1.94461 6.7986 1.87655 7.0829 1.875 7.37202V16.6218L1.87502 16.6279C1.8768 16.9592 1.96591 17.2842 2.13337 17.5701C2.30083 17.856 2.54071 18.0927 2.82884 18.2563L2.83283 18.2586L11.0786 22.8968C11.3592 23.0572 11.6768 23.1417 12 23.1417C12.3232 23.1417 12.6408 23.0572 12.9214 22.8968L21.1672 18.2586L21.1712 18.2563C21.4593 18.0927 21.6992 17.856 21.8666 17.5701C22.0341 17.2842 22.1232 16.9592 22.125 16.6279L21 16.6218H22.125L22.125 7.37806L22.125 7.37202C22.1234 7.08276 22.0553 6.79833 21.9266 6.54053C21.9113 6.50589 21.8941 6.47168 21.875 6.43804C21.849 6.39219 21.8202 6.34887 21.789 6.30817C21.6283 6.07653 21.4174 5.88336 21.1712 5.74354L12.9214 1.10306C12.6409 0.942636 12.3233 0.858215 12 0.858215ZM4.125 16.4039V8.85132L10.9538 12.6578L10.8902 20.2093L4.125 16.4039ZM13.1404 20.1921L19.875 16.4039V8.86809L17.7188 10.093V14.2969C17.7188 14.9182 17.2151 15.4219 16.5938 15.4219C15.9725 15.4219 15.4688 14.9182 15.4688 14.2969V11.3712L13.2039 12.6579L13.1404 20.1921ZM12.0791 10.7091L14.3053 9.44446L7.50584 5.69425L5.3042 6.93268L12.0791 10.7091ZM18.7114 6.94143L16.6003 8.14071L9.81233 4.39685L12 3.16629L18.7114 6.94143Z'
      fill='#dfe0e1'
    />
  </svg>
);
const ShipmentSVG = memo(Shipment);
export default ShipmentSVG;
