import { ITypeContentCancelModal } from '../../model/types/index';

export const selectTypeContent = (
  typeContent: ITypeContentCancelModal,
  shipmentId: string,
  email?: string,
) => {
  if (typeContent === 'cancelShipment')
    return {
      text: 'Are you sure you want to cancel your shipment?',
      textFirstButton: 'yes, cancel',
      textSecondButton: 'no',
      title: 'Cancel shipment',
    };
  if (typeContent === 'emptyEmail' || typeContent === 'unconfirmedEmail')
    return {
      text:
        typeContent === 'emptyEmail'
          ? 'We need to be able to contact you when your collectibles are ready. Please go to “My Account” and register your email address before sending in or withdrawing your cards.'
          : `Confirmation email sent to ${
              email || ''
            }. Please verify your address or change the email in your Profile Settings.`,
      textFirstButton: 'cancel',
      textSecondButton: 'My Account',
      title: 'MISSING CONTACT EMAIL',
    };
  if (typeContent === 'removeAddress')
    return {
      text: 'Are you sure you want to remove the address. This address will disappear from the shipment address list.',
      textFirstButton: 'cancel',
      textSecondButton: 'To My Account',
      title: 'Remove address',
    };
  if (typeContent === 'resumeShipment')
    return {
      text: `Do you want to resume Shipment ${shipmentId}?`,
      textFirstButton: 'no',
      textSecondButton: 'yes',
      title: 'Resume shipment',
    };

  return {
    text: 'Would you like to discard them?',
    textFirstButton: 'cancel',
    textSecondButton: 'discard',
    title: 'You have unsaved changes',
  };
};
