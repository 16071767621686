import { memo, useState } from 'react';
import cls from './UpdateListingBox.module.scss';
import { UpdateListingBoxDefault, UpdateListingBoxSuccess } from './ui';
import { classNames } from '@/shared/lib/classNames/classNames';
import { Modal } from '@/shared/ui/Modal';

export interface UpdateListSealedBoxProps {
  className?: string;
  isOpen: boolean;
  boxData: SealedBox;
  changeModalState: (modalName: string, value: boolean) => void;
}

export const UpdateListSealedBox = memo((props: UpdateListSealedBoxProps) => {
  const { className, isOpen, changeModalState, boxData } = props;
  const [transactionStatus, setTransactionStatus] = useState('none');
  const [quantity, setQuantity] = useState<number>(1);
  const [listingPrice, setListingPrice] = useState<string>('');

  const [transaction, setTransaction] = useState({
    price: '',
    transactionId: '',
    transactionUrl: '',
  });

  const changeTransactionStatus = (status: string) => {
    setTransactionStatus(status);
  };

  const handleModalClose = () => {
    changeModalState('updateListBox', false);
    setTransactionStatus('none');
    setListingPrice('');
  };

  return (
    <Modal
      isOpen={isOpen}
      className={classNames(cls.ListUpdateSealedBox, {}, [className])}
      onClose={handleModalClose}
    >
      {transactionStatus === 'resolved' ? (
        <UpdateListingBoxSuccess
          handleModalClose={handleModalClose}
          transaction={transaction}
          boxData={boxData}
        />
      ) : (
        <UpdateListingBoxDefault
          transactionStatus={transactionStatus}
          boxData={boxData}
          changeTransactionStatus={changeTransactionStatus}
          quantity={quantity}
          setQuantity={setQuantity}
          listingPrice={listingPrice}
          setListingPrice={setListingPrice}
          handleModalClose={handleModalClose}
        />
      )}
    </Modal>
  );
});
