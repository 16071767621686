import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Phosphor: FC<ISvgProps> = ({ width = '15', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 15 16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.7057 7.73167L8.17444 1.20041C8.05883 1.08378 7.91611 0.997615 7.75905 0.949626C7.602 0.901636 7.4355 0.893318 7.27444 0.925415L2.23069 1.93792C2.13505 1.95701 2.04721 2.00401 1.97824 2.07297C1.90928 2.14193 1.86229 2.22978 1.84319 2.32542L0.830688 7.36916C0.798591 7.53022 0.80691 7.69672 0.854899 7.85378C0.902889 8.01084 0.989053 8.15355 1.10569 8.26917L7.63694 14.8004C7.72934 14.8938 7.83934 14.9679 7.96056 15.0184C8.08179 15.069 8.21184 15.0951 8.34319 15.0951C8.47454 15.0951 8.60459 15.069 8.72581 15.0184C8.84704 14.9679 8.95703 14.8938 9.04944 14.8004L14.7057 9.14417C14.799 9.05176 14.8731 8.94176 14.9237 8.82054C14.9743 8.69931 15.0003 8.56927 15.0003 8.43791C15.0003 8.30656 14.9743 8.17652 14.9237 8.05529C14.8731 7.93407 14.799 7.82407 14.7057 7.73167ZM4.95569 5.80042C4.80735 5.80042 4.66235 5.75643 4.53901 5.67402C4.41567 5.59161 4.31954 5.47447 4.26278 5.33743C4.20601 5.20038 4.19116 5.04958 4.2201 4.9041C4.24904 4.75861 4.32047 4.62497 4.42536 4.52008C4.53025 4.4152 4.66388 4.34377 4.80937 4.31483C4.95486 4.28589 5.10566 4.30074 5.2427 4.35751C5.37975 4.41427 5.49688 4.5104 5.57929 4.63374C5.6617 4.75707 5.70569 4.90208 5.70569 5.05042C5.70569 5.24933 5.62667 5.44009 5.48602 5.58075C5.34537 5.7214 5.1546 5.80042 4.95569 5.80042Z'
      fill='#828585'
    />
  </svg>
);
export const PhosphorSVG = memo(Phosphor);
