import { FC } from 'react';
import './buynowSuccess.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { SmallCardInfo } from '../../SmallCardInfo/SmallCardInfo';
import { SuccessTitle } from '../../SuccessTitle/SuccessTitle';
import { IBuynowModalSuccessProps } from '../types';
import { OutsideLinkSVG } from '@/shared/assets/svg';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import { getRouteNFTCard } from '@/shared/const/router';
import { getItemShare } from '@/shared/lib/helpers/getItemsMenu';
import { limitWordCharacters } from '@/shared/lib/limitWordCharacters/limitWordCharacters';
import { IconButton, Button } from '@/shared/ui/Buttons';
import { HStack } from '@/shared/ui/Stack';

const BuynowSuccess: FC<IBuynowModalSuccessProps> = ({
  cardData,
  handleClose,
  transaction,
}) => {
  const { name, tokenId, frontImage } = cardData;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const socials = getItemShare(
    `Check out this item on Collector ${`${window.location.origin}/assets/solana/${tokenId}`} via @collector`,
    'hover-card',
    `${window.location.origin}/assets/solana/${tokenId}`,
  );
  const openCard = () => {
    if (pathname.includes('/assets/solana/')) handleClose();
    else navigate(getRouteNFTCard(tokenId));
  };
  return (
    <div className='buy-now-success'>
      <div className='buy-now-success__close-btn'>
        <IconButton size='32' onClick={() => handleClose()}>
          <CloseWhiteSVG />
        </IconButton>
      </div>
      <SuccessTitle text='Purchase completed successfully!' font='H4-CL' />
      <SmallCardInfo
        isTitleShort
        name={name}
        tokenId={tokenId}
        image={frontImage}
        handleModalClose={handleClose}
      />
      <div className='buy-now-success__transaction'>
        <div className='buy-now-success__transaction__text'>Transaction</div>
        <div className='buy-now-success__transaction__value'>
          <a href={transaction.transactionUrl} target='_blank' rel='noreferrer'>
            <HStack gap={0.25}>
              {limitWordCharacters(transaction.transactionId, 4, 'centerDots')}
              <OutsideLinkSVG style={{ cursor: 'pointer' }} />
            </HStack>
          </a>
        </div>
      </div>
      <div className='buy-now-success__share'>
        <div className='buy-now-success__share__title'>Share to...</div>
        <div className='buy-now-success__share__icons'>
          {!!socials &&
            socials.map(({ content: socialName, Icon, onClick }, index) => (
              <div
                key={`${socialName}-${index}`}
                className='buy-now-success__share__icons__item'
                onClick={onClick}
              >
                {Icon}
              </div>
            ))}
        </div>
      </div>
      <div className='buy-now-success__btn'>
        <Button
          fullWidth
          typeButton='secondary'
          text='go to card'
          size='medium'
          onClick={openCard}
        />
      </div>
    </div>
  );
};

export default BuynowSuccess;
