import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Refresh: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.95214 7.0626C10.9288 6.65828 12.0035 6.55271 13.0402 6.75925C14.0769 6.96579 15.0291 7.47517 15.7762 8.22294L15.7774 8.22407L16.7224 9.16635H15.3887C14.9227 9.16635 14.5449 9.54411 14.5449 10.0101C14.5449 10.4761 14.9227 10.8539 15.3887 10.8539H18.7538C18.798 10.8544 18.8422 10.8514 18.8861 10.845C18.9243 10.8395 18.9623 10.8313 18.9997 10.8204C19.1321 10.782 19.257 10.7105 19.3612 10.6059C19.4696 10.4972 19.5423 10.3662 19.5791 10.2278C19.5862 10.2011 19.5919 10.1742 19.5964 10.1471C19.6043 10.0986 19.608 10.0495 19.6074 10.0005V6.6351C19.6074 6.16911 19.2297 5.79135 18.7637 5.79135C18.2977 5.79135 17.9199 6.16911 17.9199 6.6351V7.97735L16.97 7.03019L16.9692 7.02942C15.9862 6.04593 14.7337 5.37597 13.3699 5.10428C12.0058 4.83251 10.5918 4.97142 9.30668 5.50343C8.02155 6.03544 6.92308 6.93665 6.15022 8.09305C5.37736 9.24946 4.96484 10.6091 4.96484 12C4.96484 13.3909 5.37736 14.7505 6.15022 15.907C6.92308 17.0634 8.02155 17.9646 9.30668 18.4966C10.5918 19.0286 12.0058 19.1675 13.3699 18.8957C14.734 18.624 15.9869 17.9537 16.97 16.9698C17.2993 16.6402 17.2991 16.1059 16.9695 15.7766C16.6398 15.4472 16.1056 15.4474 15.7762 15.7771C15.0291 16.5248 14.0769 17.0342 13.0402 17.2408C12.0035 17.4473 10.9288 17.3417 9.95214 16.9374C8.97544 16.5331 8.1406 15.8482 7.55323 14.9693C6.96586 14.0904 6.65234 13.0571 6.65234 12C6.65234 10.9429 6.96586 9.90959 7.55323 9.03072C8.1406 8.15185 8.97544 7.46693 9.95214 7.0626Z'
      fill='#939393'
    />
  </svg>
);

const RefreshSVG = memo(Refresh);
export default RefreshSVG;
