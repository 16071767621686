import { FC, memo } from 'react';
import { ISvgProps } from './types';

const ProfileAvatar: FC<ISvgProps> = ({
  width = '40',
  height = '40',
  style,
}) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox='0 0 40 40'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_3506_43015)'>
      <rect width={width} height={height} rx='20' fill='inherit' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 6.25C12.4061 6.25 6.25 12.4061 6.25 20C6.25 23.506 7.56221 26.7056 9.72231 29.1343C10.7061 27.7132 11.9775 26.5063 13.461 25.5958C14.0681 25.2231 14.7033 24.9046 15.3594 24.6423C13.6179 23.2689 12.5 21.14 12.5 18.75C12.5 14.6079 15.8579 11.25 20 11.25C24.1421 11.25 27.5 14.6079 27.5 18.75C27.5 21.14 26.3821 23.2689 24.6406 24.6423C25.2967 24.9046 25.9319 25.2231 26.539 25.5958C28.0225 26.5063 29.294 27.7132 30.2777 29.1343C32.4378 26.7056 33.75 23.506 33.75 20C33.75 12.4061 27.5939 6.25 20 6.25ZM30.9335 32.0219C34.1998 29.0496 36.25 24.7642 36.25 20C36.25 11.0254 28.9746 3.75 20 3.75C11.0254 3.75 3.75 11.0254 3.75 20C3.75 24.7643 5.80028 29.0497 9.06665 32.022C9.10819 32.0653 9.15318 32.1059 9.20148 32.1433C12.0722 34.698 15.8549 36.25 20 36.25C24.1451 36.25 27.9277 34.698 30.7984 32.1434C30.8468 32.1059 30.8919 32.0652 30.9335 32.0219ZM28.4273 30.8656C27.6153 29.5946 26.5239 28.5199 25.2312 27.7264C23.6837 26.7765 21.9073 26.2663 20.0928 26.2494C20.0619 26.2498 20.031 26.25 20 26.25C19.969 26.25 19.9381 26.2498 19.9072 26.2494C18.0927 26.2663 16.3163 26.7765 14.7688 27.7264C13.4762 28.5199 12.3847 29.5946 11.5727 30.8656C13.9004 32.6735 16.8245 33.75 20 33.75C23.1755 33.75 26.0996 32.6735 28.4273 30.8656ZM20.0848 23.7493C22.8071 23.704 25 21.4831 25 18.75C25 15.9886 22.7614 13.75 20 13.75C17.2386 13.75 15 15.9886 15 18.75C15 21.4831 17.1929 23.704 19.9152 23.7493C19.9435 23.7491 19.9717 23.749 20 23.749C20.0283 23.749 20.0565 23.7491 20.0848 23.7493Z'
        fill='inherit'
      />
    </g>
    <defs>
      <clipPath id='clip0_3506_43015'>
        <rect width={width} height={height} rx='20' fill='inherit' />
      </clipPath>
    </defs>
  </svg>
);
const ProfileAvatarSVG = memo(ProfileAvatar);
export default ProfileAvatarSVG;
