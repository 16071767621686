import { Component, ErrorInfo, ReactNode, Suspense } from 'react';
import { ServiceError } from '@/widgets/ServiceError';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}
export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    console.log('ErrorBoundary', error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError && environment.APP_ENV !== 'local')
      return (
        <Suspense fallback=''>
          <ServiceError />
        </Suspense>
      );

    return children;
  }
}
