import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/app/providers/StoreProvider';

export interface SendTransBody {
  tx: string;
}

export const sendTrans = createAsyncThunk<
  any,
  SendTransBody,
  ThunkConfig<string>
>('headers/setSendTransaction', async (props, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;
  try {
    const response = await extra.api.post<any>('/coin-transfer/send', props);
    if (!response.data) throw new Error();

    return response.data;
  } catch (e: unknown) {
    return rejectWithValue('Oops! Something went wrong...');
  }
});
