import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const FailedCross: FC<ISvgProps> = ({ width = '17', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.25 8C3.25 5.10051 5.60051 2.75 8.5 2.75C11.3995 2.75 13.75 5.10051 13.75 8C13.75 10.8995 11.3995 13.25 8.5 13.25C5.60051 13.25 3.25 10.8995 3.25 8ZM8.5 1.25C4.77208 1.25 1.75 4.27208 1.75 8C1.75 11.7279 4.77208 14.75 8.5 14.75C12.2279 14.75 15.25 11.7279 15.25 8C15.25 4.27208 12.2279 1.25 8.5 1.25ZM11.0303 5.46967C11.3232 5.76256 11.3232 6.23744 11.0303 6.53033L9.56066 8L11.0303 9.46967C11.3232 9.76256 11.3232 10.2374 11.0303 10.5303C10.7374 10.8232 10.2626 10.8232 9.96967 10.5303L8.5 9.06066L7.03033 10.5303C6.73744 10.8232 6.26256 10.8232 5.96967 10.5303C5.67678 10.2374 5.67678 9.76256 5.96967 9.46967L7.43934 8L5.96967 6.53033C5.67678 6.23744 5.67678 5.76256 5.96967 5.46967C6.26256 5.17678 6.73744 5.17678 7.03033 5.46967L8.5 6.93934L9.96967 5.46967C10.2626 5.17678 10.7374 5.17678 11.0303 5.46967Z'
      fill='#DC2223'
    />
  </svg>
);
const FailedCrossSVG = memo(FailedCross);
export default FailedCrossSVG;
