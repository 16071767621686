import { Dispatch, SetStateAction, memo, useCallback, useContext } from 'react';
import cls from './ConfirmSent.module.scss';
import {
  getFormAmount,
  getFormTo,
} from '../../model/selector/getFormData/getFormData';
import { SuccessSent } from '../SuccessSent/SuccessSent';
import { SendMoneyHeader } from '@/entities/Headers';
import EllipseSVG from '@/shared/assets/svg/HeaderPopover/Ellipse.svg';
import { classNames } from '@/shared/lib/classNames/classNames';
import { AuthProviderContext } from '@/shared/lib/context/AuthProviderContext';
import { sliceString } from '@/shared/lib/helpers/scliceStringFunction';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { Button } from '@/shared/ui/Buttons';
import { Spinner } from '@/shared/ui/Loaders';
import { HStack, VStack } from '@/shared/ui/Stack';

export interface ConfirmSentProps {
  className?: string;
  setOpenModal: () => void;
  setOpenConfirm: Dispatch<SetStateAction<boolean>>;
}
export const ConfirmSent = memo((props: ConfirmSentProps) => {
  const { className, setOpenConfirm, setOpenModal } = props;
  const dispatch = useAppDispatch();
  const valueTo = useAppSelector(getFormTo);
  const { sendTransaction, sendingIsLoading, successTrans, setSuccess } =
    useContext(AuthProviderContext);

  const amount = useAppSelector(getFormAmount);
  const isUSDC = localStorage.getItem('sendType') === 'USDC';

  const onCloseSuccess = useCallback(() => {
    setOpenModal();
    setSuccess?.(null);
  }, [setOpenModal, setSuccess]);
  const onSend = () => {
    sendTransaction?.(isUSDC, Number(amount), valueTo);
  };

  if (successTrans)
    return (
      <SuccessSent
        setOpenModal={onCloseSuccess}
        successTrans={successTrans}
        amount={amount}
        isUSDC={isUSDC}
      />
    );

  return (
    <VStack gap={1} className={classNames(cls.ConfirmSent, {}, [className])}>
      {!!sendingIsLoading && (
        <div className={classNames('', { [cls.loading]: sendingIsLoading })}>
          <div className={cls.spinner}>
            <Spinner style={{ width: '48px' }} />
          </div>
        </div>
      )}
      <SendMoneyHeader
        setOpen={setOpenConfirm}
        setOpenModal={setOpenModal}
        title='Confirm Send'
      />
      <HStack max justify='center'>
        <div className={cls.logo}>{!sendingIsLoading && <EllipseSVG />}</div>
      </HStack>
      <VStack max gap={1.25}>
        <HStack max className={cls.amount} justify='center'>
          {`${amount} ${isUSDC ? 'USDC' : 'SOL'}`}
        </HStack>
        <VStack max gap={0.25} className={cls.sendInfo}>
          <HStack max justify='between'>
            <span className={cls.name}>To</span>
            <span className={cls.value}>to {sliceString(valueTo || '')}</span>
          </HStack>
          <HStack max justify='between'>
            <span className={cls.name}>Network</span>
            <span className={cls.value}>Solana</span>
          </HStack>
          <HStack max justify='between'>
            <span className={cls.name}>Network fee</span>
            <span className={cls.value}>${amount}</span>
          </HStack>
        </VStack>
        <Button
          fullWidth
          typeButton='primary'
          text='Send'
          size='small'
          onClick={onSend}
        />
        <Button
          fullWidth
          typeButton='ghost'
          text='cancel'
          size='small'
          onClick={setOpenModal}
        />
      </VStack>
    </VStack>
  );
});
