import { FC, memo, useEffect, useState } from 'react';
import cls from './mainHeader.module.scss';
import { getAllCardInCart } from '@/entities/Cart';
import { BurnCartSVG } from '@/shared/assets/svg';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { HStack } from '@/shared/ui/Stack';

const BurnCartBlockUI: FC = () => {
  const userCart = useAppSelector(getAllCardInCart);

  const isCardsInCart = !!userCart?.cards?.length;
  const userCardsinCartTotal = userCart?.count;
  const [count, setCount] = useState(userCardsinCartTotal);

  useEffect(() => {
    setCount(userCardsinCartTotal);
  }, [userCart, userCardsinCartTotal]);

  return (
    <HStack align='center' gap={0.5} className={cls['burn-cart']}>
      <div
        className={classNames(
          cls.circle,
          { [cls['active-circle']]: isCardsInCart },
          [],
        )}
      >
        <BurnCartSVG />
      </div>
      <div
        className={classNames(
          cls.count,
          { [cls['active-count']]: isCardsInCart },
          [],
        )}
      >
        {count}
      </div>
    </HStack>
  );
};
const BurnCartBlock = memo(BurnCartBlockUI);
export default BurnCartBlock;
