import { FC, memo } from 'react';
import { ISvgProps } from './types';

const Copy: FC<ISvgProps> = ({ width = '15', height = '14' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 15 14'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.605005 1.5288C0.299805 2.128 0.299805 2.912 0.299805 4.48V5.32C0.299805 6.888 0.299805 7.672 0.605005 8.2712C0.873446 8.79803 1.30177 9.22636 1.8286 9.4948C2.4278 9.8 3.2118 9.8 4.7798 9.8H5.6198C7.1878 9.8 7.9718 9.8 8.571 9.4948C9.09784 9.22636 9.52616 8.79803 9.7946 8.2712C10.0998 7.672 10.0998 6.888 10.0998 5.32V4.48C10.0998 2.912 10.0998 2.128 9.7946 1.5288C9.52616 1.00197 9.09784 0.573641 8.571 0.3052C7.9718 8.9407e-08 7.1878 0 5.6198 0H4.7798C3.2118 0 2.4278 8.9407e-08 1.8286 0.3052C1.30177 0.573641 0.873446 1.00197 0.605005 1.5288ZM14.2998 7.3108V9.52C14.2998 11.088 14.2998 11.872 13.9946 12.4712C13.7262 12.998 13.2978 13.4264 12.771 13.6948C12.1718 14 11.3878 14 9.81981 14H7.5133C7.3152 14 7.2158 14 7.1325 13.9951C6.44904 13.9542 5.80424 13.6643 5.32003 13.1803C4.83582 12.6962 4.54576 12.0515 4.5047 11.368C4.4998 11.284 4.4998 11.1839 4.4998 10.9858L4.5005 10.9389C4.50574 10.8463 4.54754 10.7595 4.61671 10.6977C4.68589 10.6359 4.77677 10.604 4.8694 10.6092L5.3034 10.6008C7.2305 10.5658 8.1944 10.5476 8.9294 10.1612C9.5757 9.82126 10.0995 9.28768 10.4274 8.6352C10.7998 7.8939 10.7998 6.93 10.7998 5.0029V4.5892C10.7998 4.5528 10.7998 4.5346 10.8012 4.5192C10.8084 4.43735 10.8443 4.36065 10.9024 4.30255C10.9605 4.24445 11.0372 4.20864 11.119 4.2014C11.1344 4.2 11.1526 4.2 11.189 4.2C11.4774 4.2 11.6223 4.2 11.7441 4.2105C12.3999 4.26789 13.0144 4.55448 13.4799 5.01994C13.9453 5.48539 14.2319 6.09995 14.2893 6.7557C14.2998 6.8775 14.2998 7.021 14.2998 7.3108Z'
      fill='#FF7C01'
    />
  </svg>
);
const CopySVG = memo(Copy);
export default CopySVG;
