import { FC, memo } from 'react';
import { ISvgProps } from './types';

const Edit: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.9694 8.69251C18.901 8.5211 18.7976 8.36589 18.6656 8.23689L15.5325 5.10376C15.4002 4.9744 15.2459 4.86964 15.0769 4.79439C14.7272 4.65122 14.3353 4.65122 13.9856 4.79439C13.8175 4.86834 13.6649 4.9733 13.5356 5.10376L5.09813 13.5413C4.97024 13.6724 4.869 13.8272 4.8 13.9969C4.72603 14.1653 4.68772 14.3473 4.6875 14.5313V17.6756C4.68898 18.0481 4.83761 18.405 5.10102 18.6684C5.36442 18.9318 5.72125 19.0804 6.09375 19.0819H9.23813C9.60939 19.0811 9.96527 18.9335 10.2281 18.6713L18.6656 10.2338C18.7982 10.1019 18.9033 9.94516 18.975 9.77251C19.0491 9.60115 19.0874 9.41641 19.0874 9.2297C19.0874 9.04298 19.0491 8.85825 18.975 8.68689L18.9694 8.69251ZM9.12 17.3944H6.375V14.6494L12.2812 8.74314L15.0262 11.4881L9.12 17.3944ZM16.2188 10.3125L13.4737 7.55064L14.5312 6.49314L17.2762 9.23814L16.2188 10.3125Z'
      fill='inherit'
    />
  </svg>
);
const EditSVG = memo(Edit);
export default EditSVG;
