import { SolanaSVG } from '@/shared/assets/svg';
import { USDCSVG } from '@/shared/assets/svg/OutboundShipment';

export const convertNumberInK = (
  num: number | string,
  roundUp: 'roundUp' | 'notRound' | undefined = 'notRound',
  currency: ICurrency | undefined = 'USD',
  iconPosition: 'left' | 'right' | undefined = 'left',
  showCurrencyLabel: boolean | undefined = true,
  isshowIcon: boolean | undefined = true,
  minimumFractionDigits: number | undefined = 0,
) => {
  if (Number.isNaN(+num)) return num;

  const isNoRound = +num < 1e5;

  if (minimumFractionDigits !== 0)
    if (+num < 0.01) minimumFractionDigits = 3;
    else if (+num < 1) minimumFractionDigits = 2;
    else if (+num < 10) minimumFractionDigits = 1;
    else minimumFractionDigits = 0;

  const en = new Intl.NumberFormat('en-US', {
    currency: currency === 'USDC' ? 'USD' : currency,
    minimumFractionDigits,
    style: 'currency',
  });

  let formattedNum;

  if (currency === 'SOL') formattedNum = en.format(+num).replace(/[SOL]/g, '');
  else if (currency === 'USDC') formattedNum = en.format(+num).replace('$', '');
  else if (roundUp === 'roundUp' && !isNoRound) {
    const enRoundUp = new Intl.NumberFormat('en-US', {
      compactDisplay: 'short',
      currency: currency === 'USDC' ? 'USD' : currency,
      minimumFractionDigits,
      notation: 'compact',
      style: 'currency',
    });

    formattedNum = enRoundUp.format(+num).replace('T', 'K').replace('$', '');

    if (parseInt(formattedNum) >= 100)
      return formattedNum.slice(0, 4) + formattedNum.slice(-1);
    if (parseInt(formattedNum) >= 10)
      return formattedNum.slice(0, 4) + formattedNum.slice(-1);
    if (parseInt(formattedNum) >= 1)
      return formattedNum.slice(0, 4) + formattedNum.slice(-1);
  } else formattedNum = en.format(+num).replace(/[$SOL]/g, '');

  const renderIcon = (IconComponent: React.ElementType) => (
    <IconComponent width='18' height='18' />
  );

  if (currency === 'SOL')
    return (
      <>
        {iconPosition === 'left' && !!isshowIcon && renderIcon(SolanaSVG)}&nbsp;
        {formattedNum} {showCurrencyLabel ? ' SOL' : ''}{' '}
        {iconPosition === 'right' && !!isshowIcon && (
          <>&nbsp;{renderIcon(SolanaSVG)}</>
        )}
      </>
    );

  if (currency === 'USDC')
    return (
      <>
        {iconPosition === 'left' && !!isshowIcon && renderIcon(USDCSVG)}&nbsp;
        {formattedNum}
        {showCurrencyLabel ? ' USDC' : ''}{' '}
        {iconPosition === 'right' && !!isshowIcon && (
          <>&nbsp;{renderIcon(USDCSVG)}</>
        )}
      </>
    );

  return formattedNum;
};
