import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Location: FC<ISvgProps> = ({ width = '16', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ margin: '0.5rem 0' }}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.81802 3.31802C5.66193 2.47411 6.80653 2 8 2C9.19347 2 10.3381 2.47411 11.182 3.31802C12.0259 4.16193 12.5 5.30653 12.5 6.5C12.5 8.56997 11.3441 10.4548 10.1227 11.8594C9.51812 12.5547 8.91225 13.1152 8.45743 13.5018C8.27755 13.6547 8.12188 13.7799 8 13.875C7.87812 13.7799 7.72245 13.6547 7.54257 13.5018C7.08775 13.1152 6.48189 12.5547 5.8773 11.8594C4.65592 10.4548 3.5 8.56997 3.5 6.5C3.5 5.30653 3.97411 4.16193 4.81802 3.31802ZM7.71297 14.9094C7.71313 14.9095 7.71327 14.9096 8 14.5L7.71327 14.9096C7.88543 15.0301 8.11457 15.0301 8.28673 14.9096L8 14.5C8.28673 14.9096 8.28687 14.9095 8.28703 14.9094L8.28744 14.9091L8.28861 14.9083L8.29231 14.9057L8.30502 14.8966C8.31583 14.8889 8.33124 14.8778 8.35096 14.8634C8.39038 14.8347 8.44704 14.7927 8.51846 14.7381C8.66126 14.6289 8.86339 14.4692 9.10507 14.2638C9.58775 13.8535 10.2319 13.2578 10.8773 12.5156C12.1559 11.0452 13.5 8.93003 13.5 6.5C13.5 5.04131 12.9205 3.64236 11.8891 2.61091C10.8576 1.57946 9.45869 1 8 1C6.54131 1 5.14236 1.57946 4.11091 2.61091C3.07946 3.64236 2.5 5.04131 2.5 6.5C2.5 8.93003 3.84408 11.0452 5.1227 12.5156C5.76811 13.2578 6.41225 13.8535 6.89493 14.2638C7.13661 14.4692 7.33874 14.6289 7.48154 14.7381C7.55296 14.7927 7.60962 14.8347 7.64904 14.8634C7.66876 14.8778 7.68417 14.8889 7.69498 14.8966L7.70769 14.9057L7.71139 14.9083L7.71256 14.9091L7.71297 14.9094ZM6.5 6.5C6.5 5.67157 7.17157 5 8 5C8.82843 5 9.5 5.67157 9.5 6.5C9.5 7.32843 8.82843 8 8 8C7.17157 8 6.5 7.32843 6.5 6.5ZM8 4C6.61929 4 5.5 5.11929 5.5 6.5C5.5 7.88071 6.61929 9 8 9C9.38071 9 10.5 7.88071 10.5 6.5C10.5 5.11929 9.38071 4 8 4Z'
      fill='#FF9900'
    />
  </svg>
);
const LocationSVG = memo(Location);
export default LocationSVG;
