import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const SortIcon: FC<ISvgProps> = ({ width = '20', height = '20' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.4697 5.03033C11.7626 5.32322 12.2374 5.32322 12.5303 5.03033L13.25 4.31066L13.25 14.5C13.25 14.9142 13.5858 15.25 14 15.25C14.4142 15.25 14.75 14.9142 14.75 14.5L14.75 4.31066L15.4697 5.03033C15.7626 5.32322 16.2374 5.32322 16.5303 5.03033C16.8232 4.73744 16.8232 4.26256 16.5303 3.96967L14.5303 1.96967C14.2374 1.67678 13.7626 1.67678 13.4697 1.96967L11.4697 3.96967C11.1768 4.26256 11.1768 4.73744 11.4697 5.03033Z'
      fill='#828585'
    />
    <path
      d='M6.75 14.6893L7.46967 13.9697C7.76256 13.6768 8.23744 13.6768 8.53033 13.9697C8.82322 14.2626 8.82322 14.7374 8.53033 15.0303L6.53033 17.0303C6.23744 17.3232 5.76256 17.3232 5.46967 17.0303L3.46967 15.0303C3.17678 14.7374 3.17678 14.2626 3.46967 13.9697C3.76256 13.6768 4.23744 13.6768 4.53033 13.9697L5.25 14.6893L5.25 4.5C5.25 4.08579 5.58579 3.75 6 3.75C6.41421 3.75 6.75 4.08579 6.75 4.5L6.75 14.6893Z'
      fill='#828585'
    />
  </svg>
);

const SortIconSVG = memo(SortIcon);
export default SortIconSVG;
