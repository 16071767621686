import { FC, memo } from 'react';
import { ISvgProps } from './types';

const Info: FC<ISvgProps> = ({ width = '22', height = '22', fill }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 22 22'
    fill={fill || 'inherit'}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 3.125C6.65076 3.125 3.125 6.65076 3.125 11C3.125 15.3492 6.65076 18.875 11 18.875C15.3492 18.875 18.875 15.3492 18.875 11C18.875 6.65076 15.3492 3.125 11 3.125ZM0.875 11C0.875 5.40812 5.40812 0.875 11 0.875C16.5919 0.875 21.125 5.40812 21.125 11C21.125 16.5919 16.5919 21.125 11 21.125C5.40812 21.125 0.875 16.5919 0.875 11ZM9.125 10.625C9.125 10.0037 9.62868 9.5 10.25 9.5H11C11.6213 9.5 12.125 10.0037 12.125 10.625V14.439C12.5619 14.5935 12.875 15.0102 12.875 15.5C12.875 16.1213 12.3713 16.625 11.75 16.625H11C10.3787 16.625 9.875 16.1213 9.875 15.5V11.686C9.43805 11.5315 9.125 11.1148 9.125 10.625ZM12.3125 6.875C12.3125 7.70343 11.6409 8.375 10.8125 8.375C9.98407 8.375 9.3125 7.70343 9.3125 6.875C9.3125 6.04657 9.98407 5.375 10.8125 5.375C11.6409 5.375 12.3125 6.04657 12.3125 6.875Z'
      fill={fill || 'inherit'}
    />
  </svg>
);
const InfoSVG = memo(Info);
export default InfoSVG;
