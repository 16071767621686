import { FC, memo } from 'react';
import { ISvgProps } from './types';

const MintedStatus: FC<ISvgProps> = ({ width = '15', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 15 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.5 1.90625C6.29477 1.90625 5.11661 2.26364 4.1145 2.93323C3.11238 3.60282 2.33133 4.55454 1.87011 5.66802C1.40889 6.78151 1.28821 8.00676 1.52334 9.18883C1.75847 10.3709 2.33884 11.4567 3.19107 12.3089C4.0433 13.1612 5.1291 13.7415 6.31117 13.9767C7.49324 14.2118 8.71849 14.0911 9.83198 13.6299C10.9455 13.1687 11.8972 12.3876 12.5668 11.3855C13.2364 10.3834 13.5938 9.20523 13.5938 8C13.5907 6.38479 12.9476 4.83662 11.8055 3.69449C10.6634 2.55236 9.11521 1.90935 7.5 1.90625V1.90625ZM10.4004 6.93359L6.9668 10.2148C6.87803 10.2983 6.76054 10.3445 6.63867 10.3438C6.57911 10.3446 6.51996 10.3337 6.46465 10.3115C6.40934 10.2894 6.35896 10.2565 6.31641 10.2148L4.59961 8.57422C4.55199 8.53267 4.51327 8.48193 4.48576 8.42504C4.45826 8.36815 4.44254 8.30628 4.43955 8.24316C4.43656 8.18003 4.44637 8.11696 4.46838 8.05772C4.4904 7.99849 4.52416 7.94431 4.56764 7.89846C4.61112 7.85261 4.66343 7.81602 4.72141 7.79089C4.7794 7.76577 4.84186 7.75263 4.90505 7.75226C4.96825 7.75189 5.03086 7.76431 5.08913 7.78876C5.1474 7.81321 5.20013 7.84918 5.24414 7.89453L6.63867 9.22461L9.75586 6.25391C9.84701 6.17439 9.96536 6.13318 10.0862 6.1389C10.207 6.14461 10.3209 6.19681 10.4042 6.28458C10.4874 6.37235 10.5335 6.48889 10.5328 6.60984C10.5321 6.7308 10.4846 6.8468 10.4004 6.93359Z'
      fill='#2EF098'
    />
  </svg>
);
const MintedStatusSVG = memo(MintedStatus);
export default MintedStatusSVG;
