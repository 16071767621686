import { FC, memo } from 'react';
import { ISvgProps } from './types';

const Offer: FC<ISvgProps> = ({ width = '32', height = '32' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.2571 20.9412C9.28069 20.9412 6.05714 23.983 6.05714 27.7353V29.0294C6.05714 29.5655 5.59664 30 5.02857 30C4.46051 30 4 29.5655 4 29.0294V27.7353C4 22.9109 8.14456 19 13.2571 19H18.7429C23.8554 19 28 22.9109 28 27.7353V29.0294C28 29.5655 27.5395 30 26.9714 30C26.4034 30 25.9429 29.5655 25.9429 29.0294V27.7353C25.9429 23.983 22.7193 20.9412 18.7429 20.9412H13.2571Z'
      fill='#FF7C01'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 6.05263C13.5438 6.05263 11.5526 8.04379 11.5526 10.5C11.5526 12.9562 13.5438 14.9474 16 14.9474C18.4562 14.9474 20.4474 12.9562 20.4474 10.5C20.4474 8.04379 18.4562 6.05263 16 6.05263ZM9.5 10.5C9.5 6.91015 12.4101 4 16 4C19.5898 4 22.5 6.91014 22.5 10.5C22.5 14.0899 19.5898 17 16 17C12.4101 17 9.5 14.0899 9.5 10.5Z'
      fill='#FF7C01'
    />
  </svg>
);
const OfferSVG = memo(Offer);
export default OfferSVG;
