import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAllUserCardsReload extends IAllUserCards {
  clear?: 'clear';
}

export interface ICardInitialStateMP {
  isLoadingMarketPlaceCards: boolean;
  isLoadingOffers: boolean;
  isLoadingListings: boolean;
  allMarketPlaceCards: IAllUserCardsReload;
  offers: {
    NFTOffers: IOffer[];
    sellerOffers: IBuyerOffer[];
    buyerOffers: IBuyerOffer[];
  };
  listings: IListing[];
}

const initialState: ICardInitialStateMP = {
  allMarketPlaceCards: {
    cardsQtyByCategory: {},
    filterNFtCard: [],
    findTotal: 0,
    total: 0,
    totalCardsCoast: '0',
    totalPages: 1,
  },
  isLoadingListings: false,
  isLoadingMarketPlaceCards: false,
  isLoadingOffers: false,
  listings: [],
  offers: {
    NFTOffers: [],
    buyerOffers: [],
    sellerOffers: [],
  },
};

export const marketPlaceSlice = createSlice({
  initialState,
  name: 'marketplace',
  reducers: {
    clearAllMarketPlaceCardsSlice: state => {
      state.allMarketPlaceCards.filterNFtCard = [];
    },
    clearListingsSlice: state => {
      state.listings = [];
    },
    clearOffersBuyerSlice: state => {
      state.offers.buyerOffers = [];
    },
    clearOffersSellerSlice: state => {
      state.offers.sellerOffers = [];
    },
    clearOffersSlice: state => {
      state.offers = {
        NFTOffers: [],
        buyerOffers: [],
        sellerOffers: [],
      };
    },
    setBuyerOffers: (state, { payload }: PayloadAction<IBuyerOffer[]>) => {
      state.offers.buyerOffers = payload;
    },
    setListings: (state, { payload }: PayloadAction<IListing[]>) => {
      state.listings = payload;
    },
    setLoadingListings: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingListings = payload;
    },
    setLoadingMarketPlaceCardsSlice: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isLoadingMarketPlaceCards = payload;
    },
    setLoadingOffers: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingOffers = payload;
    },
    setMarketPlaceCardsSlice: (
      state,
      { payload }: PayloadAction<IAllUserCardsReload>,
    ) => {
      const copyArray: ICard[] = [
        ...state.allMarketPlaceCards.filterNFtCard,
      ].concat(payload.filterNFtCard);
      state.allMarketPlaceCards.filterNFtCard =
        payload.clear === 'clear' ? payload.filterNFtCard : copyArray;
      state.allMarketPlaceCards.cardsQtyByCategory = payload.cardsQtyByCategory;
      state.allMarketPlaceCards.total = payload.total;
      state.allMarketPlaceCards.findTotal = payload.findTotal;
      state.allMarketPlaceCards.totalCardsCoast = payload.totalCardsCoast;
      state.allMarketPlaceCards.totalPages = payload.totalPages
        ? payload.totalPages
        : 1;
    },
    setNFTOffers: (state, { payload }: PayloadAction<IOffer[]>) => {
      state.offers.NFTOffers = payload;
    },
    setResetAllMarketPlaceCardsSlice: () => initialState,
    setSellerOffers: (state, { payload }: PayloadAction<IBuyerOffer[]>) => {
      state.offers.sellerOffers = payload;
    },
  },
});

export const {
  setLoadingMarketPlaceCardsSlice,
  setLoadingOffers,
  setLoadingListings,
  setNFTOffers,
  setBuyerOffers,
  setSellerOffers,
  setListings,
  clearOffersSlice,
  clearOffersBuyerSlice,
  clearOffersSellerSlice,
  setMarketPlaceCardsSlice,
  clearAllMarketPlaceCardsSlice,
  clearListingsSlice,
  setResetAllMarketPlaceCardsSlice,
} = marketPlaceSlice.actions;

export const { reducer: marketplaceReducer } = marketPlaceSlice;
