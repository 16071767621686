import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const USDT: FC<ISvgProps> = ({ width = '16', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_3348_40851)'>
      <path
        d='M15.7589 9.93524C14.6904 14.221 10.3496 16.8292 6.06336 15.7605C1.77889 14.692 -0.829339 10.351 0.239588 6.06558C1.30758 1.77935 5.64835 -0.82907 9.93329 0.239387C14.2192 1.30785 16.8273 5.64936 15.7587 9.93532L15.7588 9.93524H15.7589Z'
        fill='#50AF95'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.01196 8.90691V8.90691C8.95539 8.91115 8.66313 8.92859 8.01119 8.92859C7.49266 8.92859 7.1245 8.91304 6.99534 8.90691V8.90691C4.99144 8.81876 3.49571 8.46993 3.49571 8.05227C3.49571 7.63462 4.99144 7.28626 6.99534 7.19669V8.55949C7.12638 8.56892 7.50161 8.59108 8.02015 8.59108C8.64238 8.59108 8.95398 8.56515 9.01007 8.55996V7.19764C11.0097 7.28673 12.5022 7.63556 12.5022 8.05227C12.5022 8.46898 11.0102 8.81782 9.01007 8.90644V8.90644L9.01196 8.90691ZM9.01195 7.05668V5.83719H11.8026V3.97754H4.20468V5.83719H6.99486V7.05621C4.72699 7.16039 3.02148 7.60963 3.02148 8.14796C3.02148 8.68629 4.72699 9.13506 6.99486 9.23971V13.1476H9.01148V9.23829C11.2742 9.13411 12.9768 8.68535 12.9768 8.14749C12.9768 7.60963 11.2756 7.16086 9.01148 7.05621V7.05621L9.01195 7.05668Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_3348_40851'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
const USDTSVG = memo(USDT);
export default USDTSVG;
