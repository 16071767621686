export const dateToValidForm = (createdAt: string) => {
  const monthes = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let d: Date | string;

  if (typeof createdAt === 'string' && createdAt !== '')
    d = new Date(createdAt);
  else if (createdAt) d = new Date(createdAt * 1000);
  else {
    d = '--';
    return d;
  }

  return `${d.getDate()} ${
    monthes[d.getMonth()]
  } ${d.getFullYear()} ${d.toLocaleString('en-US', {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
  })}`;
};
