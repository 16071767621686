import { useEffect } from 'react';
import cls from './TransferSuccess.module.scss';
import { SuccessTitle } from '../../..';
import { OutsideLinkSVG } from '@/shared/assets/svg';
import { getRoutePublicAccount } from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { limitWordCharacters } from '@/shared/lib/limitWordCharacters/limitWordCharacters';
import { VStack } from '@/shared/ui/Stack';

export interface TransferSuccessProps {
  className?: string;
  isByWallet: boolean;
  wallet: string;
  responseWallet: string;
  email: string;
  getNFTCard: (withLoading: boolean) => void;
}

export const TransferSuccess = (props: TransferSuccessProps) => {
  const { className, isByWallet, wallet, responseWallet, email, getNFTCard } =
    props;

  useEffect(
    () => () => {
      getNFTCard(false);
    },
    [getNFTCard],
  );

  return (
    <VStack
      className={classNames(cls.SealedBoxUpdateListingSuccess, {}, [className])}
    >
      <SuccessTitle
        text='Your transfer is completed!'
        font='H3'
        type='success'
      />
      <div className={cls.description}>
        Your pNFTs sent to
        <a
          href={getRoutePublicAccount(responseWallet)}
          className={cls.link}
          style={{ cursor: 'pointer' }}
        >
          <span className={cls.linkValue}>
            {isByWallet ? limitWordCharacters(wallet, 6, 'centerDots') : email}
          </span>
          <OutsideLinkSVG />
        </a>
      </div>
    </VStack>
  );
};
