import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const SuccessMinted: FC<ISvgProps> = ({
  width = '40',
  height = '40',
  fill = '#5BEAFF',
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20 0.5C16.1433 0.5 12.3731 1.64366 9.16639 3.78634C5.95963 5.92903 3.46027 8.97451 1.98436 12.5377C0.508449 16.1008 0.122284 20.0216 0.874696 23.8043C1.62711 27.5869 3.4843 31.0615 6.21143 33.7886C8.93855 36.5157 12.4131 38.3729 16.1957 39.1253C19.9784 39.8777 23.8992 39.4916 27.4623 38.0156C31.0255 36.5397 34.071 34.0404 36.2137 30.8336C38.3564 27.6269 39.5 23.8567 39.5 20C39.4901 14.8313 37.4325 9.87718 33.7776 6.22237C30.1228 2.56755 25.1687 0.509911 20 0.5V0.5ZM29.2813 16.5875L18.2938 27.0875C18.0097 27.3547 17.6337 27.5024 17.2438 27.5C17.0531 27.5027 16.8639 27.4677 16.6869 27.3969C16.5099 27.3261 16.3487 27.2209 16.2125 27.0875L10.7188 21.8375C10.5664 21.7046 10.4425 21.5422 10.3544 21.3601C10.2664 21.1781 10.2161 20.9801 10.2066 20.7781C10.197 20.5761 10.2284 20.3743 10.2988 20.1847C10.3693 19.9952 10.4773 19.8218 10.6164 19.6751C10.7556 19.5283 10.923 19.4113 11.1085 19.3309C11.2941 19.2505 11.494 19.2084 11.6962 19.2072C11.8984 19.2061 12.0988 19.2458 12.2852 19.324C12.4717 19.4023 12.6404 19.5174 12.7813 19.6625L17.2438 23.9187L27.2188 14.4125C27.5104 14.158 27.8891 14.0262 28.2758 14.0445C28.6624 14.0628 29.027 14.2298 29.2933 14.5107C29.5597 14.7915 29.7071 15.1644 29.7049 15.5515C29.7026 15.9386 29.5508 16.3098 29.2813 16.5875Z'
      fill={fill}
    />
  </svg>
);
const SuccessMintedSVG = memo(SuccessMinted);
export default SuccessMintedSVG;
