import { memo, useCallback, useMemo } from 'react';
import cls from './Form.module.scss';
import SolanaSmall from '@/shared/assets/svg/HeaderPopover/SolanaSmall.svg';
import USDCSmall from '@/shared/assets/svg/HeaderPopover/USDCSmall.svg';
import { classNames } from '@/shared/lib/classNames/classNames';

export interface InputIconProps {
  className?: string;
  value?: string;
}
export const InputIcon = memo((props: InputIconProps) => {
  const { className, value } = props;
  const isUSDC = localStorage.getItem('sendType') === 'USDC';

  const Icon = useCallback(() => {
    if (isUSDC) return <USDCSmall />;
    return <SolanaSmall className={cls.sol} />;
  }, [isUSDC]);

  const currentTitle = useMemo(() => {
    if (value) return value;
    return isUSDC ? 'USDC' : 'SOL';
  }, [isUSDC, value]);

  return (
    <div className={classNames(cls.InputIcon, {}, [className])}>
      <Icon />
      <div className={cls.name}>{currentTitle}</div>
    </div>
  );
});
