import { FC, memo } from 'react';
import { ISvgProps } from './types';

const ExclamationPoint: FC<ISvgProps> = ({ width = '40', height = '40' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M38.6188 11.0375L28.9625 1.38125C28.3998 0.820058 27.6385 0.503404 26.8438 0.5H13.1562C12.3615 0.503404 11.6002 0.820058 11.0375 1.38125L1.38125 11.0375C0.820058 11.6002 0.503404 12.3615 0.5 13.1562V26.8438C0.503404 27.6385 0.820058 28.3998 1.38125 28.9625L11.0375 38.6188C11.6002 39.1799 12.3615 39.4966 13.1562 39.5H26.8438C27.6385 39.4966 28.3998 39.1799 28.9625 38.6188L38.6188 28.9625C39.1799 28.3998 39.4966 27.6385 39.5 26.8438V13.1562C39.4966 12.3615 39.1799 11.6002 38.6188 11.0375ZM18.5 11C18.5 10.6022 18.658 10.2206 18.9393 9.93934C19.2206 9.65804 19.6022 9.5 20 9.5C20.3978 9.5 20.7794 9.65804 21.0607 9.93934C21.342 10.2206 21.5 10.6022 21.5 11V21.5C21.5 21.8978 21.342 22.2794 21.0607 22.5607C20.7794 22.842 20.3978 23 20 23C19.6022 23 19.2206 22.842 18.9393 22.5607C18.658 22.2794 18.5 21.8978 18.5 21.5V11ZM20 30.5C19.555 30.5 19.12 30.368 18.75 30.1208C18.38 29.8736 18.0916 29.5222 17.9213 29.111C17.751 28.6999 17.7064 28.2475 17.7932 27.811C17.88 27.3746 18.0943 26.9737 18.409 26.659C18.7237 26.3443 19.1246 26.13 19.561 26.0432C19.9975 25.9564 20.4499 26.001 20.861 26.1713C21.2722 26.3416 21.6236 26.63 21.8708 27C22.118 27.37 22.25 27.805 22.25 28.25C22.25 28.8467 22.0129 29.419 21.591 29.841C21.169 30.2629 20.5967 30.5 20 30.5Z'
      fill='#FF4445'
    />
  </svg>
);
const ExclamationPointSVG = memo(ExclamationPoint);
export default ExclamationPointSVG;
