import { FC, memo } from 'react';
import { ISvgProps } from './types';

const Avatar: FC<ISvgProps> = ({ width = '52', height = '52' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 52 52'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.33337 13C4.33337 10.7015 5.24647 8.4971 6.87178 6.87178C8.4971 5.24647 10.7015 4.33337 13 4.33337H39C41.2986 4.33337 43.503 5.24647 45.1283 6.87178C46.7536 8.4971 47.6667 10.7015 47.6667 13V39C47.6667 41.2986 46.7536 43.503 45.1283 45.1283C43.503 46.7536 41.2986 47.6667 39 47.6667H13C10.7015 47.6667 8.4971 46.7536 6.87178 45.1283C5.24647 43.503 4.33337 41.2986 4.33337 39V13Z'
      stroke='#3D3D3D'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.4167 23.8333C21.4082 23.8333 23.8333 21.4082 23.8333 18.4167C23.8333 15.4251 21.4082 13 18.4167 13C15.4251 13 13 15.4251 13 18.4167C13 21.4082 15.4251 23.8333 18.4167 23.8333Z'
      stroke='#3D3D3D'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M31.473 27.3454L13 47.6666H39.2882C41.5103 47.6666 43.6414 46.7839 45.2127 45.2126C46.7839 43.6413 47.6667 41.5102 47.6667 39.2881V38.9999C47.6667 37.9903 47.2875 37.6024 46.605 36.8549L37.8733 27.3324C37.4664 26.8885 36.9714 26.5343 36.4199 26.2924C35.8684 26.0505 35.2725 25.9262 34.6703 25.9274C34.0681 25.9286 33.4727 26.0553 32.9222 26.2995C32.3717 26.5437 31.8781 26.8999 31.473 27.3454V27.3454Z'
      stroke='#3D3D3D'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
const AvatarSVG = memo(Avatar);
export default AvatarSVG;
