import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Print: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.67715 8.5751L6.60122 8.35999V4.37178C6.60122 3.76426 7.06134 3.25676 7.66277 3.20092C10.5481 2.93303 13.4518 2.93303 16.3372 3.20092C16.9386 3.25676 17.3987 3.76426 17.3987 4.37178V8.35999L19.3228 8.5751C19.9447 8.64462 20.4577 9.09634 20.6085 9.70713C21.1305 11.8219 21.1305 14.033 20.6085 16.1478L20.4651 16.7288C20.3962 17.0076 20.1474 17.2033 19.8618 17.2033H17.3987V19.6427C17.3987 20.2302 16.9562 20.7221 16.375 20.7805C13.4656 21.0732 10.5344 21.0732 7.62493 20.7805C7.04378 20.7221 6.60122 20.2302 6.60122 19.6427V17.2033H4.13821C3.85261 17.2033 3.60375 17.0076 3.53493 16.7288L3.39151 16.1478C2.8695 14.033 2.8695 11.8219 3.39151 9.70713C3.54228 9.09634 4.0553 8.64462 4.67715 8.5751ZM8.00959 8.22248C10.6653 8.00237 13.3346 8.00236 15.9904 8.22248V4.59187C13.3352 4.35818 10.6648 4.35818 8.00959 4.59187V8.22248ZM8.00959 15.9563V19.3948C10.664 19.6463 13.336 19.6463 15.9904 19.3948V15.9563C13.336 15.7048 10.664 15.7048 8.00959 15.9563ZM17.3987 11.0647C16.7506 11.0647 16.2251 11.5933 16.2251 12.2452C16.2251 12.8972 16.7506 13.4257 17.3987 13.4257C18.0469 13.4257 18.5724 12.8972 18.5724 12.2452C18.5724 11.5933 18.0469 11.0647 17.3987 11.0647Z'
      fill='#828585'
    />
  </svg>
);
const PrintSVG = memo(Print);
export default PrintSVG;
