import { FC, memo } from 'react';
import { downloadPDFOutboundShipment } from '../../helpers';
import { IInvoiceButtonProps } from '../types';
import DownloadSVG from '@/shared/assets/svg/buttons/DownloadSVG';
import { Button } from '@/shared/ui/Buttons';

export const InvoiceButton: FC<IInvoiceButtonProps> = memo(({ shipment }) => (
  <div className='invoice-button'>
    <Button
      typeButton='secondary'
      size='small'
      img={DownloadSVG}
      text='Invoice'
      onClick={() => downloadPDFOutboundShipment(shipment)}
    />
  </div>
));
