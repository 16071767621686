import {
  SuccessFaceSVG,
  FrozenFaceSVG,
  BurnedSVG,
  InvalidFaceSVG,
  SuccessMintedSVG,
  ReadyForMintSVG,
  RejectedSVG,
} from '@/shared/assets/svg/VerifyCardStatusIcon';

export const selectVerifyCardContent = {
  icon: (status: string) => {
    switch (status) {
      case 'Valid':
        return <SuccessFaceSVG />;
      case 'Frozen':
        return <FrozenFaceSVG />;
      case 'Invalid':
        return <InvalidFaceSVG />;
      case 'Burned':
        return <BurnedSVG />;
      case 'Minted':
        return <ReadyForMintSVG />;
      case 'Transferred':
        return <SuccessMintedSVG />;
      case 'Rejected':
        return <RejectedSVG />;
      case 'RequestedBack':
        return <RejectedSVG />;
      default:
        return null;
    }
  },
  text: (status: string) => {
    switch (status) {
      case 'Valid':
        return 'This pNFT represents a valid underlying asset held securely by one of our vaulting partners.';
      case 'Frozen':
        return 'This card exists  in Collector but is not available for any action';
      case 'Invalid':
        return 'This pNFT does NOT represent a valid underlying asset, or the underlying asset was corrupted or destroyed. It exists only as a placeholder. Proceed with caution!';
      case 'Burned':
        return 'This pNFT does NOT represent an underlying asset, which has been returned to the owner. Proceed with caution!';
      case 'Minted':
        return 'The pNFT that represents this card is ready to be minted onto the blockchain';
      case 'Transferred':
        return 'This is a valid asset on the Collector platform and the Solana blockchain';
      default:
        return '';
    }
  },
  title: (status: string) => {
    switch (status) {
      case 'Valid':
        return 'pNFT card is on Collector !';
      case 'Frozen':
        return 'pNFT card is frozen!';
      case 'Invalid':
        return 'pNFT card is Invalid!';
      case 'Burned':
        return 'pNFT is burned!';
      case 'Transferred':
        return 'Asset is on Collector!';
      case 'Minted':
        return 'pNFT card is ready for mint!';
      case 'Rejected':
        return 'Asset was sent back!';
      case 'RequestedBack':
        return 'Asset was sent back!';
      default:
        return '';
    }
  },
};
