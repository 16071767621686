import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Burned: FC<ISvgProps> = ({ width = '48', height = '48' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM26.3967 6.44489C26.987 6.3427 27.5903 6.53338 28.0147 6.95633C32.1959 11.1232 37.9615 17.3854 37.9615 25.7303C37.9615 29.4332 36.4906 32.9843 33.8723 35.6026C31.254 38.2209 27.7028 39.6919 24 39.6919C20.2972 39.6919 16.746 38.2209 14.1277 35.6026C11.5094 32.9843 10.0385 29.4332 10.0385 25.7303C10.0385 21.5963 12.1071 17.3813 14.3849 13.9497C14.6559 13.5416 15.0779 13.2578 15.5582 13.161C16.0384 13.0642 16.5374 13.1622 16.9454 13.4335L20.941 16.0905L25.0446 7.47045C25.3022 6.92945 25.8063 6.54709 26.3967 6.44489ZM27.2384 11.4524L23.3592 19.6008C23.1291 20.0842 22.7004 20.4439 22.1844 20.5866C21.6684 20.7293 21.1158 20.641 20.67 20.3445L16.4792 17.5576C14.8389 20.3398 13.7308 23.1901 13.7308 25.7303C13.7308 28.4539 14.8127 31.0659 16.7386 32.9918C18.6644 34.9176 21.2764 35.9996 24 35.9996C26.7236 35.9996 29.3356 34.9176 31.2614 32.9918C33.1873 31.0659 34.2692 28.4539 34.2692 25.7303C34.2692 20.0279 30.8779 15.3377 27.2384 11.4524ZM29.8547 25.061C30.8618 25.2203 31.549 26.1659 31.3897 27.173C31.1535 28.6653 30.4509 30.0446 29.3825 31.113C28.3141 32.1814 26.9348 32.8841 25.4424 33.1202C24.4354 33.2796 23.4898 32.5924 23.3304 31.5853C23.1711 30.5782 23.8583 29.6326 24.8653 29.4733C25.5874 29.359 26.2547 29.0191 26.7716 28.5022C27.2885 27.9852 27.6285 27.3179 27.7427 26.5959C27.9021 25.5888 28.8477 24.9016 29.8547 25.061Z'
      fill='#FF4445'
    />
  </svg>
);
const BurnedSVG = memo(Burned);
export default BurnedSVG;
