import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAllUserCardsReload extends IAllUserCards {
  clear?: 'clear';
}

export interface IBurnedInitialState {
  isLoadingBurnedCards: boolean;
  burnIdData: IBurnedIdData;
  allBurnedCards: IAllUserCardsReload;
}

const initialState: IBurnedInitialState = {
  allBurnedCards: {
    cardsQtyByCategory: {},
    filterNFtCard: [],
    findTotal: 0,
    total: 0,
    totalCardsCoast: '0',
    totalPages: 1,
  },
  burnIdData: {
    count: 0,
    countAllCards: 0,
    createAt: '',
    id: '',
    ownerId: '',
  },
  isLoadingBurnedCards: false,
};

export const burnedSlice = createSlice({
  initialState,
  name: 'burned',
  reducers: {
    clearAllBurnedCardsSlice: state => {
      state.allBurnedCards.filterNFtCard = [];
    },
    setAllBurnedCardsSlice: (
      state,
      { payload }: PayloadAction<IAllUserCardsReload>,
    ) => {
      const copyArray: ICard[] = [...state.allBurnedCards.filterNFtCard].concat(
        payload.filterNFtCard,
      );
      state.allBurnedCards.filterNFtCard =
        payload.clear === 'clear' ? payload.filterNFtCard : copyArray;
      state.allBurnedCards.cardsQtyByCategory = payload.cardsQtyByCategory;
      state.allBurnedCards.total = payload.total;
      state.allBurnedCards.findTotal = payload.findTotal;
      state.allBurnedCards.totalCardsCoast = payload.totalCardsCoast;
      state.allBurnedCards.totalPages = payload.totalPages
        ? payload.totalPages
        : 1;
    },
    setBurnIdDataSlice: (state, { payload }: PayloadAction<IBurnedIdData>) => {
      state.burnIdData = payload;
      state.allBurnedCards.total = payload.count;
    },
    setLoadingCardsSlice: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingBurnedCards = payload;
    },
    setResetBurnedSlice: () => initialState,
  },
});

export const {
  setLoadingCardsSlice,
  setBurnIdDataSlice,
  setAllBurnedCardsSlice,
  clearAllBurnedCardsSlice,
  setResetBurnedSlice,
} = burnedSlice.actions;

export const { reducer: burnedCardReducer } = burnedSlice;
