import { StateSchema } from '@/app/providers/StoreProvider';

export const getIsLoadingMarketplaceCardsPage = (state: StateSchema) =>
  state.marketplace.isLoadingMarketPlaceCards;
export const getIsLoadingListingMarketplaceCardsPage = (state: StateSchema) =>
  state.marketplace.isLoadingListings;
export const getIsLoadingOffersMarketplaceCardsPage = (state: StateSchema) =>
  state.marketplace.isLoadingOffers;

export const getListingMarketplaceCardsPage = (state: StateSchema) =>
  state.marketplace.listings;

export const getNFTOffersMarketplaceCardsPage = (state: StateSchema) =>
  state.marketplace.offers.NFTOffers;
export const getSellerOffersMarketplaceCardsPage = (state: StateSchema) =>
  state.marketplace.offers.sellerOffers;
export const getBuyerOffersMarketplaceCardsPage = (state: StateSchema) =>
  state.marketplace.offers.buyerOffers;

export const getTotalMarketplaceCardsPage = (state: StateSchema) =>
  state.marketplace.allMarketPlaceCards.total;
export const getTotalAllCoastMarketplaceCardsPage = (state: StateSchema) =>
  state.marketplace.allMarketPlaceCards.totalCardsCoast;
export const getFindTotalMarketplaceCardsPage = (state: StateSchema) =>
  state.marketplace.allMarketPlaceCards.findTotal;
export const getTotalPagesMarketplaceCardsPage = (state: StateSchema) =>
  state.marketplace.allMarketPlaceCards.totalPages;
export const getAllMarketplaceCardsPage = (state: StateSchema) =>
  state.marketplace.allMarketPlaceCards.filterNFtCard;
export const getQtyByCategoryMarketplaceCardsPage = (state: StateSchema) =>
  state.marketplace.allMarketPlaceCards.cardsQtyByCategory;
