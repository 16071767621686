import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import cls from './authHeader.module.scss';
import FullLogo from '@/shared/assets/svg/Logo/FullLogo';
import { getRouteWelcome } from '@/shared/const/router';
import { HStack } from '@/shared/ui/Stack';

export const AuthHeader: FC = memo(() => (
  <HStack max justify='between' className={cls.header}>
    <Link to={getRouteWelcome()}>
      <FullLogo />
    </Link>
  </HStack>
));
