import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const FullLogoSVG: FC<ISvgProps> = ({ width = '225', height = '32' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 225 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_3065_36197)'>
      <path
        d='M53.926 14.053H58.414C58.238 10.137 54.806 7.69501 49.35 7.69501C44.004 7.69501 40 10.357 40 15.989C40 21.643 44.004 24.305 49.35 24.305C54.806 24.305 58.348 21.863 58.458 17.617H53.926C53.816 19.553 52.518 21.115 49.35 21.115C46.27 21.115 44.202 19.333 44.202 15.989C44.202 12.667 46.27 10.885 49.35 10.885C52.518 10.885 53.816 12.491 53.926 14.053Z'
        fill='white'
      />
      <path
        d='M69.7454 7.69501C64.1134 7.69501 60.1094 10.357 60.1094 15.989C60.1094 21.643 64.1134 24.305 69.7454 24.305C75.3554 24.305 79.3594 21.643 79.3594 15.989C79.3594 10.357 75.3554 7.69501 69.7454 7.69501ZM69.7454 21.115C66.3794 21.115 64.3114 19.333 64.3114 15.989C64.3114 12.667 66.3794 10.885 69.7454 10.885C73.0894 10.885 75.1794 12.667 75.1794 15.989C75.1794 19.333 73.0894 21.115 69.7454 21.115Z'
        fill='white'
      />
      <path
        d='M96.5232 23.843V20.697H85.5012V8.13501H81.6732V23.843H96.5232Z'
        fill='white'
      />
      <path
        d='M113.582 23.843V20.697H102.56V8.13501H98.7318V23.843H113.582Z'
        fill='white'
      />
      <path
        d='M119.618 11.215H130.486V8.13501H115.79V23.843H130.75V20.785H119.618V17.199H130.112V14.317H119.618V11.215Z'
        fill='white'
      />
      <path
        d='M146.309 14.053H150.797C150.621 10.137 147.189 7.69501 141.733 7.69501C136.387 7.69501 132.383 10.357 132.383 15.989C132.383 21.643 136.387 24.305 141.733 24.305C147.189 24.305 150.731 21.863 150.841 17.617H146.309C146.199 19.553 144.901 21.115 141.733 21.115C138.653 21.115 136.585 19.333 136.585 15.989C136.585 12.667 138.653 10.885 141.733 10.885C144.901 10.885 146.199 12.491 146.309 14.053Z'
        fill='white'
      />
      <path
        d='M168.058 8.13501H151.712V11.325H157.982V23.843H161.81V11.325H168.058V8.13501Z'
        fill='white'
      />
      <path
        d='M178.07 7.69501C172.438 7.69501 168.434 10.357 168.434 15.989C168.434 21.643 172.438 24.305 178.07 24.305C183.68 24.305 187.684 21.643 187.684 15.989C187.684 10.357 183.68 7.69501 178.07 7.69501ZM178.07 21.115C174.704 21.115 172.636 19.333 172.636 15.989C172.636 12.667 174.704 10.885 178.07 10.885C181.414 10.885 183.504 12.667 183.504 15.989C183.504 19.333 181.414 21.115 178.07 21.115Z'
        fill='white'
      />
      <path
        d='M203.373 17.573V17.441C205.331 17.001 206.915 15.923 206.915 13.041C206.915 9.71901 205.221 8.13501 201.173 8.13501H189.997V23.843H193.825V18.101H199.413L202.889 23.843H207.289L203.373 17.573ZM200.513 15.109H193.825V11.347H200.513C202.009 11.347 202.669 11.941 202.669 13.217C202.669 14.493 202.009 15.109 200.513 15.109Z'
        fill='white'
      />
      <path
        d='M22 20.798L11.3952 27.07L0 19.6877V7.013L22 20.798Z'
        fill='#5BEAFF'
      />
      <path
        d='M21.9765 6.56332L0 19.6923V7.01296L11.496 0L21.9765 6.56332Z'
        fill='#FF7C01'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5658 16.1398L10.3589 13.5038L21.9763 6.56348V11.8281L14.5658 16.1398Z'
        fill='#E35200'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 24.8058V19.6625L11.395 27.0685V27.0677L21.9999 20.798V25.6476L11.3952 31.9975L11.3952 32L0 24.8058Z'
        fill='#0483FF'
      />
    </g>
    <defs>
      <clipPath id='clip0_3065_36197'>
        <rect width='225' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
const FullLogo = memo(FullLogoSVG);

export default FullLogo;
