import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Burn: FC<ISvgProps> = ({ width = '16', height = '19' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.90993 1.03387C9.76625 0.890685 9.56202 0.826131 9.36215 0.860729C9.16228 0.895327 8.99162 1.02477 8.90443 1.20792L6.49564 6.26778L3.97109 4.58895C3.83298 4.49711 3.66404 4.46393 3.50146 4.49671C3.33888 4.5295 3.196 4.62555 3.10428 4.76373C1.88195 6.60516 0.8125 8.81139 0.8125 10.9375C0.8125 12.8438 1.56975 14.6719 2.91767 16.0198C4.26559 17.3678 6.09376 18.125 8 18.125C9.90624 18.125 11.7344 17.3678 13.0823 16.0198C14.4302 14.6719 15.1875 12.8438 15.1875 10.9375C15.1875 6.58895 12.1809 3.29708 9.90993 1.03387ZM7.31432 7.45616L9.64989 2.5501C11.7677 4.74014 13.9375 7.49977 13.9375 10.9375C13.9375 12.5122 13.3119 14.0225 12.1984 15.136C11.085 16.2495 9.57472 16.875 8 16.875C6.42528 16.875 4.91505 16.2495 3.80155 15.136C2.68806 14.0225 2.0625 12.5122 2.0625 10.9375C2.0625 9.35928 2.79486 7.61725 3.80785 5.98156L6.40391 7.70794C6.55484 7.80831 6.7419 7.83821 6.9166 7.7899C7.09129 7.74158 7.23641 7.61981 7.31432 7.45616ZM12.633 11.6601C12.6869 11.3192 12.4543 10.999 12.1133 10.9451C11.7724 10.8911 11.4523 11.1238 11.3983 11.4647C11.2843 12.1852 10.9451 12.851 10.4294 13.3668C9.9136 13.8825 9.24777 14.2217 8.52733 14.3357C8.1864 14.3897 7.95375 14.7098 8.0077 15.0507C8.06165 15.3917 8.38176 15.6243 8.7227 15.5704C9.70392 15.4151 10.6108 14.9531 11.3132 14.2506C12.0157 13.5482 12.4777 12.6413 12.633 11.6601Z'
      fill='inherit'
    />
  </svg>
);

const BurnSVG = memo(Burn);
export default BurnSVG;
