import { FC, memo, useCallback, useState } from 'react';
import './buynowModal.scss';
import BuynowDefault from './BuynowDefault/BuynowDefault';
import BuynowSuccess from './BuynowSuccess/BuynowSuccess';
import { IBuynowModalProps } from './types';
import { Modal } from '@/shared/ui/Modal';

export const BuynowModal: FC<IBuynowModalProps> = memo(
  ({
    open,
    setOpen,
    cardData,
    currentUserWallet,
    getDataCard,
    getNFTCardActivities,
  }) => {
    const [transactionStatus, setTransactionStatus] = useState('none');
    const [error, setError] = useState<undefined | string>(undefined);
    const [transaction, setTransaction] = useState({
      transactionId: '',
      transactionUrl: '',
    });

    const handleModalClose = useCallback(() => {
      setTransactionStatus('none');
      setOpen('buyNow', { isOpen: false });
    }, [setOpen]);

    const changeTransactionStatus = (status: string) => {
      setTransactionStatus(status);
    };

    return (
      <Modal
        isOpen={open}
        className='buynow-modal'
        justify='right'
        onClose={handleModalClose}
      >
        {transactionStatus === 'resolved' ? (
          <BuynowSuccess
            cardData={cardData}
            handleClose={handleModalClose}
            transaction={transaction}
          />
        ) : (
          <BuynowDefault
            getDataCard={getDataCard}
            cardData={cardData}
            handleClose={handleModalClose}
            changeTransactionStatus={changeTransactionStatus}
            setTransaction={setTransaction}
            transactionStatus={transactionStatus}
            currentUserWallet={currentUserWallet}
            setError={setError}
            getNFTCardActivities={getNFTCardActivities}
          />
        )}
      </Modal>
    );
  },
);
