import {
  FC,
  useState,
  MouseEvent,
  useEffect,
  memo,
  useMemo,
  useCallback,
  MouseEventHandler,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BurnCartBlock from './BurnCartBlock';
import { ChangeEmailModal } from './Email/ChangeEmailModal';
import { getItemsLeftBlocks } from './helpers';
import cls from './mainHeader.module.scss';
import { HeaderModal } from '../HeaderPopover/HeaderModal';
import { getAuthData, getIsAuth } from '@/entities/Auth';
import { getCartIsLoading, getUserCart } from '@/entities/Cart';
import { fetchUserInfo } from '@/entities/Headers';
import {
  getAllActiveInboundShipments,
  getAllPastInboundShipments,
  setIsEditInboundShipmentSlice,
} from '@/entities/InboundShipment';
import {
  getMyProfile,
  getMyProfileAction,
  getMyProfileEmail,
} from '@/entities/MyProfile';
import {
  UserActivitiesModal,
  getAllNotifications,
} from '@/entities/Notifications';
import {
  getAllActiveOutboundShipments,
  getAllPastOutboundShipments,
} from '@/entities/OutboundShipment';
import {
  MagicLinkModal,
  getMagicLinkIsLoading,
} from '@/features/MagicLinkModal';
import { ProfileAvatarSVG } from '@/shared/assets/svg';
import HeaderNotificationsBellWithDotSVG from '@/shared/assets/svg/HeaderNotificationsBellWithDotSVG';
import HeaderNotificationsBellWithoutDotSVG from '@/shared/assets/svg/HeaderNotificationsBellWithoutDotSVG';
import FullLogo from '@/shared/assets/svg/Logo/FullLogo';
import { allRoutes, getRouteCreateShipment } from '@/shared/const/router';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { Button } from '@/shared/ui/Buttons';
import { Spinner } from '@/shared/ui/Loaders';
import { HStack, VStack } from '@/shared/ui/Stack';
import { CancelModal } from '@/widgets/Cancel';

interface IHeaderProps {
  setOpenModal?: MouseEventHandler<HTMLDivElement>;
}

export const MainHeader: FC<IHeaderProps> = memo(() => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthToken = localStorage.getItem('isAuth');
  const { wallet } = useAppSelector(getAuthData);
  const isAuth = useAppSelector(getIsAuth);
  const isLoadingMagicLink = useAppSelector(getMagicLinkIsLoading);
  const isLoadingCart = useAppSelector(getCartIsLoading);
  const allNotifications = useAppSelector(getAllNotifications);
  const { photo, id, isActivateEmail } = useAppSelector(getMyProfile);
  const profileEmail = useAppSelector(getMyProfileEmail);
  const outboundShipmentsActive = useAppSelector(getAllActiveOutboundShipments);
  const outboundShipmentsPast = useAppSelector(getAllPastOutboundShipments);
  const inboundShipmentsActive = useAppSelector(getAllActiveInboundShipments);
  const inboundShipmentsPast = useAppSelector(getAllPastInboundShipments);
  const currentTypeContent =
    profileEmail && !isActivateEmail ? 'unconfirmedEmail' : 'emptyEmail';
  const itemsLeftBlocks = useMemo(
    () => getItemsLeftBlocks(pathname, navigate, isAuth),
    [pathname, navigate, isAuth],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [openNotifications, setOpenNotifications] = useState<boolean>(false);
  const [openModalEmail, setOpenModalEmail] = useState(false);
  const [openShippingTipsModal, setOpenShippingTipsModal] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const onClose = () => {
    setOpenModal(false);
  };
  const onOpen = () => {
    setOpenModal(true);
  };

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (isAuth) setAnchorEl(event.currentTarget);
      else {
        onOpen();
        setAnchorEl(null);
      }
    },
    [isAuth],
  );

  const disabledOnRoute = pathname === allRoutes.getRouteVerifyNFTCard();
  const disabledGetCart = pathname === allRoutes.getRouteBurnCart();

  const openCreateShipment = useCallback(() => {
    dispatch(setIsEditInboundShipmentSlice(false));
    navigate(getRouteCreateShipment());
  }, [dispatch, navigate]);

  const checkingForEmail = useCallback(() => {
    if (isAuth)
      isActivateEmail ? openCreateShipment() : setOpenModalEmail(true);
    else onOpen();
  }, [isActivateEmail, isAuth, openCreateShipment]);

  const openFindCardModal = useCallback(
    () => setOpenShippingTipsModal(true),
    [],
  );

  const openModalNotifications = useCallback(
    () => setOpenNotifications(true),
    [],
  );
  const closeModalNotifications = useCallback(
    () => setOpenNotifications(false),
    [],
  );

  const redirectInProfile = useCallback(() => {
    if (isAuth) {
      navigate(allRoutes.getRouteSettings());
      setAnchorEl(null);
      setOpenModalEmail(false);
    } else onOpen();
  }, [isAuth, navigate]);

  const redirectToBurnCart = useCallback(() => {
    if (!disabledGetCart && !isLoadingCart) dispatch(getUserCart());

    if (!isLoadingCart) navigate(allRoutes.getRouteBurnCart());
  }, [disabledGetCart, dispatch, isLoadingCart, navigate]);

  const redirectOnLogo = useCallback(
    () =>
      isAuth
        ? navigate(allRoutes.getRouteDashboard())
        : navigate(allRoutes.getRouteWelcome()),
    [isAuth, navigate],
  );

  const clickHeaderIcons = useCallback(
    (onClick: () => void, text: string) => {
      if ((isAuth && isAuthToken) || text === 'Marketplace') onClick();
      else onOpen();
    },
    [isAuthToken, isAuth],
  );

  const logoContent = useCallback(() => {
    if (!isAuth && isLoadingMagicLink)
      return (
        <div className={cls['with-photo']} style={{ display: 'inline-flex' }}>
          <Spinner size={44} />
        </div>
      );
    if (photo && !photo?.includes('undefined'))
      return (
        <div className={cls['with-photo']} style={{ display: 'inline-flex' }}>
          <img
            height='48'
            src={photo}
            alt='user avatar'
            loading='lazy'
            onClick={handleClick}
          />
        </div>
      );
    return (
      <HStack className={cls.user} onClick={handleClick}>
        <ProfileAvatarSVG />
      </HStack>
    );
  }, [handleClick, isAuth, isLoadingMagicLink, photo]);

  useEffect(() => {
    isAuthToken && !id.length && dispatch(getMyProfileAction());
    if (isAuth) dispatch(getUserCart());
    if (wallet) dispatch(fetchUserInfo({ user: wallet }));
  }, [dispatch, id, isAuthToken, isAuth, wallet]);

  return (
    <VStack max gap={0.25} className={cls['main-header-wrapper']}>
      <HStack max justify='between' className={cls.content}>
        <HStack>
          <div className={cls.logo} onClick={redirectOnLogo}>
            <FullLogo />
          </div>
          <HStack gap={1} align='center' className={cls.icons}>
            {itemsLeftBlocks
              .filter(i => !i.isHide)
              .map(({ active, text, picture, onClick }, index) => (
                <HStack key={text} align='center' justify='center'>
                  {index !== 0 && <div className={cls.border} />}
                  <Button
                    typeButton='ghost'
                    text={text}
                    size='small'
                    img={picture}
                    imgSize='24'
                    active={active}
                    onClick={() => clickHeaderIcons(onClick, text)}
                  />
                </HStack>
              ))}
          </HStack>
        </HStack>
        <HStack className={cls['right-block']}>
          <HStack gap={2.75} align='center' className={cls.profile}>
            <HStack gap={1}>
              <Button
                typeButton='secondary'
                text='Verify asset'
                disabled={disabledOnRoute}
                size='small'
                onClick={openFindCardModal}
              />
              <Button
                typeButton='primary'
                text='Deposit cards'
                size='small'
                onClick={checkingForEmail}
              />
            </HStack>
            {!!isAuth && (
              <div
                className={cls.notifications}
                onClick={openModalNotifications}
              >
                {allNotifications.some(item => !item.read && !item.action) ? (
                  <HeaderNotificationsBellWithDotSVG />
                ) : (
                  <HeaderNotificationsBellWithoutDotSVG />
                )}
              </div>
            )}
            <HStack gap={0.5}>
              {!!isAuth && (
                <HStack
                  gap={1}
                  className={cls['wrapper-avatar']}
                  onClick={redirectToBurnCart}
                >
                  <BurnCartBlock />
                </HStack>
              )}
              {logoContent()}
            </HStack>
          </HStack>
        </HStack>
        {!!openModalEmail && (
          <CancelModal
            open={openModalEmail}
            setOpen={setOpenModalEmail}
            typeContent={currentTypeContent}
            onClick={redirectInProfile}
          />
        )}
        {!!openShippingTipsModal && (
          <ChangeEmailModal
            open={openShippingTipsModal}
            setOpen={setOpenShippingTipsModal}
            modalType='verifyCard'
          />
        )}
      </HStack>
      <HeaderModal
        open={!!anchorEl}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setOpenModal={setOpenModalEmail}
      />
      <UserActivitiesModal
        open={openNotifications}
        setOpenNotifications={closeModalNotifications}
        inboundShipmentsActive={inboundShipmentsActive}
        inboundShipmentsPast={inboundShipmentsPast}
        outboundShipmentsActive={outboundShipmentsActive}
        outboundShipmentsPast={outboundShipmentsPast}
      />
      {!!openModal && <MagicLinkModal open={openModal} onClose={onClose} />}
    </VStack>
  );
});
