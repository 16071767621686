import { FC, memo } from 'react';
import { ISvgProps } from './types';

const Grading: FC<ISvgProps> = ({ width = '20', height = '20' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.07812 6.52778H14.9219C15.3086 6.52778 15.625 6.84028 15.625 7.22222C15.625 7.60417 15.3086 7.91667 14.9219 7.91667H5.07812C4.69141 7.91667 4.375 7.60417 4.375 7.22222C4.375 6.84028 4.69141 6.52778 5.07812 6.52778ZM5.07812 10.6944H14.9219C15.3086 10.6944 15.625 10.3819 15.625 10C15.625 9.61806 15.3086 9.30556 14.9219 9.30556H5.07812C4.69141 9.30556 4.375 9.61806 4.375 10C4.375 10.3819 4.69141 10.6944 5.07812 10.6944ZM5.07812 13.4722H8.59375C8.98047 13.4722 9.29688 13.1597 9.29688 12.7778C9.29688 12.3958 8.98047 12.0833 8.59375 12.0833H5.07812C4.69141 12.0833 4.375 12.3958 4.375 12.7778C4.375 13.1597 4.69141 13.4722 5.07812 13.4722ZM5.07812 16.25H8.59375C8.98047 16.25 9.29688 15.9375 9.29688 15.5556C9.29688 15.1736 8.98047 14.8611 8.59375 14.8611H5.07812C4.69141 14.8611 4.375 15.1736 4.375 15.5556C4.375 15.9375 4.69141 16.25 5.07812 16.25ZM12.3977 14.2847L11.8984 13.7917C11.8333 13.7274 11.7561 13.6764 11.671 13.6416C11.586 13.6068 11.4948 13.5889 11.4027 13.5889C11.3107 13.5889 11.2195 13.6068 11.1345 13.6416C11.0494 13.6764 10.9721 13.7274 10.907 13.7917C10.8419 13.856 10.7903 13.9323 10.7551 14.0163C10.7198 14.1003 10.7017 14.1903 10.7017 14.2812C10.7017 14.3722 10.7198 14.4622 10.7551 14.5462C10.7903 14.6302 10.8419 14.7065 10.907 14.7708L11.9055 15.7569C12.1797 16.0278 12.6227 16.0278 12.8969 15.7569L15.1258 13.5556C15.1909 13.4913 15.2425 13.4149 15.2777 13.3309C15.313 13.2469 15.3311 13.1569 15.3311 13.066C15.3311 12.975 15.313 12.885 15.2777 12.801C15.2425 12.717 15.1909 12.6407 15.1258 12.5764C15.0607 12.5121 14.9834 12.4611 14.8984 12.4263C14.8133 12.3915 14.7221 12.3736 14.6301 12.3736C14.538 12.3736 14.4469 12.3915 14.3618 12.4263C14.2768 12.4611 14.1995 12.5121 14.1344 12.5764L12.3977 14.2847ZM4.375 4.44444C4.375 4.82639 4.69141 5.13889 5.07812 5.13889H14.9219C15.3086 5.13889 15.625 4.82639 15.625 4.44444C15.625 4.0625 15.3086 3.75 14.9219 3.75H5.07812C4.69141 3.75 4.375 4.0625 4.375 4.44444Z'
      fill='#DFE0E1'
    />
  </svg>
);
const GradingSVG = memo(Grading);
export default GradingSVG;
