import { Dispatch, SetStateAction, memo, useCallback, useState } from 'react';
import cls from './SentMoney.module.scss';
import { SendQRCode } from './ui/QRCode/QRCode';
import {
  SendMoneyHeader,
  getBalanceSOL,
  getBalanceUSDC,
} from '@/entities/Headers';
import { SendForm } from '@/features/SendSolanaForm';
import { UsdcSVG } from '@/shared/assets/svg';
import SolanaIcon from '@/shared/assets/svg/HeaderPopover/SolonsLogo.svg';
import BackIcon from '@/shared/assets/svg/HeaderPopover/backIcon.svg';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { HStack, VStack } from '@/shared/ui/Stack';

export interface SentMoneyProps {
  className?: string;
  setOpenSent: Dispatch<SetStateAction<boolean>>;
  setOpenModal: () => void;
}
export const SentMoney = memo((props: SentMoneyProps) => {
  const { className, setOpenSent, setOpenModal } = props;
  const [openQR, setOpenQR] = useState(false);
  const [openSendForm, setOpenSendForm] = useState(false);
  const balanceSOL = useAppSelector(getBalanceSOL);
  const balanceUSDC = useAppSelector(getBalanceUSDC);
  const isReceive = localStorage.getItem('typeTranslate') === 'receive';

  const onOpen = useCallback(
    (type: 'solana' | 'USDC') => {
      if (isReceive) {
        localStorage.setItem('sendType', type);
        setOpenQR(true);
      }
      if (!isReceive) {
        localStorage.setItem('sendType', type);
        setOpenQR(false);
        setOpenSendForm(true);
      }
    },
    [isReceive],
  );

  const content = useCallback(() => {
    if (openQR)
      return <SendQRCode setOpenModal={setOpenModal} setOpenQR={setOpenQR} />;
    if (openSendForm)
      return (
        <SendForm setOpenModal={setOpenModal} setOpenForm={setOpenSendForm} />
      );
    return (
      <VStack className={classNames(cls.SentMoney, {}, [className])} gap={1.5}>
        <SendMoneyHeader
          setOpen={setOpenSent}
          setOpenModal={setOpenModal}
          title='Choose token'
        />
        <VStack gap={0.5}>
          <HStack
            className={cls.typeWallet}
            justify='between'
            onClick={() => onOpen('solana')}
          >
            <HStack align='start' style={{ height: '100%' }} gap={0.5}>
              <SolanaIcon
                style={{ background: '#000000', borderRadius: '50%' }}
              />
              <VStack>
                <span>Solana</span>
                <span className={cls.value}>{balanceSOL} SOL</span>
              </VStack>
            </HStack>
            <BackIcon className={classNames(cls.icons, {}, [cls.next])} />
          </HStack>
          <HStack
            className={cls.typeWallet}
            justify='between'
            onClick={() => onOpen('USDC')}
          >
            <HStack align='start' style={{ height: '100%' }} gap={0.5}>
              <UsdcSVG fill='#3875C9' secondFill='#FFFFFF' />
              <VStack>
                <span>USDC</span>
                <span className={cls.value}> {balanceUSDC} USDC </span>
              </VStack>
            </HStack>
            <BackIcon className={classNames(cls.icons, {}, [cls.next])} />
          </HStack>
        </VStack>
      </VStack>
    );
  }, [
    balanceSOL,
    balanceUSDC,
    className,
    onOpen,
    openQR,
    openSendForm,
    setOpenModal,
    setOpenSent,
  ]);

  return <VStack max>{content()}</VStack>;
});
