import { NavigateFunction } from 'react-router-dom';
import {
  DottedSVG,
  RhombSVG,
  MarketPlaceSVG,
} from '@/shared/assets/svg/buttons';
import { allRoutes } from '@/shared/const/router';

export const getItemsLeftBlocks = (
  path: string,
  navigate: NavigateFunction,
  isAuth: boolean,
) => [
  {
    active: path === allRoutes.getRouteDashboard(),
    isHide: !isAuth,
    onClick: () => navigate(allRoutes.getRouteDashboard()),
    picture: DottedSVG,
    text: 'Dashboard',
  },
  {
    active:
      path === allRoutes.getRouteAllCards() ||
      path === allRoutes.getRouteBurned() ||
      path === allRoutes.getRouteSealedBoxes(),
    isHide: !isAuth,
    onClick: () => navigate(allRoutes.getRouteAllCards()),
    picture: RhombSVG,
    text: 'Collection',
  },
  {
    active:
      path === allRoutes.getRouteMarketplaceCards() ||
      path === allRoutes.getRouteMarketplaceSealedBox(),
    isHide: false,
    onClick: () => navigate(allRoutes.getRouteMarketplaceCards()),
    picture: MarketPlaceSVG,
    text: 'Marketplace',
  },
];
