import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const DollarBold: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 1.125C12.6213 1.125 13.125 1.62868 13.125 2.25V3.375H13.5C14.1402 3.375 14.7741 3.5011 15.3656 3.74609C15.957 3.99108 16.4945 4.35017 16.9471 4.80285C17.3998 5.25554 17.7589 5.79296 18.0039 6.38442C18.2489 6.97588 18.375 7.60981 18.375 8.25C18.375 8.87132 17.8713 9.375 17.25 9.375C16.6287 9.375 16.125 8.87132 16.125 8.25C16.125 7.90528 16.0571 7.56394 15.9252 7.24546C15.7933 6.92698 15.5999 6.6376 15.3562 6.39384C15.1124 6.15009 14.823 5.95673 14.5045 5.82482C14.1861 5.6929 13.8447 5.625 13.5 5.625H12H10.125C9.42881 5.625 8.76113 5.90156 8.26884 6.39384C7.77656 6.88613 7.5 7.55381 7.5 8.25C7.5 8.94619 7.77656 9.61387 8.26884 10.1062C8.76113 10.5984 9.42881 10.875 10.125 10.875H14.25C15.5429 10.875 16.7829 11.3886 17.6971 12.3029C18.6114 13.2171 19.125 14.4571 19.125 15.75C19.125 17.0429 18.6114 18.2829 17.6971 19.1971C16.7829 20.1114 15.5429 20.625 14.25 20.625H13.125V21.75C13.125 22.3713 12.6213 22.875 12 22.875C11.3787 22.875 10.875 22.3713 10.875 21.75V20.625H9.75C8.45707 20.625 7.21709 20.1114 6.30285 19.1971C5.38861 18.2829 4.875 17.0429 4.875 15.75C4.875 15.1287 5.37868 14.625 6 14.625C6.62132 14.625 7.125 15.1287 7.125 15.75C7.125 16.4462 7.40156 17.1139 7.89384 17.6062C8.38613 18.0984 9.05381 18.375 9.75 18.375H12H14.25C14.9462 18.375 15.6139 18.0984 16.1062 17.6062C16.5984 17.1139 16.875 16.4462 16.875 15.75C16.875 15.0538 16.5984 14.3861 16.1062 13.8938C15.6139 13.4016 14.9462 13.125 14.25 13.125H10.125C8.83207 13.125 7.59209 12.6114 6.67785 11.6971C5.76361 10.7829 5.25 9.54293 5.25 8.25C5.25 6.95707 5.76361 5.71709 6.67785 4.80285C7.59209 3.88861 8.83207 3.375 10.125 3.375H10.875V2.25C10.875 1.62868 11.3787 1.125 12 1.125Z'
      fill='#DFE0E1'
    />
  </svg>
);

const DollarBoldSVG = memo(DollarBold);
export default DollarBoldSVG;
