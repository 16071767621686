import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Listing: FC<ISvgProps> = ({ width = '19', height = '19' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.07123 0.441279C8.87504 0.38133 8.6671 0.370592 8.46577 0.410012L8.58586 1.02337L8.46358 0.410444L2.1589 1.66826C1.91125 1.71766 1.71766 1.91125 1.66826 2.1589L0.410444 8.46358L0.410012 8.46577C0.370592 8.6671 0.38133 8.87504 0.441279 9.07123C0.501193 9.26731 0.608678 9.4459 0.753762 9.59077L8.90745 17.7445C9.02254 17.8614 9.15968 17.9544 9.31094 18.0181C9.4635 18.0823 9.62736 18.1154 9.7929 18.1154C9.95842 18.1154 10.1223 18.0823 10.2748 18.0181C10.4261 17.9544 10.5633 17.8614 10.6783 17.7445L17.7445 10.6783C17.8614 10.5633 17.9544 10.4261 18.0181 10.2748C18.0823 10.1223 18.1154 9.95842 18.1154 9.7929C18.1154 9.62736 18.0823 9.4635 18.0181 9.31094C17.9544 9.15968 17.8614 9.02254 17.7445 8.90745L9.59051 0.753504C9.44565 0.60842 9.26731 0.501193 9.07123 0.441279ZM2.81249 2.81249L8.70596 1.63672L16.8621 9.7929L9.7929 16.8621L1.63718 8.70642L1.63672 8.70596L2.81249 2.81249ZM6.5 5.5625C6.5 6.08027 6.08027 6.5 5.5625 6.5C5.04473 6.5 4.625 6.08027 4.625 5.5625C4.625 5.04473 5.04473 4.625 5.5625 4.625C6.08027 4.625 6.5 5.04473 6.5 5.5625Z'
      fill='inherit'
    />
  </svg>
);
const ListingSVG = memo(Listing);
export default ListingSVG;
