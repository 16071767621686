import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const Logout: FC<ISvgProps> = ({ width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.75 4.875H4.875V19.125H9.75C10.3713 19.125 10.875 19.6287 10.875 20.25C10.875 20.8713 10.3713 21.375 9.75 21.375H4.5C4.00272 21.375 3.52581 21.1775 3.17417 20.8258C2.82254 20.4742 2.625 19.9973 2.625 19.5V4.5C2.625 4.00272 2.82254 3.52581 3.17417 3.17417C3.52581 2.82254 4.00272 2.625 4.5 2.625H9.75C10.3713 2.625 10.875 3.12868 10.875 3.75C10.875 4.37132 10.3713 4.875 9.75 4.875ZM15.517 7.267C15.9563 6.82766 16.6687 6.82766 17.108 7.267L21.0455 11.2045C21.1534 11.3124 21.2347 11.4367 21.2896 11.5694C21.3446 11.702 21.375 11.8475 21.375 12C21.375 12.2879 21.2652 12.5758 21.0455 12.7955L17.108 16.733C16.6687 17.1723 15.9563 17.1723 15.517 16.733C15.0777 16.2937 15.0777 15.5813 15.517 15.142L17.534 13.125H9.75C9.12868 13.125 8.625 12.6213 8.625 12C8.625 11.3787 9.12868 10.875 9.75 10.875H17.534L15.517 8.858C15.0777 8.41866 15.0777 7.70634 15.517 7.267Z'
      fill='#dfe0e1'
    />
  </svg>
);
const LogoutSVG = memo(Logout);
export default LogoutSVG;
