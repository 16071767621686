import { FC, memo } from 'react';
import { ISvgProps } from './types';

const BurnCheckout: FC<ISvgProps> = ({ width = '36', height = '36' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='3' y='3' width='30' height='30' rx='15' fill='white' />
    <path
      d='M24.4781 12.2441C23.2594 10.2941 21.675 8.61594 20.2969 7.23781C20.2119 7.15357 20.108 7.09089 19.9938 7.05501C19.8796 7.01913 19.7585 7.01109 19.6406 7.03156C19.5219 7.05027 19.4095 7.0976 19.3131 7.16946C19.2167 7.24133 19.1393 7.33558 19.0875 7.44406L16.1906 13.5191L13.1719 11.5034C13.0063 11.3938 12.8044 11.3535 12.6094 11.3909C12.5126 11.41 12.4206 11.448 12.3386 11.5027C12.2565 11.5574 12.1861 11.6277 12.1313 11.7097C10.3031 14.4659 9.375 16.9597 9.375 19.1253C9.375 21.4128 10.2837 23.6066 11.9012 25.2241C13.5187 26.8416 15.7125 27.7503 18 27.7503C20.2875 27.7503 22.4813 26.8416 24.0988 25.2241C25.7163 23.6066 26.625 21.4128 26.625 19.1253C26.625 16.7909 25.9219 14.5409 24.4781 12.2441ZM23.5594 19.9878C23.382 21.1702 22.8301 22.2645 21.9846 23.11C21.1392 23.9554 20.0449 24.5073 18.8625 24.6847H18.75C18.5618 24.6833 18.3811 24.6112 18.2436 24.4827C18.1061 24.3543 18.0219 24.1788 18.0077 23.9912C17.9935 23.8035 18.0504 23.6174 18.1671 23.4697C18.2838 23.3221 18.4517 23.2237 18.6375 23.1941C19.4994 23.0539 20.2954 22.6463 20.9129 22.0288C21.5304 21.4114 21.938 20.6154 22.0781 19.7534C22.0885 19.6524 22.1193 19.5546 22.1686 19.4658C22.2179 19.377 22.2847 19.2992 22.365 19.237C22.4453 19.1749 22.5374 19.1296 22.6357 19.1041C22.734 19.0786 22.8364 19.0733 22.9368 19.0885C23.0372 19.1038 23.1335 19.1393 23.2198 19.1928C23.306 19.2464 23.3805 19.3169 23.4388 19.4001C23.497 19.4833 23.5377 19.5775 23.5584 19.6769C23.5791 19.7763 23.5795 19.8789 23.5594 19.9784V19.9878Z'
      fill='#FF4445'
    />
    <rect
      x='3'
      y='3'
      width='30'
      height='30'
      rx='15'
      stroke='white'
      strokeWidth='6'
    />
  </svg>
);
const BurnCheckoutSVG = memo(BurnCheckout);
export default BurnCheckoutSVG;
