import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const ArrowUpRight: FC<ISvgProps> = ({
  width = '16',
  height = '16',
  fill = 'inherit',
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill={fill}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.9445 4C12.8651 3.81602 12.7185 3.66934 12.5345 3.59V3.59C12.4467 3.54067 12.3497 3.51002 12.2495 3.5H5.74948C5.55057 3.5 5.3598 3.57902 5.21915 3.71967C5.0785 3.86032 4.99948 4.05109 4.99948 4.25C4.99948 4.44891 5.0785 4.63968 5.21915 4.78033C5.3598 4.92098 5.55057 5 5.74948 5H10.4395L3.71948 11.72C3.64933 11.7893 3.59364 11.8718 3.55563 11.9628C3.51762 12.0538 3.49805 12.1514 3.49805 12.25C3.49805 12.3486 3.51762 12.4462 3.55563 12.5372C3.59364 12.6282 3.64933 12.7107 3.71948 12.78C3.8601 12.9205 4.05073 12.9993 4.24948 12.9993C4.44823 12.9993 4.63885 12.9205 4.77948 12.78L11.4995 6.06V10.75C11.4995 10.9489 11.5785 11.1397 11.7191 11.2803C11.8598 11.421 12.0506 11.5 12.2495 11.5C12.4484 11.5 12.6392 11.421 12.7798 11.2803C12.9205 11.1397 12.9995 10.9489 12.9995 10.75V4.25C12.996 4.16408 12.9774 4.07944 12.9445 4V4Z'
      fill='inherit'
    />
  </svg>
);
const ArrowUpRightSVG = memo(ArrowUpRight);
export default ArrowUpRightSVG;
